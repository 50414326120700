import { isArray } from 'lodash';
import request from '@/http';

export function listRtbCreativeTracking(params) {
  const url = '/api/adx/dsp/creative/tracking';

  return request.get(url, {
    params,
  });
}

export function createRtbCreative(creative) {
  const { file, ...params } = creative;

  const formData = new FormData();

  formData.append('file', file);

  const url = '/api/adx/dsp/creative';

  return request.post(url, {
    useLoading: true,
    data: formData,
    params,
  });
}

export function listRtbCreative(params) {
  const url = ' /api/adx/dsp/creative';

  return request.get(url, {
    params,
  });
}

export function deleteRtbCreative(ids) {
  const url = '/api/adx/dsp/creative';

  return request.delete(url, {
    data: isArray(ids) ? ids : [ids],
  });
}

export function adjustCreativeResource(body) {
  const url = '/api/adx/dsp/creative/adjust';

  const { file, ...params } = body;

  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  return request.put(url, {
    params,
    data: formData,
  });
}

export function getRtbTrackingConfig() {
  const url = '/api/adx/rtb/tracking/config';

  return request.get(url);
}
