import { isArray } from 'lodash';
import { GroupStatusEnum } from './group.enum';
import TimeService from '@/services/time.service';

export function transformGroupList(list) {
  if (isArray(list) && list.length) {
    return list.map((group) => {
      const { status, createTime, updateTime } = group;

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      return {
        ...group,
        createTimeText,
        updateTimeText,
        statusText: GroupStatusEnum.getText(status),
      };
    });
  }

  return [];
}
