import { getDvaApp } from 'umi';
import { isFunction } from 'lodash';
import request from '@/http';
import type { Dispatch as DispatchType } from 'umi';
import { CancelRequestReason } from '@/constant/api';

interface CancelRequest {
  run: () => Promise<any>;
  cancel: (reason: any) => void;
}

class Dispatch {
  dispatch: DispatchType = () => {};

  constructor() {
    // @ts-ignore
    this.dispatch = (window?.g_app || getDvaApp())?._store?.dispatch || function () {};
  }

  cancelableRequest(method: (data: any, options: any) => any, payload: any): CancelRequest {
    if (isFunction(method)) {
      const { token, cancel } = request.CancelToken.source();

      return {
        run() {
          return method(payload, {
            cancelToken: token,
          });
        },
        cancel(reason: any) {
          cancel(reason || CancelRequestReason.Manual);
        },
      };
    }

    return {
      run() {
        return Promise.reject();
      },
      cancel(reason?: any) {},
    };
  }
}

export default Dispatch;
