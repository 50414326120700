import type { Effect, Reducer } from 'umi';
import type { ListResponse } from '@/models/interface';
import type { NExport } from './interface';
import { listExport } from './api';
import { transformExportList } from './util';

interface IExportState {
  exportData: ListResponse<NExport.IExportItem>;
}

interface IExportModel {
  namespace: string;
  state: IExportState;
  effects: {
    listExport: Effect;
  };
  reducers: {
    listExportSuccess: Reducer;
  };
}

const exportModel: IExportModel = {
  namespace: 'exportState',
  state: {
    exportData: {
      list: [],
      paginator: {
        totalNum: 0,
        totalPage: 1,
        pageSize: 10,
        pageIndex: 1,
      },
    },
  },
  effects: {
    *listExport({ payload }, { call, put }) {
      const data = yield call(listExport, payload);

      yield put({
        type: 'listExportSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listExportSuccess(state, { payload }) {
      const {
        list = [],
        paginator = {
          totalNum: 0,
          totalPage: 1,
          pageSize: 10,
          pageIndex: 1,
        },
      } = payload;

      return {
        ...state,
        exportData: {
          list: transformExportList(list),
          paginator,
        },
      };
    },
  },
};

export default exportModel;
