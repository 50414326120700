import Utils from '@/utils';
import request from '@/http';

// 根据ep id获取是否在 聚合版本里面配置过
export function listDspSspEpConfigInAggregation(params) {
  const url = '/api/adx/operation/ssp/ep/dsp/ep/aggregation/config/list';

  return request.get(url, {
    params: Utils.checkParams(params),
  });
}

// 根据id 获取ep是否在老版本里面配置过
export function listDspSspEpConfigInOperation(params) {
  const url = '/api/adx/operation/ssp/ep/dsp/ep/config/list';

  return request.get(url, {
    params: Utils.checkParams(params),
  });
}
