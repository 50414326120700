import { isNumber } from 'lodash';
import type { NExport } from './interface';
import TimeService from '@/services/time.service';
import NumberFormatService from '@/services/numberformat.service';

function formatExportProgress(number: number) {
  if (isNumber(number)) {
    if (number < 0) {
      return 0;
    }

    if (number > 1) {
      return 100;
    }

    return Math.ceil(number * 100);
  }

  return 0;
}

export function transformExportList(list: NExport.IExportItem[]) {
  if (list) {
    return list.map((item) => {
      const { progress, exportTime, size } = item;

      return {
        ...item,
        progressPercentage: formatExportProgress(progress),
        exportTimeValue: TimeService.formatTime(exportTime),
        formatKB: NumberFormatService.formatFileSize(size),
      };
    });
  }

  return list;
}
