import { history } from 'umi';
import { isArray, endsWith } from 'lodash';
import { listMenu } from '@/models/global/api';
import allRoutes from '@/../config/routes';
import SessionService from '@/services/session.service';

class PermissionService {
  static whiteRouteList = [
    '/',
    '/404',
    '/403',
    '/user',
    '/user/login',
    '/user/404',
    '/change-password',
    '/register',
  ];

  static splitRouteSub(pathname: string) {
    if (!pathname) {
      return '';
    }

    if (pathname === '/') {
      return pathname;
    }

    return endsWith(pathname, '/') ? pathname.substring(0, pathname.length - 1) : pathname;
  }

  static listMenu() {
    return listMenu();
  }

  static getRouterPathList(list: any) {
    try {
      const ret: any[] = [];

      function getPath(routeList: any[]) {
        for (let i = 0; i < routeList.length; i++) {
          const { path, routes } = routeList[i];

          if (path && !ret.includes(path)) {
            ret.push(path);
          }

          if (routes && isArray(routes) && routes.length) {
            getPath(routes);
          }
        }
      }

      getPath(list);

      return ret;
    } catch (e) {
      return [];
    }
  }

  static getFirstMatchedPath(permissionPathList: any[]) {
    // @ts-ignore
    let ret: string = null;

    function matched(list: any[]) {
      for (let i = 0; i < list.length; i++) {
        const { visible, hideInMenu, path, children } = list[i];

        // 有效的path
        const flag = visible && !hideInMenu && path;

        if (flag) {
          ret = path;

          return;
        }

        if (!ret) {
          // 如果存在子节点 则再次从子节点里面找
          if (children && children.length) {
            matched(children);
          }
        }
      }
    }

    matched(permissionPathList);

    return ret;
  }

  static validateRoutePath(path: string) {
    const userPermissions = SessionService.getSessionMenuData();

    return PermissionService.routeValidate(userPermissions, 'path', path);
  }

  static routeValidate(list: any[], key: string, value: any) {
    let flag = false;

    function hasPath(routes: any[], k: string, v: any) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < routes.length; i++) {
        const item = routes[i];

        if (flag) {
          return;
        }

        // 当前访问的url 在menu接口里面存在 并且 visible 为true 才算有权限
        if (item[k] && item[k] === v && item?.visible === true) {
          flag = true;
        } else if (item.children && item.children.length) {
          hasPath(item.children, k, v);
        }
      }
    }

    if (list && isArray(list) && key && value) {
      hasPath(list, key, value);
    }

    return flag;
  }

  // 验证路由主方法
  static verificationRoute(route: string) {
    const pathname = PermissionService.splitRouteSub(route);

    const userPermissions = SessionService.getSessionMenuData();

    const allPathList = PermissionService.getRouterPathList(allRoutes);

    function handler(path: string) {
      try {
        const flag = PermissionService.routeValidate(userPermissions, 'path', path);

        // 没有权限
        if (!flag) {
          const firstMatchedRoute = PermissionService.getFirstMatchedPath(userPermissions);

          // dashboard 是项目的第一个页面， 如果首页没有权限的话需要自动跳转到第一个有权限的页面
          if (path === '/dashboard' && firstMatchedRoute) {
            history.replace(firstMatchedRoute);

            return;
          }

          history.replace('/403');
        }
      } catch (e) {
        console.log(e);
      }
    }

    // 存在这个路由
    if (allPathList.includes(pathname)) {
      // 是否在白名单里面
      if (!PermissionService.whiteRouteList.includes(pathname)) {
        try {
          if (userPermissions && isArray(userPermissions) && userPermissions.length) {
            handler(pathname);
          } else {
            PermissionService.listMenu()
              .then((data) => {
                SessionService.setSessionMenuData(data);

                handler(pathname);
              })
              .catch();
          }
        } catch (e) {
          console.log(e);
        }
      }

      return;
    }

    if (pathname !== '/404') {
      setTimeout(() => {
        history.replace('/404');
      }, 100);
    }
  }

  // 获取path permission 配置
  static getRouterPathPermissionConfig(menuData: any[]) {
    try {
      const ret = {};

      function getPath(routeList: any[]) {
        for (let i = 0; i < routeList.length; i++) {
          const { path, permission, children } = routeList[i];

          const pathname = PermissionService.splitRouteSub(path);

          if (path && !ret[path]) {
            ret[pathname] = permission;
          }

          if (children && isArray(children) && children.length) {
            getPath(children);
          }
        }
      }

      getPath(menuData);

      return ret;
    } catch (e) {
      return {};
    }
  }
}

export default PermissionService;
