export class AdFormat {
  static Banner = 'banner';

  static Native = 'native';

  static Video = 'video';
}

export class AdSetStateEnum {
  static Active = 'Active';

  static Paused = 'Paused';

  static Deleted = 'Deleted';
}

export class AutoJumpCreativeState {
  static Passive = 0;

  static Active = 1;
}

export class IpCheckingState {
  static OFF = 0;

  static ON = 1;
}

export class CreativeCheckingState {
  static OFF = 0;

  static ON = 1;
}

export class BidAdaptiveState {
  static OFF = 0;

  static ON = 1;

  static toText(value) {
    if (value === BidAdaptiveState.ON) {
      return 'ON';
    }

    return 'OFF';
  }
}

export class AdSetJsCaptureState {
  static OFF = 0;

  static ON = 1;

  static toText(value) {
    if (value === AdSetJsCaptureState.ON) {
      return 'ON';
    }

    return 'OFF';
  }
}
