import { isArray } from 'lodash';
import TimeService from '@/services/time.service';
import SspUnitOperationService from '@/pages/operation/ssp-unit/service';
import AuthService from '@/services/auth.service';
import DspSspOperationService from '@/pages/operation/operation.service';

export function transformSspUnitList(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { createTime, updateTime, managerIds } = item;

      const operationId = SspUnitOperationService.generatorOperationId(item);

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const managerAuthed = AuthService.authOperationData(managerIds);

      const dataAuthed = AuthService.authorizedSsp(item.sspId);

      return {
        ...item,
        operationId,
        createTimeText,
        updateTimeText,
        userAuthed: managerAuthed || dataAuthed,
      };
    });
  }

  return [];
}

export function transformOfferAdset(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { offerName, offerId, adSetName, adSetId, adFormat, countryName, osName } = item;

      return {
        ...item,
        name: `${offerName}#${offerId}-${adSetName}#${adSetId}-(${adFormat}-${countryName}-${osName})`,
        id: DspSspOperationService.generateOfferAdsetId(item),
        matchedId: SspUnitOperationService.generateMatchedInternalDspKey(item),
      };
    });
  }

  return [];
}

export function transformInternalDspEp(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      return {
        ...item,
        matchedId: SspUnitOperationService.generateMatchedInternalDspKey(item),
      };
    });
  }

  return [];
}

export function transformExternalSspEp(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      return {
        ...item,
        matchedId: SspUnitOperationService.generateMatchedInternalDspKey(item),
      };
    });
  }

  return [];
}

export function transformDspEpItems(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { dspUnit, dspId } = item;

      const [dspName, dataCenter, adFormat] = dspUnit?.split('-');

      const dspUnitName = `${dspName}#${dspId}-${dataCenter}-${adFormat}`;

      return {
        ...item,
        id: item.dspIntegrationId,
        name: dspUnitName,
      };
    });
  }

  return [];
}
