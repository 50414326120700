// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/van/WebstormProjects/admile-adx-platform-web/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/dsp",
        "redirect": "/dsp/list",
        "exact": true
      },
      {
        "path": "/ssp",
        "redirect": "/ssp/list",
        "exact": true
      },
      {
        "path": "/user",
        "redirect": "/user/login",
        "exact": true
      },
      {
        "path": "/settings",
        "redirect": "/settings/collection",
        "exact": true
      },
      {
        "path": "/rtb",
        "redirect": "/rtb/offer",
        "exact": true
      },
      {
        "path": "/report",
        "redirect": "/report/adx",
        "exact": true
      },
      {
        "path": "/management",
        "redirect": "/management/user",
        "exact": true
      },
      {
        "path": "/management/user",
        "redirect": "/management/user/list",
        "exact": true
      },
      {
        "path": "/management/group",
        "redirect": "/management/group/list",
        "exact": true
      },
      {
        "path": "/dsp/registration",
        "redirect": "/dsp/registration/list",
        "exact": true
      },
      {
        "path": "/ssp/registration",
        "redirect": "/ssp/registration/list",
        "exact": true
      },
      {
        "path": "/operation",
        "redirect": "/operation/dsp-ep/list",
        "exact": true
      },
      {
        "path": "/operation/dsp",
        "redirect": "/operation/dsp-ep/list",
        "exact": true
      },
      {
        "path": "/operation/dsp-ep",
        "redirect": "/operation/dsp-ep/list",
        "exact": true
      },
      {
        "path": "/operation/ssp-ep",
        "redirect": "/operation/ssp-ep/list",
        "exact": true
      },
      {
        "path": "/operation/ssp",
        "redirect": "/operation/ssp-ep/list",
        "exact": true
      },
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dsp",
        "name": "DSP",
        "routes": [
          {
            "path": "/dsp/list",
            "name": "DSP List",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dsp__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/dsp/list'), loading: LoadingComponent}),
            "target": "_blank",
            "exact": true
          },
          {
            "path": "/dsp/detail",
            "name": "DSP Detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dsp__detail' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/dsp/detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/dsp/registration",
            "name": "DSP Registration",
            "routes": [
              {
                "path": "/dsp/registration/list",
                "name": "Registration List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__dsp' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/register/dsp'), loading: LoadingComponent}),
                "target": "_blank",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/dsp/registration/detail",
                "name": "Registration List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__dsp__detail' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/register/dsp/detail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/ssp",
        "name": "SSP",
        "routes": [
          {
            "path": "/ssp/list",
            "name": "SSP List",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ssp__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/ssp/list'), loading: LoadingComponent}),
            "target": "_blank",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/ssp/detail",
            "name": "SSP Detail",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ssp__detail' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/ssp/detail'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/ssp/registration",
            "name": "SSP Registration",
            "routes": [
              {
                "path": "/ssp/registration/list",
                "name": "Registration List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__ssp' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/register/ssp'), loading: LoadingComponent}),
                "target": "_blank",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/ssp/registration/detail",
                "name": "Registration List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__ssp__detail' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/register/ssp/detail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/rtb",
        "name": "RTB",
        "routes": [
          {
            "path": "/rtb/offer",
            "name": "Offer",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rtb__offer' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/rtb/offer'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/RtbLayout'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/rtb/ad-set",
            "name": "Ad-Set",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rtb__ad-set' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/rtb/ad-set'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/RtbLayout'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/rtb/creative",
            "name": "Creative",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rtb__creative' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/rtb/creative'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/RtbLayout'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/rtb/ssp-budget-units",
            "name": "Ssp-Budget-Units",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rtb__by-ssp-unit' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/rtb/by-ssp-unit'), loading: LoadingComponent}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/RtbLayout'), loading: LoadingComponent})],
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/report",
        "name": "Report",
        "routes": [
          {
            "path": "/report/adx",
            "name": "Adx Report",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__adx' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/report/adx'), loading: LoadingComponent}),
            "target": "_blank",
            "exact": true
          },
          {
            "path": "/report/dsp",
            "name": "Dsp Report",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__dsp' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/report/dsp'), loading: LoadingComponent}),
            "target": "_blank",
            "exact": true
          },
          {
            "path": "/report/dsp-illegal",
            "name": "Dsp Illegal Report",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__dsp-illegal' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/report/dsp-illegal'), loading: LoadingComponent}),
            "target": "_blank",
            "exact": true
          },
          {
            "path": "/report/topbundle",
            "name": "Top Bundle ID Report",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__topbundle' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/report/topbundle'), loading: LoadingComponent}),
            "target": "_blank",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "redirect": "/user/login",
            "exact": true
          },
          {
            "path": "/user/login",
            "name": "login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__loginv2' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/user/loginv2'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/operation",
        "name": "Operation",
        "routes": [
          {
            "path": "/operation/dsp",
            "name": "DSP",
            "routes": [
              {
                "path": "/operation/dsp/list",
                "name": "List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__dsp__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/dsp/list'), loading: LoadingComponent}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/OperationLayout'), loading: LoadingComponent})],
                "target": "_blank",
                "exact": true
              },
              {
                "path": "/operation/dsp/approve",
                "name": "Approve",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__dsp__approve' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/dsp/approve'), loading: LoadingComponent}),
                "target": "_blank",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/operation/dsp/block",
                "name": "Block",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__dsp__block' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/dsp/block'), loading: LoadingComponent}),
                "target": "_blank",
                "hideInMenu": true,
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation/dsp-ep",
            "name": "DSP Unit",
            "routes": [
              {
                "path": "/operation/dsp-ep/list",
                "name": "List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__dsp-ep__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/dsp-ep/list'), loading: LoadingComponent}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/EpOperationLayout'), loading: LoadingComponent})],
                "target": "_blank",
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation/ssp",
            "name": "SSP",
            "routes": [
              {
                "path": "/operation/ssp/list",
                "name": "List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__ssp__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/ssp/list'), loading: LoadingComponent}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/OperationLayout'), loading: LoadingComponent})],
                "target": "_blank",
                "exact": true
              },
              {
                "path": "/operation/ssp/approve",
                "name": "Approve",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__ssp__approve' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/ssp/approve'), loading: LoadingComponent}),
                "target": "_blank",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/operation/ssp/block",
                "name": "Block",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__ssp__block' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/ssp/block'), loading: LoadingComponent}),
                "target": "_blank",
                "hideInMenu": true,
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation/ssp-ep",
            "name": "SSP EP",
            "routes": [
              {
                "path": "/operation/ssp-ep/list",
                "name": "List",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__ssp-ep__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/ssp-ep/list'), loading: LoadingComponent}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/EpOperationLayout'), loading: LoadingComponent})],
                "target": "_blank",
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/operation/rule",
            "name": "Rule",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__operation__floatRule__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/operation/floatRule/list'), loading: LoadingComponent}),
            "target": "_blank",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/billing",
        "name": "Billing",
        "routes": [
          {
            "path": "/billing/dsp",
            "name": "DSP",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__billing__dsp' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/billing/dsp'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/billing/ssp",
            "name": "SSP",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__billing__ssp' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/billing/ssp'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/export",
        "name": "Export",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__export__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/export/list'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/settings",
        "name": "Settings",
        "routes": [
          {
            "path": "/settings/collection",
            "name": "Collection",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__collection' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/settings/collection'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "Management",
        "path": "/management",
        "routes": [
          {
            "name": "User",
            "path": "/management/user",
            "routes": [
              {
                "name": "List",
                "path": "/management/user/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__user__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/management/user/list'), loading: LoadingComponent}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/UserGroupLayout'), loading: LoadingComponent})],
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "Permission",
                "path": "/management/user/permission",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__user__permission' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/management/user/permission'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "Operation Log",
                "path": "/management/user/log",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__user__operation-log' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/management/user/operation-log'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "Group",
            "path": "/management/group",
            "routes": [
              {
                "name": "List",
                "path": "/management/group/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__group__list' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/management/group/list'), loading: LoadingComponent}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/layouts/UserGroupLayout'), loading: LoadingComponent})],
                "exact": true
              },
              {
                "name": "Permission",
                "path": "/management/group/permission",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__group__permission' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/management/group/permission'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "Permission",
            "path": "/management/permission",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__management__permission' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/management/permission'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/change-password",
        "name": "Change Password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__change-password' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/change-password'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/register",
        "name": "Register",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__form' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/register/form'), loading: LoadingComponent}),
        "hideInMenu": true,
        "layout": false,
        "exact": true
      },
      {
        "path": "/test-page",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__test-page' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/test-page'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/403",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/403'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/404",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/Users/van/WebstormProjects/admile-adx-platform-web/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
