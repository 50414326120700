/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { message } from 'antd';
import { history } from 'umi';
import { extend } from 'umi-request';
import SessionService from '@/services/session.service';
import PermissionService from '@/services/permission.service';

const REQUEST_ERROR_KEY = 'REQUEST_ERROR';

const REQUEST_GLOBAL_LOADING_KEY = 'REQUEST_GLOBAL_LOADING_KEY';

const REQUEST_TIME_OUT = 10 * 60 * 1000;

const USER_NETWORK_ERROR =
  "An exception has occurred in your network. You can't connect to the server!";

const USER_REQUEST_ERROR = 'An error occurred in the request. Please try again later!';

const codeMessage = {
  200: 'Request success.',
  201: 'Request success.',
  400: 'Bad request.',
  401: 'No permission.',
  403: 'Login information is invalid, please login again.',
  404: 'The request is made for a record that does not exist.',
  406: 'The format of the request is not available.',
  410: 'The requested resource is permanently deleted.',
  422: 'A validation error occurred while creating an object.',
  500: 'Server error, please check the server.',
  502: 'Bad Gateway.',
  503: 'Service unavailable, server temporarily overloaded or maintained.',
  504: 'Gateway timeout.',
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  credentials: 'include', // 默认请求是否带上cookie
  timeout: REQUEST_TIME_OUT,
  headers: {
    'User-Type': 'ADX',
  },
});

// 对请求前、响应后做处理，处理 token
request.interceptors.request.use((url, options) => {
  const { useLoading, headers } = options;

  const { location } = history;

  const pathPermissionConfig = SessionService.getPathPermissionConfig();

  const pathname = PermissionService.splitRouteSub(location.pathname);

  const userPermission =
    pathPermissionConfig && pathPermissionConfig[pathname]
      ? pathPermissionConfig[pathname]
      : 'none';

  if (useLoading) {
    message
      .loading({
        content: 'Request processing...',
        key: REQUEST_GLOBAL_LOADING_KEY,
      })
      .then();
  }

  const token = localStorage.getItem('token') || 'ccd3538d-445a-4475-bdf9-83fdebf07d68';

  return {
    url,
    options: {
      ...options,
      headers: {
        ...headers,
        token,
        'Menu-Permission': userPermission,
      },
    },
  };
});

/**
 * @description: 响应拦截
 */
request.interceptors.response.use(async (response, options) => {
  const { getResponse = false, useToast = true, useLoading } = options;

  if (useLoading) {
    message.destroy(REQUEST_GLOBAL_LOADING_KEY);
  }

  // 处理 status
  if (response && response.status && response.status !== 200) {
    const errorText = codeMessage[response.status] || response.statusText;

    message.error({
      content: `Fail: ${errorText}`,
      key: REQUEST_ERROR_KEY,
    });

    const { pathname } = history.location;

    if ((response.status === 401 || response.status === 403) && pathname !== '/user/login') {
      // history.replace(`/user/login?redirect=${encodeURIComponent(window.location.href)}`);
      history.replace('/user/login');

      return Promise.resolve({
        code: response.status,
        message: response.statusText,
      });
    }

    return Promise.reject(codeMessage[response.status] || response.statusText);
  }

  if (!response) {
    message.error({
      content: USER_NETWORK_ERROR,
      key: REQUEST_ERROR_KEY,
    });

    return Promise.reject(USER_REQUEST_ERROR);
  }

  // 处理 code
  const responseData = await response.json();

  if (responseData && response.status === 200 && responseData.code !== 0) {
    if (useToast) {
      // 统一处理
      message.info({
        content: responseData.message || USER_REQUEST_ERROR,
        key: REQUEST_ERROR_KEY,
      });
    }

    // !这里的reject 在redux 返回出去的data里面获取不到
    if (!getResponse) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ ...responseData, data: {} });
    }

    // 单独处理
    return Promise.resolve(responseData);
  }

  const data = getResponse ? responseData : responseData.data;

  return Promise.resolve(data);
});

export default request;
