import request from '@/http';

export function listDsp() {
  const url = '/api/adx/dsp/list';

  return request.get(url);
}

export function listDspManager() {
  const url = '/api/adx/dsp/manager';

  return request.get(url);
}

export function listSSP() {
  const url = '/api/adx/ssp/list';

  return request.get(url);
}

export function listSSPManager() {
  const url = '/api/adx/ssp/manager';

  return request.get(url);
}

export function getCountrySelectList() {
  const url = '/api/common/location/country';
  return request.get(url);
}

export function listTimezone() {
  const url = '/api/common/timezone';

  return request.get(url);
}

export function listDspEp() {
  const url = '/api/adx/dsp/ep/list';

  return request.get(url);
}

export function listSspEp() {
  const url = '/api/adx/ssp/ep/list';

  return request.get(url);
}
