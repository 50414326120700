import { defaultListData } from '@/models/common.js';
import {
  listSspUnit,
  listOperationDspApproveUnit,
  listOperationDspBlockUnit,
  listDspOffer,
  listInternalDspEp,
  listMatchedSspEp,
} from './api';
import {
  transformSspUnitList,
  transformOfferAdset,
  transformInternalDspEp,
  transformDspEpItems,
} from './util';
import SspUnitOperationService from '@/pages/operation/ssp-unit/service';

const operationSspUnitModel = {
  namespace: 'operationSspUnit',
  state: {
    sspUnitData: defaultListData,
    approveUnitSelectList: [],
    blockUnitSelectList: [],
    dspOffer: [],
    operationState: {},
    hasOperatedIds: [],
    filterFormDspOptions: [],
    approvedDspOptions: [],

    internalDspEpList: [],
    externalSspEpList: [],
  },
  effects: {
    *listSspUnit({ payload }, { put, call }) {
      const data = yield call(listSspUnit, payload);

      yield put({
        type: 'listSspUnitSuccess',
        payload: data,
      });
    },

    *listMatchedSspEp({ payload }, { put, call }) {
      const data = yield call(listMatchedSspEp, payload);

      yield put({
        type: 'listMatchedSspEpSuccess',
        payload: data,
      });
    },

    *listOperationDspApproveUnit({ payload }, { put, call }) {
      const data = yield call(listOperationDspApproveUnit, payload);

      yield put({
        type: 'listOperationDspApproveUnitSuccess',
        payload: data,
      });
    },

    *listOperationDspBlockUnit({ payload }, { put, call }) {
      const data = yield call(listOperationDspBlockUnit, payload);

      yield put({
        type: 'listOperationDspBlockUnitSuccess',
        payload: data,
      });
    },

    *listDspOffer({ payload }, { put, call }) {
      const internalDspEpList = yield call(listInternalDspEp, payload);

      const dspOffer = yield call(listDspOffer, payload);

      yield put({
        type: 'listDspOfferSuccess',
        payload: {
          dspOffer,
          internalDspEpList,
        },
      });
    },

    *listInternalDspEp({ payload }, { put, call }) {
      const data = yield call(listInternalDspEp, payload);

      yield put({
        type: 'listInternalDspEpSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listSspUnitSuccess(state, { payload }) {
      const { list, paginator } = payload;

      return {
        ...state,
        sspUnitData: {
          list: transformSspUnitList(list),
          paginator,
        },
      };
    },

    listMatchedSspEpSuccess(state, { payload }) {
      return {
        ...state,
        externalSspEpList: payload,
      };
    },

    listOperationDspApproveUnitSuccess(state, { payload }) {
      return {
        ...state,
        approveUnitSelectList: transformDspEpItems(payload),
      };
    },

    listOperationDspBlockUnitSuccess(state, { payload }) {
      return {
        ...state,
        blockUnitSelectList: transformDspEpItems(payload),
      };
    },

    updateOperationState(state, { payload }) {
      const { id, isLoading } = payload;

      const { operationState, hasOperatedIds } = state;

      const flag = hasOperatedIds.includes(id);

      operationState[id] = isLoading;

      if (!flag) {
        hasOperatedIds.push(id);
      }

      return {
        ...state,
        hasOperatedIds: [...hasOperatedIds],
        operationState: { ...operationState },
      };
    },

    updateFilterFormDspOptions(state, { payload }) {
      return {
        ...state,
        filterFormDspOptions: payload?.map((item) => ({
          ...item,
          name: `${item.name}#${item.id}`,
        })),
      };
    },

    updateApprovedDspOptions(state, { payload }) {
      return {
        ...state,
        approvedDspOptions: payload,
      };
    },

    listDspOfferSuccess(state, { payload }) {
      const { dspOffer = [], internalDspEpList = [] } = payload;

      const mergedDspOffer = transformOfferAdset(dspOffer);

      const mergeInternalDspEpList = transformInternalDspEp(internalDspEpList);

      const formatDspOffer = SspUnitOperationService.disableNotMatchedInternalDspEp(
        mergeInternalDspEpList,
        mergedDspOffer,
      );

      return {
        ...state,
        dspOffer: formatDspOffer,
        internalDspEpList: mergeInternalDspEpList,
      };
    },

    listInternalDspEpSuccess(state, { payload }) {
      return {
        ...state,
        internalDspEpList: transformInternalDspEp(payload),
      };
    },
  },
};

export default operationSspUnitModel;
