import { trim } from 'lodash';
import UserService from '@/pages/user/user.service';
import RtbService from '@/pages/rtb/rtb.service';
import CommonService from '@/services/common.service';

class InitService {
  static check() {
    return !CommonService.getIsRegisterPage();
  }

  static run() {
    InitService.recoverSystemCopy();

    if (InitService.check()) {
      // 保底执行了获取rtb tracking params
      RtbService.getRtbTrackingConfig()?.then();
    }
  }

  static async getUserInfo() {
    if (InitService.check()) {
      return await UserService.getUserInfo();
    }

    return {};
  }

  static recoverSystemCopy() {
    document.oncopy = (e) => {
      const text = window.getSelection()?.toString();

      // @ts-ignore
      e.clipboardData.setData('text/plain', trim(text));

      e.preventDefault();
    };
  }
}

export default InitService;
