class DspBillingInitData {
  static generateInvoiceInfo = {
    dspIdentity: null,
    identity: null,
    wire: null,
    payonner: null,
    paypal: null,
  };
}

export default DspBillingInitData;
