import request from '@/http';
import Utils from '@/utils';
import type { NSsp } from '@/models/ssp/interface';

export function listSsp(params: NSsp.ListSspParams) {
  const url = '/api/adx/ssp';

  return request.get(url, {
    params: Utils.filterNullable(params),
  });
}

export function createSsp(ssp: NSsp.AddSspParams) {
  const url = '/api/adx/ssp';

  return request.post(url, {
    data: ssp,
    useLoading: true,
  });
}

export function updateListSsp(ssp: NSsp.UpdateSspParams) {
  const url = '/api/adx/ssp';

  return request.put(url, {
    data: ssp,
    useLoading: true,
  });
}

export function updateDetailSsp(ssp: NSsp.UpdateSspParams) {
  const url = '/api/adx/ssp/detail';

  return request.put(url, {
    data: ssp,
  });
}

export function deleteSsp(sspIds: number[]) {
  const url = '/api/adx/ssp';

  return request.delete(url, {
    params: {
      ids: sspIds,
    },
  });
}

// detail
export function detailSsp(sspId: number) {
  const url = `/api/adx/ssp/detail/${sspId}`;

  return request.get(url);
}

// dsp integration
export function listSspIntegration(body: NSsp.ListSspIntegrationParams) {
  const { sspId, ...params } = body;

  const url = `/api/adx/ssp/integration/${sspId}`;

  return request.get(url, {
    params,
  });
}

// add dsp integration
export function addSspIntegration(body: NSsp.AddUpdateIntegrationBody) {
  const url = '/api/adx/ssp/integration';

  return request.post(url, {
    data: body,
    useLoading: true,
  });
}

// edit dsp integration
export function updateSspIntegration(body: NSsp.AddUpdateIntegrationBody) {
  const { id, ...data } = body;

  const url = `/api/adx/ssp/integration/${id}`;

  return request.put(url, {
    data,
  });
}

// 检查当前ssp integration 是否已经被分配 dsp (operation)
export function checkSspIntegrationUsing(params: NSsp.CheckSspIntegrationParams) {
  const { id, ...restBody } = params;

  const url = `/api/adx/ssp/integration/operation/check/${id}`;

  return request.post(url, {
    data: {
      ...restBody,
    },
    getResponse: true,
    useToast: false,
  });
}
