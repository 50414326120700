import type { Effect, Reducer } from 'umi';
import type { ListResponse } from '@/models/interface';
import type { NDspOperation } from './interface';
import { defaultListData } from '@/models/common.js';
import { listDspUnit, listOperationSspUnit } from './api';
import { transformDspUnitList } from './util';

interface State {
  dspUnitData: ListResponse<NDspOperation.DspUnitItem>;
  unitList: NDspOperation.SspUnitItem[];
  operationState: any;
  hasOperatedIds: number[];
  filterFormSspOptions: any[];
  approvedSspOptions: any[];
}

interface Model {
  namespace: string;
  state: State;
  effects: {
    listDspUnit: Effect;
    listOperationSspUnit: Effect;
  };
  reducers: {
    listDspUnitSuccess: Reducer;
    listOperationSspUnitSuccess: Reducer;
    updateOperationState: Reducer;
    updateFilterFormSspOptions: Reducer;
    updateApprovedSspOptions: Reducer;
  };
}

const operationDspModel: Model = {
  namespace: 'operationDspUnitState',
  state: {
    dspUnitData: defaultListData,
    unitList: [],
    operationState: {},
    hasOperatedIds: [],
    filterFormSspOptions: [],
    approvedSspOptions: [],
  },
  effects: {
    *listDspUnit({ payload }, { put, call }) {
      const data = yield call(listDspUnit, payload);

      yield put({
        type: 'listDspUnitSuccess',
        payload: data,
      });
    },

    *listOperationSspUnit({ payload }, { put, call }) {
      const data = yield call(listOperationSspUnit, payload);

      yield put({
        type: 'listOperationSspUnitSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listDspUnitSuccess(state, { payload }) {
      const { list, paginator } = payload;

      return {
        ...state,
        dspUnitData: {
          list: transformDspUnitList(list),
          paginator,
        },
      };
    },

    listOperationSspUnitSuccess(state, { payload }) {
      return {
        ...state,
        unitList: payload,
      };
    },

    updateOperationState(state, { payload }) {
      const { id, isLoading } = payload;

      const { operationState, hasOperatedIds } = state;

      const flag = hasOperatedIds.includes(id);

      operationState[id] = isLoading;

      if (!flag) {
        hasOperatedIds.push(id);
      }

      return {
        ...state,
        hasOperatedIds: [...hasOperatedIds],
        operationState: { ...operationState },
      };
    },

    updateFilterFormSspOptions(state, { payload }) {
      return {
        ...state,
        filterFormSspOptions: payload?.map((item: any) => ({
          ...item,
          name: `${item.name}#${item.id}`,
        })),
      };
    },

    updateApprovedSspOptions(state, { payload }) {
      return {
        ...state,
        approvedSspOptions: payload,
      };
    },
  },
};

export default operationDspModel;
