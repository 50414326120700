import { listPagePermission, listApiPermission } from './permission.api';
import { transformPagePermissionList, transformApiPermissionList } from './permission.util';
import ModelConstant from '@/models/constant';

const permissionModel = {
  namespace: 'permissionState',
  state: {
    pageData: ModelConstant.ListResponse,
    apiData: ModelConstant.ListResponse,
  },
  effects: {
    *listPagePermission({ payload }, { call, put }) {
      const data = yield call(listPagePermission, payload);

      yield put({
        type: 'listPagePermissionSuccess',
        payload: data,
      });
    },

    *listApiPermission({ payload }, { call, put }) {
      const data = yield call(listApiPermission, payload);

      yield put({
        type: 'listApiPermissionSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listPagePermissionSuccess(state, { payload }) {
      const { list = [], paginator = ModelConstant.Paginator } = payload;

      return {
        ...state,
        pageData: {
          list: transformPagePermissionList(list),
          paginator,
        },
      };
    },

    listApiPermissionSuccess(state, { payload }) {
      const { list = [], paginator = ModelConstant.Paginator } = payload;

      return {
        ...state,
        apiData: {
          list: transformApiPermissionList(list),
          paginator,
        },
      };
    },
  },
};

export default permissionModel;
