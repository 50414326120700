import ModelConstant from '@/models/constant';

import {
  listDspRegistration,
  dspRegistrationDetail,
  listSspRegistration,
  sspRegistrationDetail,
} from './api';
import { transformApproveRegisterList, transformRegisterDetail } from './util';

const approveAdvertiserModel = {
  namespace: 'registerState',
  state: {
    dspRegisterData: ModelConstant.ListResponse,
    dspRegisterDetail: {},

    sspRegisterData: ModelConstant.ListResponse,
    sspRegisterDetail: {},
  },
  effects: {
    *listDspRegistration({ payload }, { call, put }) {
      const data = yield call(listDspRegistration, payload);

      yield put({
        type: 'listDspRegistrationSuccess',
        payload: data,
      });
    },

    *dspRegistrationDetail({ payload }, { call, put }) {
      const data = yield call(dspRegistrationDetail, payload);

      yield put({
        type: 'dspRegistrationDetailSuccess',
        payload: data,
      });
    },

    *listSspRegistration({ payload }, { call, put }) {
      const data = yield call(listSspRegistration, payload);

      yield put({
        type: 'listSspRegistrationSuccess',
        payload: data,
      });
    },

    *sspRegistrationDetail({ payload }, { call, put }) {
      const data = yield call(sspRegistrationDetail, payload);

      yield put({
        type: 'sspRegistrationDetailSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listDspRegistrationSuccess(state, { payload }) {
      const {
        list = [],
        paginator = {
          totalNum: 0,
          totalPage: 1,
          pageSize: 10,
          pageIndex: 1,
        },
      } = payload || {};

      return {
        ...state,
        dspRegisterData: {
          list: transformApproveRegisterList(list),
          paginator,
        },
      };
    },

    dspRegistrationDetailSuccess(state, { payload }) {
      return {
        ...state,
        dspRegisterDetail: transformRegisterDetail(payload),
      };
    },

    listSspRegistrationSuccess(state, { payload }) {
      const {
        list = [],
        paginator = {
          totalNum: 0,
          totalPage: 1,
          pageSize: 10,
          pageIndex: 1,
        },
      } = payload || {};

      return {
        ...state,
        sspRegisterData: {
          list: transformApproveRegisterList(list),
          paginator,
        },
      };
    },

    sspRegistrationDetailSuccess(state, { payload }) {
      return {
        ...state,
        sspRegisterDetail: transformRegisterDetail(payload),
      };
    },
  },
};

export default approveAdvertiserModel;
