import { isArray } from 'lodash';
import TimeService from '@/services/time.service';
import { CollectionStateEnum } from '@/models/settings/collection/collection.enum';

export function transformIdentityList(list) {
  if (isArray(list)) {
    return sortByDefault(list).map((item) => {
      const { createTime, updateTime, status, isDefault } = item;

      return {
        ...item,
        createTimeText: TimeService.formatTime(createTime),
        updateTimeText: TimeService.formatTime(updateTime),
        statusText: CollectionStateEnum.getText(status),
        isDefaultText: String(isDefault),
      };
    });
  }
}

export function transformWireList(list) {
  if (isArray(list)) {
    return sortByDefault(list).map((item) => {
      const { createTime, updateTime, status, isDefault } = item;

      return {
        ...item,
        createTimeText: TimeService.formatTime(createTime),
        updateTimeText: TimeService.formatTime(updateTime),
        statusText: CollectionStateEnum.getText(status),
        isDefaultText: String(isDefault),
      };
    });
  }
}

export function sortByDefault(list) {
  if (!isArray(list)) {
    return [];
  }

  if (list.length === 1) {
    return list;
  }

  return list.sort((item) => (item.isDefault ? -1 : 0));
}
