import type { NExport } from './interface';
import TimeService from '@/services/time.service';
import NumberFormatService from '@/services/numberformat.service';

export function transformExportList(list: NExport.IExportItem[]) {
  if (list) {
    return list.map((item) => {
      return {
        ...item,
        exportTimeValue: TimeService.formatTime(item.exportTime),
        formatKB: NumberFormatService.formatFileSize(item.size),
      };
    });
  }

  return list;
}
