import request from '@/http';

// 获取系统用户组
export function listSystemUserGroup(params) {
  const url = '/api/adx/group';

  return request.get(url, {
    params,
  });
}

// 创建系统用户组
export function createSystemUserGroup(body) {
  const url = '/api/adx/group';

  return request.post(url, {
    data: body,
    useLoading: true,
  });
}

// 更新系统用户组
export function updateSystemUserGroup(body) {
  const url = '/api/adx/group';

  return request.put(url, {
    data: body,
    useLoading: true,
  });
}

// 为用户组添加权限
export function authorizeSystemUserGroupPermission(body) {
  const url = '/api/adx/group/permission';

  return request.put(url, {
    data: body,
    useLoading: true,
  });
}

// 查询某个组的权限信息
export function getSystemUserGroupPermission(id) {
  const url = `/api/adx/group/${id}/permission`;

  return request.get(url);
}
