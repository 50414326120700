import { defaultListData } from '@/models/common.js';
import { listApprovalSspUnit, listDspUnitOperation } from './api';
import { transformDspUnitList } from './util';

const operationDspUnitModel = {
  namespace: 'operationDspUnit',
  state: {
    dspUnitData: defaultListData,
    unitList: [],
    operationState: {},
    hasOperatedIds: [],
    filterFormSspOptions: [],
    approvedSspOptions: [],
  },
  effects: {
    *listDspUnitOperation({ payload }, { put, call }) {
      const data = yield call(listDspUnitOperation, payload);

      yield put({
        type: 'listDspUnitOperationSuccess',
        payload: data,
      });
    },

    *listApprovalSspUnit({ payload }, { put, call }) {
      const data = yield call(listApprovalSspUnit, payload);

      yield put({
        type: 'listApprovalSspUnitSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listDspUnitOperationSuccess(state, { payload }) {
      const { list, paginator } = payload;

      return {
        ...state,
        dspUnitData: {
          list: transformDspUnitList(list),
          paginator,
        },
      };
    },

    listApprovalSspUnitSuccess(state, { payload }) {
      return {
        ...state,
        unitList: payload,
      };
    },

    updateOperationState(state, { payload }) {
      const { id, isLoading } = payload;

      const { operationState, hasOperatedIds } = state;

      const flag = hasOperatedIds.includes(id);

      operationState[id] = isLoading;

      if (!flag) {
        hasOperatedIds.push(id);
      }

      return {
        ...state,
        hasOperatedIds: [...hasOperatedIds],
        operationState: { ...operationState },
      };
    },

    updateFilterFormSspOptions(state, { payload }) {
      return {
        ...state,
        filterFormSspOptions: payload?.map((item) => ({
          ...item,
          name: `${item.name}#${item.id}`,
        })),
      };
    },

    updateApprovedSspOptions(state, { payload }) {
      return {
        ...state,
        approvedSspOptions: payload,
      };
    },
  },
};

export default operationDspUnitModel;
