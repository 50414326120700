import { isArray } from 'lodash';
import NumberFormatService from '@/services/numberformat.service';

export function transformDspReportData(data) {
  if (data) {
    const { list, summary, ...rest } = data;

    const { payout, totalPayout, ...restSummary } = summary || {};

    return {
      list: isArray(list)
        ? list.map((item) => {
            return {
              ...item,
              payout: NumberFormatService.toFixed(item.payout, '0.[0000]'),
            };
          })
        : [],
      summary: {
        ...restSummary,
        payout: NumberFormatService.toFixed(payout, '0.[0000]'),
        totalPayout: NumberFormatService.toFixed(totalPayout, '0.[0000]'),
      },
      ...rest,
    };
  }

  return {
    list: [],
    paginator: {},
    summary: {
      bids: 0,
      impressions: 0,
      illegalImpressions: 0,
      clicks: 0,
      installs: 0,
      billedEvents: 0,
      revenue: 0,
      payout: 0,
      profit: 0,
      matchedBids: 0,
      allRequests: 0,

      totalBids: 0,
      totalImpressions: 0,
      totalIllegalImpressions: 0,
      totalClicks: 0,
      totalInstalls: 0,
      totalBilledEvents: 0,
      totalRevenue: 0,
      totalPayout: 0,
      totalProfit: 0,
      totalMatchedBids: 0,
      totalAllRequests: 0,
    },
  };
}
