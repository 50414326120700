import { listSspBudgetUnit } from './api';
import { transformSspUnit } from './util';

const bySspUnitModel = {
  namespace: 'bySspUnitState',
  state: {
    sspUnitData: {},
  },
  effects: {
    *listSspBudgetUnit({ payload }, { call, put }) {
      const data = yield call(listSspBudgetUnit, payload);

      yield put({
        type: 'listSspBudgetUnitSuccess',
        payload: data,
      });

      return data;
    },
  },
  reducers: {
    listSspBudgetUnitSuccess(state, { payload }) {
      return {
        ...state,
        sspUnitData: transformSspUnit(payload),
      };
    },
  },
};

export default bySspUnitModel;
