class SessionService {
  static getSessionMenuData() {
    let menuData = [];

    try {
      menuData = JSON.parse(window.sessionStorage.getItem('menuData'));
    } catch (e) {}

    return menuData;
  }

  static setSessionMenuData(menuData) {
    window.sessionStorage.setItem('menuData', JSON.stringify(menuData));
  }

  static clearSessionMenuData() {
    window.sessionStorage.removeItem('menuData');
  }

  static updatePathPermissionConfig(data) {
    window.sessionStorage.setItem('pathPermissionConfig', JSON.stringify(data));
  }

  static getPathPermissionConfig() {
    let pathPermissionConfig = {};

    try {
      pathPermissionConfig = JSON.parse(window.sessionStorage.getItem('pathPermissionConfig'));
    } catch (e) {}

    return pathPermissionConfig || {};
  }
}

export default SessionService;
