import request from '@/http';
import type { NDspOperation } from './interface';
import type { ListResponse } from '@/models/interface';

export function listDspUnit(
  params: NDspOperation.ListDspUnitParams,
): Promise<ListResponse<NDspOperation.DspUnitItem>> {
  const url = '/api/adx/operation/dsp';

  return request.get(url, {
    params,
  });
}

export function listOperationSspUnit(params: NDspOperation.ListSspUnitParams) {
  const url = '/api/adx/operation/ssp/units';

  return request.get(url, {
    params,
  });
}

export function detailApproveDspUnit(params: NDspOperation.DetailOperation) {
  const url = '/api/adx/operation/dsp/approve';

  return request.get(url, {
    params,
  });
}

export function approveDspUnit(body: NDspOperation.ApproveDspUnitBody) {
  const url = '/api/adx/operation/dsp/approve';

  return request.post(url, {
    data: body,
  });
}

export function detailBlockDspUnit(params: NDspOperation.DetailOperation) {
  const url = '/api/adx/operation/dsp/block';

  return request.get(url, {
    params,
  });
}

export function blockDspUnit(body: NDspOperation.BlockDspUnitBody) {
  const url = '/api/adx/operation/dsp/block';

  return request.post(url, {
    data: body,
  });
}

export function deleteDspUnit(body: NDspOperation.BlockDspUnitBody) {
  const url = '/api/adx/operation/dsp/block';

  return request.delete(url, {
    data: body,
  });
}

export function reFloatRule(sspOperationId: number) {
  const url = `/api/adx/operation/refloated/${sspOperationId}`;

  return request.post(url);
}

// multiple operations
// list approved ssp
export function listDspApprovedSsp(body: any) {
  const url = '/api/adx/operation/dsp/approve/detail/batch';

  return request.post(url, {
    data: body,
  });
}

// approval dsp ssp
export function multipleApproveDspSsp(body: any) {
  const url = '/api/adx/operation/dsp/approve/batch';

  return request.post(url, {
    data: body,
  });
}

// list blocked
export function listDspBlocked(body: any) {
  const url = '/api/adx/operation/dsp/block/detail/batch';

  return request.post(url, {
    data: body,
  });
}

// multiple block dsp ssp
export function multipleBlockDspSsp(body: any) {
  const url = '/api/adx/operation/dsp/block/batch';

  return request.post(url, {
    data: body,
  });
}
