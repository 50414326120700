import { history } from 'umi';
import qs from 'qs';
import { isArray } from 'lodash';
import { listDspApprovedSsp, multipleApproveDspSsp } from '@/models/operation/dsp/api';
import DspUnitService from '@/pages/operation/dsp/service';

class DspMultipleApproveService {
  static defaultApprovedData = {
    sspOperations: [],
    highWeights: [],
    whiteLists: [],
    floorPriceRangeMin: null,
    floorPriceRangeMax: null,
    sspList: [],
  };

  static number(inp: any) {
    if (isNaN(inp)) return inp;

    return +inp;
  }

  static getUrlOperationIds() {
    // @ts-ignore
    const urlParams = qs.parse(history.location.query) || {};

    // @ts-ignore
    return Object.values(qs.parse(urlParams.operationIds));
  }

  static parseOperationIds() {
    // @ts-ignore
    const operationIds: string[] = DspMultipleApproveService.getUrlOperationIds();

    if (isArray(operationIds)) {
      return operationIds.map((string) => {
        const [dspIntegrationId, countryId, os, adFormat] = string
          ?.split('_')
          ?.map((item: any) => DspMultipleApproveService.number(item));

        return {
          dspIntegrationId,
          countryId,
          os,
          adFormat,
        };
      });
    }

    return [];
  }

  static transformApprovedDsp(list: any[]) {
    if (isArray(list)) {
      const selectedList = DspMultipleApproveService.getApprovedDspUnits();

      return list.map((item: any) => {
        const operationId = DspUnitService.generatorOperationId(item);

        const selected = selectedList?.find((dsp: any) => dsp.operationId === operationId);

        const { approveDetail = DspMultipleApproveService.defaultApprovedData, ...rest } = item;

        return {
          ...rest,
          ...approveDetail,
          operationId,
          dspUnit: selected ? selected.dspUnit : '',
        };
      });
    }

    return [];
  }

  static async listDspApprovedSsp(body: any) {
    const approvedData = await listDspApprovedSsp(body);

    return DspMultipleApproveService.transformApprovedDsp(approvedData);
  }

  static multipleApproveDspSsp(body: any) {
    return multipleApproveDspSsp(body);
  }

  static getFormNames(operationId: string) {
    return {
      sspOperations: `${operationId}|sspOperations`,
      highWeights: `${operationId}|highWeights`,
      whiteLists: `${operationId}|whiteLists`,
      floorPriceRangeMin: `${operationId}|floorPriceRangeMin`,
      floorPriceRangeMax: `${operationId}|floorPriceRangeMax`,
      sspList: `${operationId}|sspList`,
      restApprovedDspUnit: `${operationId}|restApprovedDspUnit`,
    };
  }

  static decodeValues(values: any) {
    if (values) {
      const keys = Object.keys(values);

      const urlOperationIds = DspMultipleApproveService.getUrlOperationIds();

      const ret: any[] = [];

      urlOperationIds?.forEach((urlKey) => {
        // @ts-ignore
        const dspUnit = DspUnitService.decodeOperationId(urlKey);

        // @ts-ignore
        const names = DspMultipleApproveService.getFormNames(urlKey);

        let item = {
          dspUnit,
        };

        keys.forEach((key) => {
          const [operationId, formKey] = key.split('|');

          const value = values[key];

          if (operationId === urlKey) {
            if (key === names.sspOperations) {
              item[formKey] = DspUnitService.dspUnitSspListToBody(value);

              return;
            }

            // 保留参数
            if (key === names.restApprovedDspUnit) {
              item = {
                ...value,
                ...item,
              };
            }

            item[formKey] = value;
          }
        });

        ret.push(item);
      });

      return ret;
    }

    return [];
  }

  static getFormValues(approvedList: any[]) {
    const format: any = {};

    if (isArray(approvedList)) {
      approvedList.forEach((item: any) => {
        const names = DspMultipleApproveService.getFormNames(item.operationId);

        const { restApprovedDspUnit, ...values } = DspUnitService.dspUnitApproveDetailToForm(item);

        const formKeyMap = Object.keys(names);

        formKeyMap.forEach((key) => {
          const keyValue = values[key];

          const mapValue = names[key];

          format[mapValue] = keyValue;
        });

        format.restApprovedDspUnit = restApprovedDspUnit;
      });
    }

    return format;
  }

  static updateApprovedDspUnits(list: any[]) {
    if (list && list.length) {
      localStorage.setItem('operation_approved_list', JSON.stringify(list));
    }
  }

  static getApprovedDspUnits() {
    let ret = [];

    try {
      // @ts-ignore
      ret = JSON.parse(localStorage.getItem('operation_approved_list'));
    } catch (e) {}

    return ret;
  }
}

export default DspMultipleApproveService;
