import http from '@/http';

export function listPagePermission(params) {
  const url = '/api/adx/permission/page/all';

  return http.get(url, {
    params,
  });
}

// 获取系统页面权限
export function listPagePermissionTree(params) {
  const url = '/api/adx/permission/page/tree';

  return http.get(url, {
    params,
  });
}

export function createPagePermission(body) {
  const url = '/api/adx/permission/page';

  return http.post(url, {
    data: body,
  });
}

export function updatePagePermission(body) {
  const url = '/api/adx/permission/page';

  return http.put(url, {
    data: body,
  });
}

// 为页面权限绑定api
export function pageMatchApi(body) {
  const { pageId, ...rest } = body;

  const url = `/api/adx/permission/page/${pageId}/api`;

  return http.put(url, {
    data: { ...rest },
  });
}

export function listApiPermission(params) {
  const url = '/api/adx/permission/api';

  return http.get(url, {
    params,
  });
}

export function updateApiPermission(body) {
  const url = '/api/admin/permission/api';

  return http.put(url, {
    data: body,
  });
}

// 获取系统数据权限
export function listSystemDataPermission() {
  const url = '/api/adx/permission/data';

  return http.get(url);
}
