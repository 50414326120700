import type { Effect, Reducer } from 'umi';
import { getCountrySelectList } from '@/components/BSelect/select.api';

interface CommonModelState {
  countryList: [];
}

interface CommonModel {
  namespace: string;
  state: CommonModelState;
  effects: {
    getCountrySelectList: Effect;
  };
  reducers: {
    getCountrySelectListSuccess: Reducer;
  };
}

const commonModel: CommonModel = {
  namespace: 'commonState',
  state: {
    countryList: [],
  },
  effects: {
    *getCountrySelectList(_, { call, put }) {
      const data = yield call(getCountrySelectList);

      yield put({
        type: 'getCountrySelectListSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    getCountrySelectListSuccess(state, { payload }) {
      return {
        ...state,
        countryList: payload,
      };
    },
  },
};

export default commonModel;
