import { invert, isNumber } from 'lodash';

export class GroupStatusEnum {
  static Enabled = 1;

  static Disabled = 0;

  static getText(status) {
    const nameMap = invert(GroupStatusEnum);

    if (!isNumber(status)) {
      return 'unknown';
    }

    return nameMap[status];
  }
}
