export class PageStatusEnum {
  static Enable = 1;

  static Disable = 0;
}

export class ApiStatusEnum {
  static Enable = 1;

  static Disable = 0;
}

export class PageHiddenEnum {
  static Visible = false;

  static Hidden = true;
}

export class PermissionParentEnum {
  static TopParentId = 0;
}

export class ApiLogStateEnum {
  static False = false;

  static True = true;
}

export class DataPermissionEnum {
  static Dsp = 'dsp';

  static Ssp = 'ssp';
}
