export default {
  'menu.login': 'Login',
  'menu.Dashboard': 'Dashboard',
  'menu.DSP': 'DSP',
  'menu.DSP.DSP List': 'DSP List',
  'menu.DSP.DSP Detail': 'DSP Detail',
  'menu.DSP.DSP Registration': 'DSP Registration',
  'menu.DSP.DSP Registration.Registration List': 'List',
  'menu.DSP.DSP Registration.DSP Registration Detail': 'DSP Registration Detail',

  'menu.SSP': 'SSP',
  'menu.SSP.SSP List': 'SSP List',
  'menu.SSP.SSP Detail': 'SSP Detail',
  'menu.SSP.SSP Registration': 'SSP Registration',
  'menu.SSP.SSP Registration.Registration List': 'List',
  'menu.SSP.SSP Registration.SSP Registration List': 'List',
  'menu.SSP.SSP Registration.SSP Registration Detail': 'SSP Registration Detail',

  'menu.Operation': 'Operation',
  'menu.Operation.Operation': 'Operation',
  'menu.Operation.DSP': 'DSP',
  'menu.Operation.DSP.List': 'List',
  'menu.Operation.DSP Unit': 'DSP Unit',
  'menu.Operation.DSP Unit.List': 'List',
  'menu.Operation.DSP Operation': 'DSP Operation',
  'menu.Operation.SSP': 'SSP',
  'menu.Operation.SSP.List': 'SSP List',
  'menu.Operation.SSP Operation': 'SSP Operation',
  'menu.Operation.SSP Operation.SSP Operation Block': 'SSP Operation Block',
  'menu.Operation.SSP.Block': 'SSP Block',
  'menu.Operation.DSP.Block': 'Block',
  'menu.Operation.SSP.Approve': 'SSP Approve',
  'menu.Operation.SSP Operation.SSP Operation Approve': 'SSP Operation Approve',
  'menu.Operation.New Operation': 'New Operation',
  'menu.Operation.SSP EP Operation': 'New Operation',
  'menu.Operation.DSP EP Operation': 'New Operation',
  'menu.Operation.New Operation.DSP EP Operation List': 'DSP EP',
  'menu.Operation.DSP EP Operation List': 'DSP EP',
  'menu.Operation.SSP EP Operation.SSP EP Operation List': 'SSP EP',
  'menu.Operation.SSP EP.List': 'SSP EP List',
  'menu.Operation.SSP EP': 'SSP EP',

  'menu.Operation.Rule': 'Operation Rule',

  'menu.Report': 'Report',
  'menu.Report.Adx Report': 'ADX Report',
  'menu.Report.Dsp Report': 'DSP Report',
  'menu.Report.Top Bundle ID Report': 'Top Bundle ID Report',
  'menu.Report.Dsp Illegal Report': 'DSP illegal Report',

  'menu.Export': 'Export List',

  'menu.Change Password': 'Change Password',
  'menu.Settings': 'Settings',
  'menu.Settings.Collection': 'Collection',

  'menu.Billing': 'Billing',
  'menu.Billing.DSP': 'DSP Billing',
  'menu.Billing.SSP': 'SSP Billing',

  'menu.RTB': 'RTB',
  'menu.RTB.Offer': 'Offer',
  'menu.RTB.Ad-Set': 'Ad Set',
  'menu.RTB.Creative': 'Creative',
  'menu.RTB.Ssp-Budget-Units': 'SSP Budget Units',

  'menu.Management': 'Management',
  'menu.Management.User': 'System User',
  'menu.Management.User.User': 'System User',
  'menu.Management.User.List': 'User List',
  'menu.Management.User.Permission': 'Permission',
  'menu.Management.User.User Permission': 'User Permission Detail',
  'menu.Management.User.Operation Log': 'Operation Log',
  'menu.Management.Permission': 'Permission',
  'menu.Management.Group': 'User Group',
  'menu.Management.Group.List': 'Group List',
  'menu.Management.Group.Permission': 'Group Permission Detail',

  'menu.Register': 'Register',
};
