import { DspUnitStatus } from '@/models/operation/dsp/enum';

class DspUnitConstant {
  static ONLINE_DSP_SUIBIAN_ID: number = 52;

  static ONLINE_RV_INHOUSE_PREFIX = 'RV-inhouse';

  static statusList = [
    {
      name: 'Active',
      id: DspUnitStatus.Active,
    },
    {
      name: 'Paused',
      id: DspUnitStatus.Paused,
    },
    {
      name: 'Deleted',
      id: DspUnitStatus.Deleted,
    },
  ];

  static sspAllItem = {
    sspIntegrationId: -1,
    sspUnit: 'All of them',
  };
}

export default DspUnitConstant;
