import { listDspBilling, generatorInvoiceNo } from './dsp.billing.api';
import { transformDspBillingData } from './dsp.billing.util';
import { defaultListData } from '@/models/common.js';
import DspBillingInitData from '@/models/billing/dsp/dsp.billing.init';

const dspBillingModel = {
  namespace: 'dspBillingState',
  state: {
    dspBillingData: defaultListData,
    generateInvoiceInfo: DspBillingInitData.generateInvoiceInfo,
    activeViewBillingRecord: {},
    invoiceNO: null,
  },
  effects: {
    *listDspBilling({ payload }, { call, put }) {
      const data = yield call(listDspBilling, payload);

      yield put({
        type: 'listDspBillingSuccess',
        payload: data,
      });

      return data;
    },

    *generatorInvoiceNo({ payload }, { call, put }) {
      const data = yield call(generatorInvoiceNo, payload);

      yield put({
        type: 'generatorInvoiceNoSuccess',
        payload: data,
      });

      return data;
    },
  },
  reducers: {
    listDspBillingSuccess(state, { payload }) {
      return {
        ...state,
        dspBillingData: transformDspBillingData(payload),
      };
    },

    updateGenerateInvoiceData(state, { payload }) {
      return {
        ...state,
        generateInvoiceInfo: payload,
      };
    },

    updateActiveBillingRecord(state, { payload }) {
      return {
        ...state,
        activeViewBillingRecord: payload,
      };
    },

    generatorInvoiceNoSuccess(state, { payload }) {
      return {
        ...state,
        invoiceNO: payload,
      };
    },
  },
};

export default dspBillingModel;
