import React from 'react';

const AdminUserContext = React.createContext(false);

const CanCreateOperationContext = React.createContext(true);

const IsAdxBDContext = React.createContext(true);

const useAdminUser = () => React.useContext(AdminUserContext);

const useCanCreateOperation = () => React.useContext(CanCreateOperationContext);

const useIsAdxBD = () => React.useContext(IsAdxBDContext);

export {
  AdminUserContext,
  useAdminUser,
  CanCreateOperationContext,
  useCanCreateOperation,
  IsAdxBDContext,
  useIsAdxBD,
};
