export class SspUnitStatus {
  static Active = 'Active';

  static Paused = 'Paused';

  static Deleted = 'Deleted';
}

export class SspOperationType {
  static Approve = 'Approve';

  static Block = 'Block';
}

export class SspOperation {
  static MULTIPLE = 'MULTIPLE';

  static OPERATION = 'OPERATION';
}

export class DspOperationStateEnum {
  static ON = 1;

  static OFF = 0;
}
