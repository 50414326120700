import { isArray } from 'lodash';
import TimeService from '@/services/time.service';
import { ApproveItemStatus } from '@/pages/register/enum';

export function transformApproveRegisterList(list) {
  if (isArray(list) && list.length) {
    return list.map((reg) => {
      const [createTimeValue, updateTimeValue] = TimeService.multipleFormatTime([
        reg.createTime,
        reg.updateTime,
      ]);

      return {
        ...reg,
        createTimeValue,
        updateTimeValue,
        approvedName: ApproveItemStatus.getText(reg.approved),
      };
    });
  }

  return [];
}

export function transformRegisterDetail(data) {
  if (data) {
    const { basic, questionary, approved } = data;

    let basicToObject = {};

    let questionaryToObject = {};

    let questionToObject = {};

    try {
      basicToObject = JSON.parse(basic);

      questionaryToObject = JSON.parse(questionary);

      questionToObject = JSON.parse(questionaryToObject.question);
    } catch (e) {
      console.log(e);
    }

    questionaryToObject.question = questionToObject;

    const { contacts, socialAccount } = basicToObject;

    return {
      ...data,
      approvedName: ApproveItemStatus.getText(approved),
      basic: {
        ...basicToObject,
        contactValue:
          contacts && isArray(contacts) && contacts.length
            ? contacts
                .map((item) => {
                  return `${item.name} ${item.email}`;
                })
                .join(', ')
            : '--',
        socialAccountValue: `${socialAccount.type} ${socialAccount.account}`,
      },
      questionary: questionaryToObject,
    };
  }

  return {};
}
