import request from '@/http';

export function getDspReport(body) {
  const url = '/api/adx/tracking/rtb/report';

  return request.post(url, {
    data: body,
  });
}

export function exportDspReport(body) {
  const url = '/api/adx/tracking/rtb/report/export';

  return request.post(url, {
    data: body,
  });
}
