class SelectEnum {
  static DSP_LIST = 'DSP_LIST';

  static DSP_MANAGER = 'DSP_MANAGER';

  static DSP_TAG = 'DSP_TAG';

  static SSP_LIST = 'SSP_LIST';

  static SSP_MANAGER = 'SSP_MANAGER';

  static SSP_TAG = 'SSP_TAG';

  static COUNTRY = 'COUNTRY';

  static OS = 'OS';

  static OS_NAME = 'OS_NAME';

  static TIME_ZONE = 'TIME_ZONE';

  static DSP_EP = 'DSP_EP';

  static SSP_EP = 'SSP_EP';

  static DEVICE_TYPE = 'DEVICE_TYPE';

  static USER_GROUPS = 'USER_GROUPS';
}

export default SelectEnum;
