import { isArray, isNumber } from 'lodash';
import request from '@/http';

export function listRtbAdset(params) {
  const url = '/api/adx/dsp/offer/adset/tracking';

  return request.get(url, {
    params,
  });
}

export function createRtbAdset(adSet) {
  const url = '/api/adx/dsp/offer/adset';

  return request.post(url, {
    data: adSet,
    useLoading: true,
  });
}

export function updateRtbAdset(adSet) {
  const url = '/api/adx/dsp/offer/adset';

  return request.put(url, {
    data: adSet,
    useLoading: true,
  });
}

export function detailRtbAdset(adSetId) {
  const url = `/api/adx/dsp/offer/adset/${adSetId}`;

  return request.get(url);
}

export function copyRtbAdset(adSetId, isCopyCreative) {
  const url = '/api/adx/dsp/offer/adset/copy';

  return request.put(url, {
    params: {
      creative: isCopyCreative,
    },
    data: adSetId,
    useLoading: true,
  });
}

export function listAdsetCreative(params) {
  const { adSetId, ...rest } = params;

  const url = `/api/adx/dsp/offer/adset/${adSetId}/creative`;

  return request.get(url, {
    params: { ...rest },
  });
}

export function addAdsetCreative(body) {
  const url = '/api/adx/dsp/offer/adset/creatives';

  return request.post(url, {
    data: !isArray(body) ? [body] : body,
  });
}

export function updateAdsetCreative(body) {
  const url = '/api/adx/dsp/offer/adset/creatives';

  return request.put(url, {
    data: !isArray(body) ? [body] : body,
    useLoading: true,
  });
}

export function listOfferAdsetApprovedSspEp(params) {
  const url = '/api/adx/dsp/offer/adset/dsp/ssp/matched/units';

  return request.get(url, {
    params,
  });
}

export function updateOfferAdsetApprovedSspEp(adSetId, sspIds) {
  const url = `/api/adx/dsp/offer/adset/${adSetId}/creative/ssp-ep-checking-list`;

  return request.post(url, {
    data: !isArray(sspIds) ? [sspIds] : sspIds,
  });
}
