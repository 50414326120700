import { history } from 'umi';
import qs from 'qs';
import { isArray } from 'lodash';
import {
  detailExternalSspApprovedUnitBatch,
  approveExternalSspUnitBatch,
  detailInternalSspApprovedUnitBatch,
  approveInternalSspUnitBatch,
  listSspApprovedDsp,
  multipleApproveSspDsp,
} from '@/models/operation/ssp/api';
import DspUnitService from '@/pages/operation/dsp/service';
import SspUnitService from '@/pages/operation/ssp/service';
import { DspOperationStateEnum } from '@/models/operation/ssp/enum';

class SspMultipleApproveService {
  static defaultApprovedData = {
    internalDspOperationSwitch: null,
    internalDspOperations: [],
    externalDspOperations: [],
    dspOfferId: [],
    bidPriceRangeMin: null,
    bidPriceRangeMax: null,
    externalDspEp: [],
  };

  static number(inp) {
    if (isNaN(inp)) {
      return inp;
    }

    return +inp;
  }

  // 获取url operationIds 参数
  static getUrlOperationIds() {
    // @ts-ignore
    const urlParams = qs.parse(history.location.query) || {};

    // @ts-ignore
    return Object.values(qs.parse(urlParams.operationIds));
  }

  // 解析 operationIds 参数
  static parseOperationIds() {
    // @ts-ignore
    const operationIds = SspMultipleApproveService.getUrlOperationIds();

    if (isArray(operationIds)) {
      return operationIds.map((string) => {
        const [sspIntegrationId, countryId, os, adFormat] = string
          ?.split('_')
          ?.map((item) => SspMultipleApproveService.number(item));

        return {
          sspIntegrationId,
          countryId,
          os,
          adFormat,
        };
      });
    }

    return [];
  }

  static transformApprovedSsp(list) {
    if (isArray(list)) {
      const selectedList = SspMultipleApproveService.getApprovedSspUnits();

      return list.map((item) => {
        const operationId = SspUnitService.generatorOperationId(item);

        const selected = selectedList?.find((ssp) => ssp.operationId === operationId);

        const { approveDetail = SspMultipleApproveService.defaultApprovedData, ...rest } = item;

        return {
          ...rest,
          ...approveDetail,
          operationId,
          sspUnit: selected ? selected.sspUnit : '',
        };
      });
    }

    return [];
  }

  static async detailExternalSspApprovedUnitBatch(body) {
    const external = await detailExternalSspApprovedUnitBatch(body);

    return SspMultipleApproveService.transformApprovedSsp(external);
  }

  static async detailInternalSspApprovedUnitBatch(body) {
    const internal = await detailInternalSspApprovedUnitBatch(body);

    return SspMultipleApproveService.transformApprovedSsp(internal);
  }

  static approveExternalSspUnitBatch(body) {
    return approveExternalSspUnitBatch(body);
  }

  static approveInternalSspUnitBatch(body) {
    return approveInternalSspUnitBatch(body);
  }

  static async listSspApprovedDsp(body) {
    const approvedData = await listSspApprovedDsp(body);

    return SspMultipleApproveService.transformApprovedSsp(approvedData);
  }

  static multipleApproveSspDsp(body) {
    return multipleApproveSspDsp(body);
  }

  static getExternalFormNames(operationId) {
    return {
      externalDspOperations: `${operationId}|externalDspOperations`,
      externalDspEp: `${operationId}|externalDspEp`,

      bidPriceRangeMin: `${operationId}|bidPriceRangeMin`,
      bidPriceRangeMax: `${operationId}|bidPriceRangeMax`,
      restExternalApprovedDspUnit: `${operationId}|restExternalApprovedDspUnit`,
    };
  }

  static getInternalFormNames(operationId) {
    return {
      dspOfferId: `${operationId}|dspOfferId`,
      internalDspSspOfferAdSetBudgets: `${operationId}|internalDspSspOfferAdSetBudgets`,
      internalDspOperationSwitch: `${operationId}|internalDspOperationSwitch`,
      restInternalApprovedDspUnit: `${operationId}|restInternalApprovedDspUnit`,
    };
  }

  static getFormNames(operationId) {
    return {
      ...SspMultipleApproveService.getExternalFormNames(operationId),
      ...SspMultipleApproveService.getInternalFormNames(operationId),
    };
  }

  static decodeValues(values, internalDspEpList, dspOffer) {
    if (values) {
      const keys = Object.keys(values);

      const urlOperationIds = SspMultipleApproveService.getUrlOperationIds();

      const internal = [];

      const external = [];

      urlOperationIds?.forEach((urlKey) => {
        const sspUnit = SspUnitService.decodeOperationId(urlKey);

        const names = SspMultipleApproveService.getFormNames(urlKey);

        const item = {};

        keys.forEach((key) => {
          const [operationId, formKey] = key.split('|');

          const value = values[key];

          if (operationId === urlKey) {
            // 外部dsp
            if (key === names.externalDspOperations) {
              item[formKey] = DspUnitService.dspUnitSspListToBody(value);

              return;
            }

            if (key === names.internalDspSspOfferAdSetBudgets) {
              item[formKey] = SspUnitService.internalDspOperations2Body(
                value,
                internalDspEpList,
                dspOffer,
                sspUnit,
              );

              const { internalDspList, offerAdSetBudgets } =
                SspUnitService.internalDspOperations2Body(
                  value,
                  internalDspEpList,
                  dspOffer,
                  sspUnit,
                );

              item.internalDspOperations = internalDspList;

              item[formKey] = offerAdSetBudgets;

              return;
            }

            if (key === names.internalDspOperationSwitch) {
              item[formKey] = value ? DspOperationStateEnum.ON : DspOperationStateEnum.OFF;

              return;
            }

            if (
              [names.restInternalApprovedDspUnit, names.restInternalApprovedDspUnit].includes(key)
            ) {
              const {
                floorPriceRangeMin,
                floorPriceRangeMax,
                highWeights = [],
                whiteLists = [],
              } = value;

              item.floorPriceRangeMin = floorPriceRangeMin;
              item.floorPriceRangeMax = floorPriceRangeMax;
              item.highWeights = highWeights;
              item.whiteLists = whiteLists;

              return;
            }

            item[formKey] = value;
          }
        });

        const {
          bidPriceRangeMin,
          bidPriceRangeMax,
          externalDspOperations = [],

          highWeights,
          whiteLists,

          internalDspOperationSwitch,
          internalDspOperations = [],
          internalDspSspOfferAdSetBudgets = [],
        } = item;

        external.push({
          sspUnit,
          bidPriceRangeMin,
          bidPriceRangeMax,
          externalDspOperations,
          highWeights,
          whiteLists,
        });

        internal.push({
          sspUnit,
          internalDspOperationSwitch,
          highWeights,
          whiteLists,
          internalDspSspOfferAdSetBudgets,
          internalDspOperations,
        });
      });

      return {
        internal,
        external,
      };
    }

    return {
      internal: [],
      external: [],
    };
  }

  static getExternalFormValues(approvedList) {
    const format = {};

    if (isArray(approvedList)) {
      approvedList.forEach((item) => {
        const names = SspMultipleApproveService.getExternalFormNames(item.operationId);

        const values = SspUnitService.sspUnitApproveDetailToForm({ external: item });

        const formKeyMap = Object.keys(names);

        formKeyMap.forEach((key) => {
          const keyValue = values[key];

          const mapValue = names[key];

          format[mapValue] = keyValue;
        });

        format[names.restExternalApprovedDspUnit] = values.restExternal;
      });
    }

    return format;
  }

  static getInternalFormValues(approvedList) {
    const format = {};

    if (isArray(approvedList)) {
      approvedList.forEach((item) => {
        const names = SspMultipleApproveService.getInternalFormNames(item.operationId);

        const values = SspUnitService.sspUnitApproveDetailToForm({ internal: item });

        const formKeyMap = Object.keys(names);

        formKeyMap.forEach((key) => {
          const keyValue = values[key];

          const mapValue = names[key];

          format[mapValue] = keyValue;
        });

        format[names.restInternalApprovedDspUnit] = values.restInternal;
      });
    }

    return format;
  }

  static updateApprovedSspUnits(list) {
    if (list && list.length) {
      localStorage.setItem('operation_ssp_approved_list', JSON.stringify(list));
    }
  }

  static getApprovedSspUnits() {
    let ret = [];

    try {
      // @ts-ignore
      ret = JSON.parse(localStorage.getItem('operation_ssp_approved_list'));
    } catch (e) {}

    return ret;
  }
}

export default SspMultipleApproveService;
