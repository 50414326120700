import {
  uploadOperationLog,
  listDspOperationLog,
  listSspOperationLog,
  listNewOperationDspEpLog,
  listNewOperationSspEpLog,
  listDspMemo,
  listDspEpMemo,
  listSspMemo,
  listSspEpMemo,
} from './api';
import { get, isFunction, isEqual, isArray, isNumber, isObject, difference } from 'lodash';
import { OperationLogModule, UpdateAction } from '@/models/operation-log/enum';
import { optionInFormKey } from '@/components/BSelect/Select';

class OperationLogService {
  // 字段变动记录
  changeFormFields;

  // 当前表单select类型选中的option
  selectedOptionInForm;

  // 当前作用的模块
  module;

  // form instance
  form;

  constructor(module, form) {
    this.changeFormFields = [];

    this.module = module;

    this.form = form;
  }

  /**
   * @description:变动字段收集依赖form onFieldsChange事件,如果通过form set api更改的值的话 是不会纳入变动记录里面的
   * **/
  setFormItemFieldValue(name, value, plugData) {
    if (this.form && isFunction(this.form.setFieldValue)) {
      // 设置表单
      this.form.setFieldValue(name, value);

      const changed = {
        name,
        value,
        touched: true,
      };

      const changePlug = plugData || {};

      this.updateChangedFields(changed, changePlug);
    }
  }

  setSelectedOptionInForm(values) {
    if (values && values[optionInFormKey]) {
      this.selectedOptionInForm = values[optionInFormKey];
    }
  }

  // 更新变动字段
  updateChangedFields(changed, plug) {
    if (changed && changed.touched) {
      const findIndex = this.changeFormFields.findIndex((item) => isEqual(item.name, changed.name));

      const item = {
        ...changed,
        plug,
      };

      // 新增
      if (findIndex <= -1) {
        this.changeFormFields.push(item);

        return;
      }

      // 重复记录只保留最新一条
      this.changeFormFields.splice(findIndex, 1, item);
    }
  }

  getChangeFormFields() {
    return this.changeFormFields;
  }

  getModule() {
    return this.module;
  }

  isFieldChanged(name) {
    const format = (namePath) => {
      return isArray(namePath) ? namePath.join('_') : namePath;
    };

    const formatName = format(name);

    const changed = this.getChangeFormFields();

    const changedNames = isArray(changed) ? changed.map((item) => format(item.name)) : [];

    return changedNames.includes(formatName);
  }

  // 如果是大数据数组，找出差异
  getValueDiff(oldValue, value) {
    const action = value.length > oldValue.length;

    const diffValue = action ? difference(value, oldValue) : difference(oldValue, value);

    return {
      diffValue,
      diffAction: action ? 'plus' : 'minus',
    };
  }

  // 根据表单初始值和变动记录 找出前后变动记录
  getDiffFromNamePath(data, action) {
    const changed = [];

    const changedPaths = this.changeFormFields;

    if (data && changedPaths && changedPaths.length) {
      changedPaths.forEach((path) => {
        const { name, value, plug } = path;

        const oldValue = get(data, name);

        const mergeValue = this.transformChangePathValue(path);

        const mergeOldValue = this.transformChangePathValue({
          name,
          value: oldValue,
        });

        if (!isEqual(oldValue, value)) {
          const valueAction =
            isArray(oldValue) && isArray(value)
              ? this.getValueDiff(oldValue, value)
              : {
                  diffValue: value,
                  diffAction: 'plus',
                };

          changed.push({
            ...valueAction,

            plug,
            action: action || UpdateAction.Update,
            path: name,
            from: mergeOldValue,
            to: mergeValue,
          });
        }
      });
    }

    return changed;
  }

  // 将id转换成明文
  transformChangePathValue(path) {
    const { name, value } = path;

    const selectedOptionInForm = this.selectedOptionInForm;

    if (isObject(selectedOptionInForm)) {
      const options = get(selectedOptionInForm, name);

      if (isArray(options) && options.length) {
        const getNameByOptionId = (id) => {
          const find = options.find((item) => (item.id || item.value) === id);

          return find ? find.name || find.abbr || find.label : id;
        };

        if (isArray(value)) {
          return value.map((id) => getNameByOptionId(id));
        }

        if (isNumber(value)) {
          return getNameByOptionId(value);
        }
      }

      return value;
    }

    return value;
  }

  // 是否是Item.List name 批量
  static isListNameField(name) {
    if (isArray(name) && name.length) {
      if (name.length === 1) {
        return false;
      }

      const [first, ...restFields] = name;

      return restFields.some((field) => isNumber(field));
    }

    return false;
  }

  // 整合上传日志接口
  uploadOperationLog(body) {
    return uploadOperationLog({ ...body, page: this.module });
  }

  // 通用上传
  uploadLog(memos) {
    return uploadOperationLog(memos);
  }

  // 查询上传日志接口
  listOperationLog(params) {
    if (this.module === OperationLogModule.DspOperation) {
      return listDspOperationLog(params);
    }

    if (this.module === OperationLogModule.SspOperation) {
      return listSspOperationLog(params);
    }

    if (this.module === OperationLogModule.NewDspEpOperation) {
      return listNewOperationDspEpLog(params);
    }

    if (this.module === OperationLogModule.NewSspEpOperation) {
      return listNewOperationSspEpLog(params);
    }

    if (this.module === OperationLogModule.DSP) {
      return listDspMemo(params);
    }

    if (this.module === OperationLogModule.DSP_EP) {
      return listDspEpMemo(params);
    }

    if (this.module === OperationLogModule.SSP) {
      return listSspMemo(params);
    }

    if (this.module === OperationLogModule.SSP_EP) {
      return listSspEpMemo(params);
    }

    return Promise.resolve([]);
  }
}

export default OperationLogService;
