import type { Effect, Reducer } from 'umi';
import type { ListResponse } from '@/models/interface';
import type { NDsp } from '@/models/dsp/interface';
import { listDsp, detailDsp, listDspIntegration, listDspIdentity } from './api';
import { defaultListData } from '@/models/common.js';
import {
  transformDspDetail,
  transformDspList,
  transformDspIntegration,
  sortByDefault,
} from './util';

interface DspModelState {
  dspData: ListResponse<NDsp.DspItem>;
  dspDetail: NDsp.DspDetail;
  dspIntegrationData: ListResponse<NDsp.DspIntegrationItem>;
  dspIdentityList: NDsp.DspIdentityItem[];
}

interface DspModel {
  namespace: string;
  state: DspModelState;
  effects: {
    listDsp: Effect;
    detailDsp: Effect;
    listDspIntegration: Effect;
    listDspIdentity: Effect;
  };
  reducers: {
    listDspSuccess: Reducer;
    detailDspSuccess: Reducer;
    listDspIntegrationSuccess: Reducer;
    listDspIdentitySuccess: Reducer;
    clearDspIdentity: Reducer;
  };
}

const dspModel: DspModel = {
  namespace: 'dspState',
  state: {
    dspData: defaultListData,
    dspDetail: {} as NDsp.DspDetail,
    dspIntegrationData: defaultListData,
    dspIdentityList: [],
  },
  effects: {
    *listDspIdentity({ payload }, { call, put }) {
      const data = yield call(listDspIdentity, payload);

      yield put({
        type: 'listDspIdentitySuccess',
        payload: data,
      });

      return data;
    },

    *listDsp({ payload }, { call, put }) {
      const data = yield call(listDsp, payload);

      yield put({
        type: 'listDspSuccess',
        payload: data,
      });
    },

    *detailDsp({ payload }, { call, put }) {
      const data = yield call(detailDsp, payload);

      yield put({
        type: 'detailDspSuccess',
        payload: data,
      });
    },

    *listDspIntegration({ payload }, { call, put }) {
      const data = yield call(listDspIntegration, payload);

      yield put({
        type: 'listDspIntegrationSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listDspSuccess(state, { payload }) {
      return {
        ...state,
        dspData: {
          list: transformDspList(payload.list),
          paginator: payload.paginator,
        },
      };
    },
    detailDspSuccess(state, { payload }) {
      return {
        ...state,
        dspDetail: transformDspDetail(payload),
      };
    },

    listDspIntegrationSuccess(state, { payload }) {
      const { list, paginator } = payload;

      return {
        ...state,
        dspIntegrationData: {
          list: transformDspIntegration(list),
          paginator,
        },
      };
    },

    listDspIdentitySuccess(state, { payload }) {
      return {
        ...state,
        dspIdentityList: sortByDefault(payload),
      };
    },

    clearDspIdentity(state) {
      return {
        ...state,
        dspIdentityList: [],
      };
    },
  },
};

export default dspModel;
