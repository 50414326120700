import { isArray, isNumber } from 'lodash';
import Utils from '@/utils';
import NumberFormatService from '@/services/numberformat.service';

export function transformDspBillingData(data) {
  if (!Utils.falsely(data)) {
    const { list, summary, ...rest } = data;

    // 当前页汇总
    const pageSummary = {
      systemRevenue: 0,
      payout: 0,
      systemCost: 0,

      confirmRevenue: 0,
      profit: 0,
      deduction: 0,
      collection: 0,
    };

    const defaultTotalSummary = {
      totalSystemRevenue: 0,
      totalPayout: 0,
      totalSystemCost: 0,

      totalConfirmRevenue: 0,
      totalProfit: 0,
      totalDeduction: 0,
      totalCollection: 0,
    };

    const summaryKeys = Object.keys(pageSummary);

    const ret = isArray(list)
      ? list.map((billing) => {
          const {
            dspName,
            dspId,

            deductionRate,

            profit,
            invoiceRevenue,
          } = billing;

          // page summary
          summaryKeys.forEach((key) => {
            // 调整数字的精度(不包含百分比)
            const value = isNumber(billing[key]) ? billing[key] : 0;

            const format = Number(value > 1 ? value.toFixed(2) : value);

            billing[key] = format;

            pageSummary[key] += isNumber(format) ? format : 0;
          });

          return {
            ...billing,
            invoiceRevenueValue: invoiceRevenue,
            dsp: `${dspName}#${dspId}`,
            deductionRate: NumberFormatService.toFixed(deductionRate * 100),
            invoiceRevenue: NumberFormatService.formatNumber(invoiceRevenue, '0,0.[00]'),
            profit: NumberFormatService.toFixed(profit),
          };
        })
      : [];

    return {
      ...rest,
      list: ret,
      summary: {
        ...pageSummary,
        ...(summary ? summary : defaultTotalSummary),
      },
    };
  }

  return {
    list: [],
    paginator: {},
    summary: {},
  };
}
