import { listRtbAdset } from './api';
import { defaultListData } from '@/models/common.js';
import { transformRtbAdsetData } from '@/models/rtb/ad-set/util';

const adSetModel = {
  namespace: 'rtbAdsetState',
  state: {
    adsetData: defaultListData,
    limitAdSetGeoOptionIds: [],
  },
  effects: {
    *listRtbAdset({ payload }, { call, put }) {
      const data = yield call(listRtbAdset, payload);

      yield put({
        type: 'listRtbAdsetSuccess',
        payload: data,
      });

      return data;
    },
  },
  reducers: {
    listRtbAdsetSuccess(state, { payload }) {
      return {
        ...state,
        adsetData: transformRtbAdsetData(payload),
      };
    },

    updateLimitAdSetGeoOptionIds(state, { payload }) {
      return {
        ...state,
        limitAdSetGeoOptionIds: payload,
      };
    },
  },
};

export default adSetModel;
