export class SortDirection {
  static Descend = 'descend';

  static Ascend = 'ascend';
}

class TableHelper {
  static sortDirections = [SortDirection.Descend, SortDirection.Ascend];

  // antd key 转换成业务key
  static sortDirectionMap = {
    [SortDirection.Descend]: 'desc',
    [SortDirection.Ascend]: 'asc',
  };

  static getSortKey(key) {
    const value = TableHelper.sortDirectionMap[key];

    return value ? value : key;
  }

  // 将antd pagination和sorter转换成业务字段
  static transformTableParams(pagination, sorter, fieldMap) {
    const ret = {};

    if (pagination) {
      const index = pagination.current;

      const size = pagination.pageSize;

      // 适用body传参
      ret.page = {
        index,
        size,
      };

      ret.index = index;

      ret.size = size;
    }

    if (sorter) {
      const { field, order } = sorter;

      const sortOrder = TableHelper.getSortKey(order);

      const mergeField = fieldMap && fieldMap[field] ? fieldMap[field] : field;

      ret.sortList = sortOrder ? [`${mergeField} ${sortOrder}`] : [];
    }

    return ret;
  }
}

export default TableHelper;
