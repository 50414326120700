import request from '@/http';

// 获取系统用户列表
export function listSystemUser(params) {
  const url = '/api/adx/user';

  return request.get(url, {
    params,
  });
}

// 添加系统用户
export function createSystemUser(user) {
  const url = '/api/adx/user';

  return request.post(url, {
    data: user,
    useLoading: true,
  });
}

// 更新系统用户
export function updateSystemUser(user) {
  const url = '/api/adx/user';

  return request.put(url, {
    data: user,
    useLoading: true,
  });
}

// 给用户分配权限
export function authorizeSystemUserPermission(body) {
  const url = '/api/adx/user/authorize';

  return request.put(url, {
    data: body,
    useLoading: true,
  });
}

// 查询用户已经分配的权限
export function getSystemUserAuthorizePermission(userId) {
  const url = `/api/adx/user/${userId}/permission`;

  return request.get(url);
}

// operation log summary
export function getUserOperationLogSummary(params) {
  const { userId, ...param } = params;

  const url = `/api/adx/user/${userId}/log/summary`;

  return request.get(url, {
    params: param,
  });
}

// operation log list
export function listUserOperationLog(params) {
  const { userId, ...param } = params;

  const url = `/api/adx/user/${userId}/log`;

  return request.get(url, {
    params: param,
  });
}
