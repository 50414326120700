import { isNumber, invert } from 'lodash';

export class Identity {
  static DSP = 'DSP';

  static SSP = 'SSP';

  static Both = 'Both';
}

export class ApproveItemStatus {
  static Rejected = -1;

  static 'Need Approve' = 0;

  static Approved = 1;

  static getText(value) {
    const nameMap = invert(ApproveItemStatus);

    if (!isNumber(value)) {
      return 'unknown';
    }

    return nameMap[value];
  }
}

export class ApproveStatus {
  static Rejected = 0;

  static Approved = 1;
}
