import Utils from '@/utils';

export class CollectionStateEnum {
  static ENABLE = 1;

  static DISABLE = 0;

  static getText(value) {
    if (Utils.falsely(value)) return null;

    return value === CollectionStateEnum.DISABLE ? 'Disabled' : 'Enabled';
  }
}
