import request from '@/http';

export function registerDsp(body) {
  const url = '/api/adx/dsp/partner/registry';

  return request.post(url, {
    useLoading: true,
    data: body,
  });
}

export function finishDspRegistration(id) {
  const url = `/api/adx/dsp/partner/registry/${id}/finish`;

  return request.put(url);
}

export function listDspRegistration(params) {
  const url = '/api/adx/dsp/registry';

  return request.get(url, {
    params,
  });
}

export function markDspRegistration({ id, remark }) {
  const url = `/api/adx/dsp/registry/${id}/remark`;

  return request.put(url, {
    data: remark,
  });
}

export function dspRegistrationDetail(id) {
  const url = `/api/adx/dsp/registry/${id}`;

  return request.get(url);
}

export function approveDspRegistration(data) {
  const { id, status, memo, ...rest } = data;

  const url = `/api/adx/dsp/registry/${id}/approve/${status}`;

  return request.put(url, {
    data: {
      ...rest,
      memo: memo || 'User manual approved',
    },
  });
}

// ssp
export function registerSsp(body) {
  const url = '/api/adx/ssp/partner/registry';

  return request.post(url, {
    useLoading: true,
    data: body,
  });
}

export function finishSspRegistration(id) {
  const url = `/api/adx/ssp/partner/registry/${id}/finish`;

  return request.put(url);
}

export function listSspRegistration(params) {
  const url = '/api/adx/ssp/registry';

  return request.get(url, {
    params,
  });
}

export function markSspRegistration({ id, remark }) {
  const url = `/api/adx/ssp/registry/${id}/remark`;

  return request.put(url, {
    data: remark,
  });
}

export function sspRegistrationDetail(id) {
  const url = `/api/adx/ssp/registry/${id}`;

  return request.get(url);
}

export function approveSspRegistration(data) {
  const { id, status, memo, ...rest } = data;

  const url = `/api/adx/ssp/registry/${id}/approve/${status}`;

  return request.put(url, {
    data: {
      ...rest,
      memo: memo || 'User manual approved',
    },
  });
}
