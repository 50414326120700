import { isArray } from 'lodash';
import request from '@/http';
import { TreeDataTypeEnum } from '@/models/rtb/offer/offer.enum';

export function listRtbOffer(params) {
  const url = '/api/adx/dsp/offer/tracking';

  return request.get(url, {
    params,
  });
}

export function createRtbOffer(offer) {
  const url = '/api/adx/dsp/offer';

  return request.post(url, {
    data: offer,
    useLoading: true,
  });
}

export function updateRtbOffer(offer) {
  const url = '/api/adx/dsp/offer';

  return request.put(url, {
    data: offer,
  });
}

export function updateRtbOfferGeo(offerId, geos) {
  const url = `/api/adx/dsp/offer/${offerId}/geo`;

  return request.put(url, {
    data: geos,
  });
}

export function updateRtbOfferDevices(offerId, devices) {
  const url = `/api/adx/dsp/offer/${offerId}/device`;

  return request.put(url, {
    data: devices,
  });
}

export function copyRtbOffer(offerId, deeply) {
  const url = '/api/adx/dsp/offer/copy';

  return request.put(url, {
    data: offerId,
    params: {
      deeply,
    },
  });
}

export function detailRtbOffer(offerId) {
  const url = `/api/adx/dsp/offer/${offerId}`;

  return request.get(url);
}

export function listOfferWithAdset(params) {
  const url = '/api/adx/dsp/offer/all';

  return request.get(url, {
    params,
  });
}

/** offer event **/
// list evt
export function listOfferEvent(params) {
  const { offerId, ...rest } = params;

  const url = `/api/adx/dsp/offer/${offerId}/event`;

  return request.get(url, {
    params: { ...rest },
  });
}

// create
export function createOfferEvent(evt) {
  const url = '/api/adx/dsp/offer/events';

  return request.post(url, {
    data: isArray(evt) ? evt : [evt],
    useLoading: true,
  });
}

// update
export function updateOfferEvent(evt) {
  const url = '/api/adx/dsp/offer/event';

  return request.put(url, {
    data: evt,
    useLoading: true,
  });
}

// 根据bundle id 获取app 详情
export async function getAppInfoByBundleId(bundleId) {
  const url = `/api/adx/common/app/${bundleId}/info`;

  const response = await request.get(url, {
    getResponse: true,
  });

  return response?.data;
}
