import { DspStatus, MatchTypeEnum } from '@/models/dsp/enum';
import { PlatformEnum, PriceModel, SspIntegrationStatus } from '@/models/ssp/enum';

class SspConstant {
  static platformList = [
    {
      name: PlatformEnum.ADX,
      id: PlatformEnum.ADX,
    },
    {
      name: PlatformEnum.DSP,
      id: PlatformEnum.DSP,
    },
  ];

  static formStatusList = [
    {
      name: 'Active',
      id: DspStatus.Active,
    },
    {
      name: 'Paused',
      id: DspStatus.Paused,
    },
  ];

  static statusList = [
    ...SspConstant.formStatusList,
    {
      name: 'Deleted',
      id: DspStatus.Deleted,
    },
  ];

  static integrationStatusList = [
    {
      name: 'Active',
      id: SspIntegrationStatus.Active,
    },
    {
      name: 'Paused',
      id: SspIntegrationStatus.Paused,
    },
  ];

  static priceModelList = [
    {
      name: PriceModel.ADM,
      id: PriceModel.ADM,
    },
    {
      name: PriceModel.BURL,
      id: PriceModel.BURL,
    },
    {
      name: PriceModel.NURL,
      id: PriceModel.NURL,
    },
  ];

  static trafficTarget = {
    matchType: MatchTypeEnum.Include,
    geos: [],
    osTarget: [],
    osList: [],
  };

  static allCountrySelectedOptionId = -1;

  static UnlimitedOsSelectedOptionId = -1;

  static allOsItem = {
    combinationName: 'All of them',
    id: -1,
  };
}

export default SspConstant;
