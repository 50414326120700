import type { NDsp } from '@/models/dsp/interface';
import { isArray } from 'lodash';
import { getTrafficTargetDeviceOsText } from '@/models/ssp/util';
import TimeService from '@/services/time.service';
import AuthService from '@/services/auth.service';
import Utils from '@/utils';

export function transformDspList(list: NDsp.DspItem[]) {
  if (isArray(list) && list.length) {
    return list.map((dsp) => {
      const { createTime, updateTime, managers, tags, countrys, memos } = dsp;

      const formatMemos =
        isArray(memos) && memos.length
          ? memos.map((memo) => {
              return {
                ...memo,
                createTimeText: TimeService.formatTime(memo.createTime),
              };
            })
          : [];

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const managerAuthed = AuthService.authOperationData(
        Utils.getValuesWithProperty(managers, 'id'),
      );

      return {
        ...dsp,
        createTimeText,
        updateTimeText,
        memos: formatMemos,
        userAuthed: managerAuthed,
        managerText: Utils.getValuesWithProperty(managers, 'name').join(', ') || '--',
        countryText: Utils.getValuesWithProperty(countrys, 'abbr').join(', ') || '--',
        tagText: Utils.getValuesWithProperty(tags, 'value').join(', ') || '--',
      };
    });
  }

  return [];
}

export function transformDspDetail(dspDetail: NDsp.DspDetail) {
  if (!Utils.falsely(dspDetail)) {
    const { createTime, updateTime } = dspDetail;

    const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
      createTime,
      updateTime,
    ]);

    return {
      ...dspDetail,
      createTimeText,
      updateTimeText,
      userAuthed: AuthService.authorizedDsp(dspDetail.id),
    };
  }

  return {};
}

export function transformDspIntegration(list: NDsp.DspIntegrationItem[]) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { gzip, createTime, updateTime, trafficTargets, memos } = item;

      const trafficTargetTexts = getTrafficTargetDeviceOsText(trafficTargets);

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const formatMemos =
        isArray(memos) && memos.length
          ? memos.map((memo) => {
              return {
                ...memo,
                createTimeText: TimeService.formatTime(memo.createTime),
              };
            })
          : [];

      return {
        ...item,
        createTimeText,
        updateTimeText,
        memos: formatMemos,
        trafficTargetTexts: [...new Set(trafficTargetTexts)],
        gzipText: gzip ? 'ON' : 'OFF',
      };
    });
  }

  return [];
}

export function sortByDefault(list: any[]) {
  if (!isArray(list)) {
    return [];
  }

  if (list.length === 1) {
    return list;
  }

  return list.sort((item) => (item.isDefault ? -1 : 0));
}
