import request from '@/http';

export function listSspBilling(params) {
  const url = '/api/adx/ssp/billing/monthly';

  return request.get(url, {
    params,
  });
}

export function updateSspBilling(body) {
  const url = '/api/adx/ssp/billing/monthly';

  return request.put(url, {
    data: body,
  });
}

export function listSspBillingRecord(params) {
  const url = '/api/adx/ssp/billing/operation/records';

  return request.get(url, {
    params,
  });
}

export function updateSspBillingNotes(billingId, note) {
  const url = `/api/adx/ssp/billing/monthly/${billingId}/notes`;

  return request.put(url, {
    data: note,
  });
}

export function exportSspBilling(params) {
  const url = '/api/adx/ssp/billing/monthly/export';

  return request.get(url, {
    params,
  });
}

// send billing record
export function listSspBillingSend(params) {
  const url = `/api/adx/ssp/billing/send`;

  return request.get(url, {
    params,
  });
}
