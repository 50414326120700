import { isArray, isNumber, head } from 'lodash';
import type { NDspOperation } from './interface';
import TimeService from '@/services/time.service';
import DspUnitService from '@/pages/operation/dsp/service';
import AuthService from '@/services/auth.service';

export function transformDspUnitList(list: NDspOperation.DspUnitItem[]) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { minBidFloor, maxBidFloor, createTime, updateTime, dspUnit, deviceType, managerIds } =
        item;

      const operationId = DspUnitService.generatorOperationId(item);

      const deviceString = `-${deviceType}`;

      const mergeDspUnit = dspUnit.includes(deviceString)
        ? `${head(dspUnit.split(deviceString))}(${deviceType})`
        : dspUnit;

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const managerAuthed = AuthService.authOperationData(managerIds);

      const dataAuthed = AuthService.authorizedDsp(item.dspId);

      return {
        ...item,
        operationId,
        createTimeText,
        updateTimeText,
        dspUnitText: mergeDspUnit,
        userAuthed: managerAuthed || dataAuthed,
        minBidFloor: isNumber(minBidFloor) && minBidFloor >= 0 ? minBidFloor : 0,
        maxBidFloor: isNumber(maxBidFloor) ? maxBidFloor : 0,
      };
    });
  }

  return [];
}
