export class OfferStateEnum {
  static Active = 'Active';

  static Paused = 'Paused';

  static Pending = 'Pending';

  static Deleted = 'Deleted';
}

export class CostInterval {
  static Total = 'Total';

  static Daily = 'Daily';

  static None = 'None';
}

export class CostType {
  static Auto = 'Auto';

  static Average = 'Average';

  static BySsp = 'BySsp';
}

export class TreeDataTypeEnum {
  static Offer = 'Offer';

  static AdSet = 'AdSet';
}
