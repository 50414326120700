import request from '@/http';
import type { NCommon } from '@/models/common/interface';

export function listDevice(params?: NCommon.ListOsParams) {
  const url = '/api/common/device/os';

  return request.get(url, {
    params,
  });
}

// mobile os
export function listMobileDevice() {
  return listDevice({
    terminal: ['Mobile', 'Mobile-Web', 'TV'],
  });
}

// all os
export function listTerminalOs() {
  return listDevice();
}

export function uploadFile(file: File) {
  const formData = new FormData();

  const url = '/api/adx/common/oss/upload';

  formData.append('file', file);

  return request.post(url, {
    body: formData,
    params: {
      module: 'ADX',
      material: true,
    },
  });
}

export function listCountryRegion(params: NCommon.IListRegionParams) {
  const url = '/api/common/location/region';

  return request.get(url, {
    params,
  });
}

export function listTrackingParam() {
  const url = '/api/adx/common/tracking/param';

  return request.get(url);
}

// 获取全球的国家
export function getCountryList() {
  const url = '/api/partner/common/location/country';
  return request.get(url);
}
