export enum DspStatus {
  Active = 'Active',

  Paused = 'Paused',

  Deleted = 'Deleted',
}

export enum DspIntegrationStatus {
  Active = 'Active',

  Paused = 'Paused',
}

export enum AdFormatEnum {
  banner = 'banner',

  native = 'native',

  video = 'video',
}

export enum DataCenterEnum {
  SG = 'SG',

  DE = 'DE',

  US_EAST = 'US-EAST',

  CN = 'CN',
}

export enum PriceModelEnum {
  BURL = 'BURL',

  NURL = 'NURL',

  ADM = 'ADM',
}

export enum MatchTypeEnum {
  Include = 1,

  Exclude = 0,
}

export enum DspIdentityStatusEnum {
  Enabled = 1,

  Disabled = 0,
}

export enum DspEpPxTagStatusEnum {
  ON = 1,

  OFF = 0,
}

export enum DspEpCopyStatusEnum {
  ON = 1,

  OFF = 0,
}

export enum DspEpViewStatusEnum {
  ON = 1,

  OFF = 0,
}

export enum PxTagRadioEnum {
  Custom = 1,

  SystemDefault = -1,
}

export enum EpBillTypeEnum {
  Custom = 1,

  SystemDefault = -1,
}
