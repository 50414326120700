import { isArray, isNumber, head } from 'lodash';
import TimeService from '@/services/time.service';
import SspUnitService from '@/pages/operation/ssp/service';
import AuthService from '@/services/auth.service';

export function transformSspUnitList(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { minBidFloor, maxBidFloor, createTime, updateTime, sspUnit, deviceType, managerIds } =
        item;

      const operationId = SspUnitService.generatorOperationId(item);

      const deviceString = `-${deviceType}`;

      const mergeSspUnit = sspUnit.includes(deviceString)
        ? `${head(sspUnit.split(deviceString))}(${deviceType})`
        : sspUnit;

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const managerAuthed = AuthService.authOperationData(managerIds);

      const dataAuthed = AuthService.authorizedSsp(item.sspId);

      return {
        ...item,
        operationId,
        createTimeText,
        updateTimeText,
        userAuthed: managerAuthed || dataAuthed,
        sspUnitText: mergeSspUnit,
        minBidFloor: isNumber(minBidFloor) && minBidFloor >= 0 ? minBidFloor : 0,
        maxBidFloor: isNumber(maxBidFloor) ? maxBidFloor : 0,
      };
    });
  }

  return [];
}

export function transformOfferAdset(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { offerName, offerId, adSetName, adSetId, adFormat } = item;

      return {
        ...item,
        name: `${offerName}#${offerId}-(${adSetName}#${adSetId}-${adFormat})`,
        id: SspUnitService.generateOfferAdsetId(item),
        matchedId: SspUnitService.generateMatchedInternalDspKey(item),
      };
    });
  }

  return [];
}

export function transformInternalDspEp(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      return {
        ...item,
        matchedId: SspUnitService.generateMatchedInternalDspKey(item),
      };
    });
  }

  return [];
}

export function transformDspEpItems(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      return {
        ...item,
        id: item.dspIntegrationId,
        name: item.dspUnit,
      };
    });
  }

  return [];
}
