import Utils from '@/utils';
import { isNumber } from 'lodash';
import NumberFormatService from '@/services/numberformat.service';

export function transformRtbCreativeData(data) {
  if (!Utils.falsely(data)) {
    const { list, summary, ...rest } = data;

    // 当前页汇总
    const pageSummary = {
      billedEventCounts: 0,
      impressions: 0,
      clicks: 0,

      installs: 0,
      revenue: 0,
      payout: 0,
      profit: 0,
    };

    const defaultTotalSummary = {
      billedEventCounts: 0,
      impressions: 0,
      clicks: 0,

      installs: 0,
      revenue: 0,
      payout: 0,
      profit: 0,
    };

    const summaryKeys = Object.keys(pageSummary);

    const creativeList = list?.map((creative) => {
      const { offerId, adSetId, creativeId, wpixel, hpixel, size } = creative;

      // page summary
      summaryKeys.forEach((key) => {
        // 调整数字的精度(不包含百分比)
        const value = isNumber(creative[key]) ? creative[key] : 0;

        const format = NumberFormatService.toFixed(value, '0.0000[0]');

        creative[key] = format;

        pageSummary[key] += isNumber(format) ? format : 0;
      });

      return {
        ...creative,
        fileSize: NumberFormatService.formatFileSize(size),
        pixelSize: isNumber(wpixel) && isNumber(hpixel) ? `${wpixel}x${hpixel}` : '--',
        uniqueId: `${offerId}_${adSetId}_${creativeId}`,
      };
    });

    return {
      ...rest,
      list: creativeList,
      summary: {
        pageSummary,
        totalSummary: summary ? summary : defaultTotalSummary,
      },
    };
  }

  return {
    list: [],
    paginator: {},
    summary: {},
  };
}
