/**
 * @description: 基于antd select 组件封装自定义需求组件
 * 1.默认带搜索、清除
 * 2.tag/multiple模式下面可以设置tag最大数量
 * 3.阻止回车事件删除已选中的项目 tagMaxNumber
 * 4.simple 无边框
 * 5.useAllOption 启用带all选择框，如果为true必须配置 allOptionId
 * * */

import React from 'react';
import classnames from 'classnames';
import { Select } from 'antd';
import { isArray, isFunction, split, isNaN, head } from 'lodash';
import Utils from '@/utils';

import './index.less';

class FilterSelect extends React.Component {
  mode = {
    tags: 'tags',
    multiple: 'multiple',
  };

  // 剪切板分隔符
  tokenSeparators = [',', '，', ' ', '\n', '\r'];

  searchResult = [];

  // 当时mode 为tags/multiple并且配置了最大tag数量 验证
  verifyMaxTag = (value) => {
    const { tagMaxNumber, useAllOption = false, allOptionId = null, value: propValue } = this.props;

    // 如果配置all 并且当前选中的里面存在all选项则直接返回true 因为选择all 肯定就只有一个选项
    if (useAllOption && !Utils.falsely(allOptionId) && this.hasAllOption(value)) {
      return true;
    }

    // 检验长度
    return (
      !propValue ||
      (isArray(propValue) && propValue.length < tagMaxNumber) ||
      value.length < tagMaxNumber
    );
  };

  // 判断value 里面是否包含all选项
  hasAllOption = (value) => {
    const { allOptionId } = this.props;

    return isArray(value) ? value.includes(allOptionId) : value === allOptionId;
  };

  // Select搜索事件
  filterOption = (input, option) => {
    // option 原始数据
    const optionDataItem = option['data-item'];

    // 搜索时被固定的id
    const { keepOptionInSearch, filterOptionInputChange, searchIncludeKeys = [] } = this.props;

    const includeName =
      optionDataItem && isArray(searchIncludeKeys) && searchIncludeKeys.length
        ? searchIncludeKeys.reduce((curr, key) => {
            const itemName = optionDataItem[key];

            // eslint-disable-next-line no-param-reassign
            curr += itemName;

            return curr;
          }, '')
        : '';

    const name = String(option.children || option.name)?.toLowerCase();

    // 最终检索对象
    const mergeName = `${name}${includeName}`.toLowerCase();

    if (isFunction(filterOptionInputChange)) {
      filterOptionInputChange(input);
    }

    const isKeep = isArray(keepOptionInSearch) && keepOptionInSearch.includes(option?.value);

    const flag = mergeName?.includes(input.toLowerCase());

    return flag || isKeep;
  };

  getFieldNames = () => {
    const { fieldNames, defaultFieldNames, options } = this.props;

    if (options) {
      return fieldNames
        ? fieldNames
        : defaultFieldNames
        ? {
            label: 'label',
            value: 'value',
          }
        : { label: 'name', value: 'id' };
    }

    return null;
  };

  // key down 事件
  onInputKeyDown = (evt) => {
    const { value: propValue } = this.props;

    const { keyCode } = evt;

    const { value } = evt.target;

    // 回车不删除当前选中项
    if (keyCode === 13) {
      if (value === '' || (propValue && propValue.includes(value))) {
        evt.stopPropagation();
      }
    }
  };

  /**
   * @description:通过paste事件获取到剪切板里面的数据，从而进行分词
   * **/
  onSelectPaste = async (evt) => {
    const { onChange, value, options, list, mode } = this.props;

    // 获取到剪切板里面的数据
    const content = navigator.clipboard
      ? await navigator.clipboard.readText()
      : evt.clipboardData.getData('text/plain');

    // 当前select下拉列表数据
    const mergeOptions = options || list;

    if (content && mergeOptions && mergeOptions.length) {
      const token = '|';

      // 把string里面所有的分隔符转换成统一的
      const string = this.tokenSeparators.reduce((curr, string) => {
        return curr.replaceAll(string, token);
      }, content);

      // 分割出 单词
      const inputFields = split(string, token);

      const { inputIds, inputNames } = inputFields.reduce(
        (curr, string) => {
          if (isNaN(+string)) {
            curr.inputNames.push(string);
          } else {
            curr.inputIds.push(+string);
          }

          return curr;
        },
        {
          inputIds: [],
          inputNames: [],
        },
      );

      // 根据name筛选出id
      const filterIdsByName = mergeOptions
        .filter((item) => {
          return inputNames.some((name) => this.getItemName(item).includes(name));
        })
        .map((item) => this.getItemValue(item));

      // 过滤掉剪切板里面非法的id
      const mergeInputIds = inputIds.filter((inputId) =>
        mergeOptions.map((item) => this.getItemValue(item)).includes(inputId),
      );

      const copyInputIds = [...mergeInputIds, ...filterIdsByName];

      const isMultipleMode = this.getIsMultipleMode();

      // 合并value
      let mergeValues;

      if (isMultipleMode) {
        mergeValues = isArray(value)
          ? Array.from(new Set(value.concat(copyInputIds)))
          : copyInputIds;
      } else {
        mergeValues = copyInputIds.length ? head(copyInputIds) : null;
      }

      // 回填
      if (isFunction(onChange)) {
        onChange(mergeValues);
      }
    }
  };

  getItemValue = (item) => {
    const mergeFieldNames = this.getFieldNames();

    const mergeIdField =
      mergeFieldNames && item.hasOwnProperty(mergeFieldNames.value) ? mergeFieldNames.value : 'id';

    return item[mergeIdField];
  };

  getItemName = (item) => {
    const mergeFieldNames = this.getFieldNames();

    const mergeNameField =
      mergeFieldNames && item.hasOwnProperty(mergeFieldNames.label)
        ? mergeFieldNames.label
        : 'name';

    return item[mergeNameField];
  };

  getIsMultipleMode = () => {
    const { mode } = this.props;

    return mode && [this.mode.tags, this.mode.multiple].includes(mode);
  };

  onSelectChange = (value, option) => {
    // props
    const {
      mode,
      onChange,
      tagMaxNumber,
      useAllOption = false,
      allOptionId = null,
      value: preValue,
    } = this.props;

    // 最终onChange事件生效 value
    let finalValue = value;

    // 此数组中的数据都为true时才 触发onChange时间
    const verifyMiddlewareResults = [];

    // tag/multiple 多选模式下面
    if (this.getIsMultipleMode()) {
      // 设置了最大tag数量
      if (tagMaxNumber) {
        verifyMiddlewareResults.push(this.verifyMaxTag(value));
      }

      // 启用了all选项 需要对输出数据进行调整
      if (useAllOption && !Utils.falsely(allOptionId)) {
        if (preValue && preValue.length === 1 && preValue[0] === allOptionId) {
          finalValue = value.filter((id) => id !== allOptionId);
        } else {
          finalValue = value && value.includes(allOptionId) ? [allOptionId] : value;
        }
      }
    }

    // 所有的验证都通过
    if (isFunction(onChange) && verifyMiddlewareResults.every(Boolean)) {
      onChange(finalValue, option);
    }
  };

  render() {
    const {
      // 逻辑中用到的
      tagMaxNumber,
      onChange,
      useAllOption,
      allOptionId,
      searchIncludeKeys = [],

      // 配置
      noDropdown = false,
      simple = false,
      className,
      allowClear = true,
      loading,

      // ignore
      keepOptionInSearch,
      filterOptionInputChange,

      // 直接放到input上面的
      ...others
    } = this.props;

    const showArrow = loading || !noDropdown;

    const mergeFieldNames = this.getFieldNames();

    return (
      <Select
        {...others}
        showSearch
        loading={loading}
        fieldNames={mergeFieldNames}
        showArrow={showArrow}
        allowClear={allowClear}
        filterOption={this.filterOption}
        tokenSeparators={this.tokenSeparators}
        onInputKeyDown={this.onInputKeyDown}
        onChange={this.onSelectChange}
        onPaste={this.onSelectPaste}
        dropdownStyle={noDropdown ? { display: 'none' } : null}
        className={classnames(className, 'w-full', {
          'filter-select-simple': !!simple,
        })}
      >
        {this.props.children}
      </Select>
    );
  }
}

export default FilterSelect;
