export class OperationLogModule {
  static DspOperation = 'OPA_DSP_EP';

  static SspOperation = 'OPA_SSP_EP';

  static NewDspEpOperation = 'OPB_DSP_EP';

  static NewSspEpOperation = 'OPB_SSP_EP';

  static DSP = 'DSP';

  static DSP_EP = 'DSP_EP';

  static SSP = 'SSP';

  static SSP_EP = 'SSP_EP';
}

export class UpdateAction {
  static Update = 'update';

  static Create = 'create';
}
