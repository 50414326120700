import request from '@/http';
import Utils from '@/utils';

export function listDspEpOperation(params) {
  const url = '/api/adx/operation/dsp/ep/aggregation/list';

  return request(url, {
    params: Utils.checkParams(params),
  });
}

// 获取dsp ep 按照geo维度已经审批过的 floor range
export function listDspEpGeoFloorRange(param) {
  const { dspIntegrationId, ...params } = param;

  const url = `/api/adx/dsp/integration/geo/bid/floor/range/${dspIntegrationId}`;

  return request.get(url, {
    params,
  });
}

// 获取ssp ep下拉列表
export function listApprovalSspEp(param) {
  const { dspIntegrationId, countryId, ...params } = param;

  const url = `/api/adx/operation/dsp/ep/aggregation/${dspIntegrationId}/matched/ssp/ep/list`;

  return request.get(url, {
    params,
  });
}

// 获取 ssp ep geo默认配置
export function listDspMatchedSspEpGeo(params) {
  const url = '/api/adx/operation/dsp/ep/aggregation/matched/ssp/ep/geo/list';

  return request.get(url, {
    params,
  });
}

// 更新bid floor
export function updateDspEpGeoFloorRange(ranges) {
  const url = '/api/adx/dsp/integration/geo/bid/floor/range';

  return request.post(url, {
    data: { dspEpGeoBidFloorRanges: ranges },
  });
}

// 获取dsp ssp ep geo配置信息
export function listApprovedSspEpConfig(params) {
  const url = '/api/adx/operation/dsp/ep/aggregation/approve/ssp/ep/config/list';

  return request.get(url, {
    params,
  });
}

// 审批 ssp ep geo
export function approvalDspSspEpGeo(body) {
  const url = '/api/adx/operation/dsp/ep/aggregation/approve/ssp/ep/geo/config';

  return request.post(url, {
    data: body,
  });
}

export function detailApproveDspEp(data) {
  const { dspIntegrationId, ...params } = data;

  const url = `/api/adx/operation/dsp/ep/aggregation/${dspIntegrationId}/approve/detail`;

  return request.get(url, {
    params,
  });
}

export function approveDspEp(body) {
  const url = '/api/adx/operation/dsp/ep/aggregation/approve';

  return request.post(url, {
    data: body,
  });
}

export function detailBlockDspEp(param) {
  const { dspIntegrationId, ...params } = param;

  const url = `/api/adx/operation/dsp/ep/aggregation/${dspIntegrationId}/block/detail`;

  return request.get(url, {
    params,
  });
}

export function blockDspEp(body) {
  const url = '/api/adx/operation/dsp/ep/aggregation/block';

  return request.post(url, {
    data: body,
  });
}

export function deleteDspEp(body) {
  const url = '/api/adx/operation/dsp/ep/aggregation/block';

  return request.delete(url, {
    data: body,
  });
}

export function reFloatRule(sspOperationId) {
  const url = `/api/adx/operation/dsp/ep/aggregation/refloated/${sspOperationId}`;

  return request.post(url);
}

export function reFloatGeoRule(sspOperationId) {
  const url = `/api/adx/operation/dsp/ep/aggregation/ssp/ep/config/refloated/${sspOperationId}`;

  return request.post(url);
}
