import { defaultListData } from '@/models/common.js';
import { listSspBilling, listSspBillingRecord } from './ssp.billing.api';
import { transformSspBillingData } from './ssp.billing.util';
import SspBillingInitData from '@/models/billing/ssp/ssp.billing.init';

const sspBillingModel = {
  namespace: 'sspBillingState',
  state: {
    sspBillingData: SspBillingInitData.sspBillingData,
    sspBillingRecordData: defaultListData,
  },
  effects: {
    *listSspBilling({ payload }, { call, put }) {
      const data = yield call(listSspBilling, payload);

      yield put({
        type: 'listSspBillingSuccess',
        payload: data,
      });
    },

    *listSspBillingRecord({ payload }, { call, put }) {
      const data = yield call(listSspBillingRecord, payload);

      yield put({
        type: 'listSspBillingRecordSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listSspBillingSuccess(state, { payload }) {
      return {
        ...state,
        sspBillingData: transformSspBillingData(payload),
      };
    },

    listSspBillingRecordSuccess(state, { payload }) {
      return {
        ...state,
        sspBillingRecordData: payload,
      };
    },

    clearSspBillingRecordData(state) {
      return {
        ...state,
        sspBillingRecordData: defaultListData,
      };
    },
  },
};

export default sspBillingModel;
