import { listRtbCreativeTracking } from './api';
import { defaultListData } from '@/models/common.js';
import { transformRtbCreativeData } from '@/models/rtb/creative/util';

const rtbCreativeModel = {
  namespace: 'rtbCreativeState',
  state: {
    creativeData: defaultListData,
    uploadOffer: null,
  },
  effects: {
    *listRtbCreativeTracking({ payload }, { call, put }) {
      const data = yield call(listRtbCreativeTracking, payload);

      yield put({
        type: 'listRtbCreativeTrackingSuccess',
        payload: data,
      });

      return data;
    },
  },
  reducers: {
    listRtbCreativeTrackingSuccess(state, { payload }) {
      return {
        ...state,
        creativeData: transformRtbCreativeData(payload),
      };
    },

    updateUploadOffer(state, { payload }) {
      return {
        ...state,
        uploadOffer: payload,
      };
    },
  },
};

export default rtbCreativeModel;
