import React, { useState, useEffect, useMemo, useImperativeHandle, forwardRef } from 'react';
import { useDebounceEffect, useSetState } from 'ahooks';
import { upperFirst, isArray, head, isFunction, isString, isBoolean } from 'lodash';
import classnames from 'classnames';
import { Form, Select, Divider, Button, Segmented, Tooltip } from 'antd';
import { ArrowUpOutlined, CheckOutlined, RetweetOutlined } from '@ant-design/icons';
import Utils from '@/utils';
import FilterSelect from '@/components/FilterSelect';
import TableHelper, { SortDirection } from '@/class/TableHelper';
import SelectService from './select.service';

import './select.less';

const selectService = new SelectService();

const renderKeys = {
  defaultModule: {
    valueKey: 'id',
    nameKey: 'name',
  },
};

// 额外挂在到form的key
export const optionInFormKey = 'selectedOptionInForm';

const BusinessSelect = forwardRef(
  (
    {
      value,
      valueOptionKey = 'id',
      suffixKey = 'id',
      withKeyName = true,
      onChange,
      className,
      module,
      immediately,
      placeholder,
      useAllOption,
      allOptionId = -1,
      useSelect = false,
      sortOptions,
      onOrderChange,
      params, // 接口参数 会直接丢给接口
      optionInForm, // 当前选中的Value对应的option是否放入form `optionInFormKey` 对象中
      direction,
      ...reset
    },
    ref,
  ) => {
    // 表单实例
    const formInstance = Form.useFormInstance();

    const allItem = {
      name: module ? `All ${upperFirst(module.toLowerCase())}` : 'All Selected',
      id: allOptionId,
    };

    const [list, setList] = useState([]);

    const [loading, setLoading] = useState(false);

    const [sorter, setSorter] = useSetState({
      key: head(sortOptions)?.value || null,
      order: SortDirection.Descend,
    });

    const isEmptyList = useMemo(() => {
      return !list || !list.length;
    }, [list]);

    const getItemNameFromProps = (props) => {
      if (props && props.id) {
        const componentId = props.id;

        if (componentId.includes('_')) {
          const [first, ...restFields] = componentId.split('_');

          return [...restFields];
        }

        return [componentId];
      }

      return [];
    };

    const optionInFormFields = useMemo(() => {
      if (isString(optionInForm)) {
        return [optionInForm];
      }

      if (isBoolean(optionInForm)) {
        return getItemNameFromProps(reset);
      }

      return [];
    }, [reset, optionInForm]);

    // 同步到外部事件
    const change = (...args) => {
      if (isFunction(onChange)) {
        onChange(...args);
      }
    };

    // select change事件
    const onSelectChange = (...args) => {
      try {
        // 是否需要额外注入对象
        if (isArray(optionInFormFields) && optionInFormFields.length && isArray(list)) {
          const beforeSelectedOptionInForm = formInstance.getFieldValue([
            optionInFormKey,
            ...optionInFormFields,
          ]);

          if (!beforeSelectedOptionInForm || !beforeSelectedOptionInForm.length) {
            const formatedList = list.map((item) => {
              const optionId = getOptionValue(item);

              const name = getOptionName(item);

              return {
                id: optionId,
                name,
              };
            });

            formInstance.setFieldValue([optionInFormKey, ...optionInFormFields], formatedList);
          }
        }

        change(...args);
      } catch (e) {}
    };

    const withAvailableValue = () => {
      if (isArray(value)) {
        return value && value.length;
      } else {
        return !Utils.falsely(value);
      }
    };

    const getList = () => {
      if (isEmptyList && !loading) {
        setLoading(true);

        selectService.getSelectList(module, params).then(
          (data) => {
            setList(data);

            setLoading(false);
          },
          () => setLoading(false),
        );
      }
    };

    const init = () => {
      if (withAvailableValue() || immediately) {
        getList();
      }
    };

    const onFocus = () => {
      getList();
    };

    const getOptionValue = (item) => {
      const { valueKey } = renderKeys[module] || renderKeys.defaultModule;

      return valueOptionKey && !Utils.falsely(item[valueOptionKey])
        ? item[valueOptionKey]
        : !Utils.falsely(item[valueKey])
        ? item[valueKey]
        : item;
    };

    const getOptionName = (item) => {
      const { nameKey } = renderKeys[module] || renderKeys.defaultModule;

      const optionId = getOptionValue(item);

      // 后缀
      const suffixValue = suffixKey && !Utils.falsely(item[suffixKey]) ? item[suffixKey] : optionId;

      // name
      return withKeyName ? `${item[nameKey]}#${suffixValue}` : item[nameKey];
    };

    const renderOption = () => {
      if (!isEmptyList && isArray(list)) {
        const selectOptionList = useAllOption ? [allItem, ...list] : list;

        return selectOptionList.map((item) => {
          // id
          const optionId = getOptionValue(item);

          // name
          const name = getOptionName(item);

          return (
            <Select.Option data-item={item} value={optionId} key={optionId}>
              {name}
            </Select.Option>
          );
        });
      }

      return null;
    };

    const onInvertSelect = () => {
      const allListIds = list.map((item) => getOptionValue(item));

      const excludeIdList = allListIds.filter((id) => !value.includes(id));

      const invertValue = useAllOption
        ? head(value) === allItem.id
          ? []
          : [allItem.id]
        : excludeIdList;

      onChange(invertValue);
    };

    const onSelectAll = () => {
      const isSelectAll = useAllOption && head(value) === allItem.id;

      if (!isSelectAll) {
        const allListIds = useAllOption ? [allItem.id] : list.map((item) => getOptionValue(item));

        onChange(allListIds);
      }
    };

    const onOrderChangeHandler = (value) => {
      if (isFunction(onOrderChange)) {
        onOrderChange({
          ...value,
          order: TableHelper.getSortKey(value.order),
        });
      }
    };

    const onSortOrderChange = () => {
      if (reset.loading) {
        return;
      }

      const order =
        sorter.order === SortDirection.Descend ? SortDirection.Ascend : SortDirection.Descend;

      const updater = {
        ...sorter,
        order,
      };

      setSorter(updater);

      onOrderChangeHandler(updater);
    };

    const onSortKeyChange = (value) => {
      const updater = {
        ...sorter,
        key: value,
      };

      setSorter(updater);

      onOrderChangeHandler(updater);
    };

    useImperativeHandle(ref, () => {
      return {
        dataSource: list,
      };
    });

    // 同步设置value
    useEffect(() => {
      init();
    }, []);

    // 异步设置value
    useDebounceEffect(
      () => {
        init();
      },
      [value],
      {
        wait: 500,
      },
    );

    return (
      <FilterSelect
        {...reset}
        list={list}
        value={value}
        loading={loading}
        useAllOption={useAllOption}
        allOptionId={allOptionId}
        placeholder={placeholder}
        onFocus={onFocus}
        onChange={onSelectChange}
        className={classnames('w-full', className, {
          'ant-select-direction-rtl': direction === 'rtl',
        })}
        popupClassName={classnames({
          'ant-select-direction-rtl': direction === 'rtl',
        })}
        dropdownRender={(menu) => (
          <>
            {menu}
            {(sortOptions || (useSelect && reset.mode === 'multiple')) && (
              <Divider className="mt-5 mb-5" />
            )}
            {/* sorter */}
            {sortOptions && (
              <div
                className="pt-5 pb-5"
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 5px',
                }}
              >
                <div>
                  <span className="mr-1">Sorter key:</span>
                  <Segmented
                    disabled={reset.loading}
                    value={sorter.key}
                    options={sortOptions}
                    onChange={onSortKeyChange}
                  />
                </div>
                <div>
                  <span className="mr-1">Sorter order:</span>
                  <ArrowUpOutlined
                    className={classnames('filter-select-sort fs-18 pointer text-primary', {
                      'filter-select-sort-arrow-down': sorter.order === SortDirection.Descend,
                    })}
                    onClick={onSortOrderChange}
                  />
                </div>
              </div>
            )}
            {/* 全选/反选 */}
            {useSelect && reset.mode === 'multiple' && !isEmptyList && (
              <div className="text-right">
                <Tooltip title="invert select">
                  <Button className="mr-5" icon={<RetweetOutlined />} onClick={onInvertSelect} />
                </Tooltip>
                <Tooltip title="select all">
                  <Button icon={<CheckOutlined />} onClick={onSelectAll} />
                </Tooltip>
              </div>
            )}
          </>
        )}
      >
        {renderOption()}
      </FilterSelect>
    );
  },
);

export default BusinessSelect;
