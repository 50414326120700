import { isArray } from 'lodash';
import TimeService from '@/services/time.service';
import { PageStatusEnum, ApiStatusEnum } from '@/models/management/permission/permission.enum';

export function transformPagePermissionList(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { createTime, updateTime, status } = item;

      return {
        ...item,
        statusText: status === PageStatusEnum.Enable ? 'Enabled' : 'Disabled',
        createTimeText: TimeService.formatTime(createTime),
        updateTimeText: TimeService.formatTime(updateTime),
      };
    });
  }

  return [];
}

export function transformApiPermissionList(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { createTime, updateTime, status } = item;

      return {
        ...item,
        statusText: status === ApiStatusEnum.Enable ? 'Enabled' : 'Disabled',
        createTimeText: TimeService.formatTime(createTime),
        updateTimeText: TimeService.formatTime(updateTime),
      };
    });
  }

  return [];
}
