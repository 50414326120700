import request from '@/http';
import type { NUser } from '@/models/user/interface';

export function userLogin(body: NUser.LoginBody) {
  const url = '/api/adx/user/login';

  return request.post(url, {
    data: body,
  });
}

export function updateUserPassword(body: NUser.ChangePasswordBody) {
  const url = '/api/adx/user/password';

  return request.put(url, {
    data: body,
  });
}

export function getUserInfo() {
  const url = '/api/adx/user/info';

  return request.get(url);
}

/** 用户自定义保存的报表 搜索项和列 **/
// add
export function createUserFavoriteReport(body: NUser.FavoriteReportBody) {
  const { module, ...rest } = body;

  const url = `/api/adx/user/report/${module}/query`;

  return request.post(url, {
    data: { ...rest },
  });
}

// update
export function updateUserFavoriteReport(body: NUser.FavoriteReportBody) {
  const { module, ...rest } = body;

  const url = `/api/adx/user/report/${module}/query`;

  return request.put(url, {
    data: { ...rest },
  });
}

// list
export function listUserFavoriteReport(module: string) {
  const url = `/api/adx/user/report/${module}/query`;

  return request.get(url);
}
