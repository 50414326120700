import request from '@/http';
/**
 * @param{object} params
 * **/

// 获取账单
export function listDspBilling(params) {
  const url = '/api/adx/dsp/billing/monthly';

  return request.get(url, {
    params,
  });
}

// 更新广告主账单
export function updateDspBilling(body) {
  const url = '/api/adx/dsp/billing/monthly';

  return request.put(url, {
    data: body,
  });
}

// 导出广告主账单
export function exportDspBilling(params) {
  const url = '/api/adx/dsp/billing/monthly/export';

  return request.get(url, {
    params,
  });
}

// history invoice list
export function listDspBillingInvoiceSendList(params) {
  const url = '/api/adx/dsp/billing/invoice/sendlist';

  return request.get(url, {
    params,
  });
}

// resend invoice
export function resendDspBillingInvoice(historySendId) {
  const url = `/api/adx/dsp/billing/invoice/${historySendId}/resend`;

  return request.post(url);
}

// send invoice
export function sendDspBillingInvoice(data) {
  const url = '/api/adx/dsp/billing/invoice';

  return request.post(url, {
    data,
  });
}

// generator invoice no
export function generatorInvoiceNo(body) {
  const url = '/api/adx/dsp/billing/invoice/no/generate';

  return request.post(url, {
    data: body,
  });
}

// add notes
export function updateDspBillingNote(params) {
  const { id, note } = params;

  const url = `/api/adx/dsp/billing/monthly/${id}/notes`;

  return request.put(url, {
    data: note,
  });
}

// operation record
export function listDspBillingOperationRecord(params) {
  const url = '/api/adx/dsp/billing/operation/records';

  return request.get(url, {
    params,
  });
}

// 催收
export function remindDspBilling(body) {
  const { id, ...data } = body;

  const url = `/api/adx/dsp/billing/invoice/${id}/reminder`;

  return request.post(url, {
    data,
    useLoading: true,
  });
}
