class ValidatorService {
  static withHttpHttpsReg = /^(https?:\/\/)\S+$/;

  static isStartWithHttpHttps(url: string) {
    if (url) {
      return ValidatorService.withHttpHttpsReg.test(url);
    }

    return false;
  }

  static validateEmail(value: any) {
    const reg =
      /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/;

    return reg.test(value);
  }

  static isValidDomain(domain: string) {
    const domainRegex = /^(?!-)[A-Za-z\d-]+(\.[A-Za-z\d-]+)*\.[A-Za-z]{2,63}(?<!-)$/;

    return domainRegex.test(domain);
  }

  static isValidAppleBundleId(appId: string) {
    return /^id\d{1,12}$/.test(appId);
  }
}

export default ValidatorService;
