import { isArray } from 'lodash';
import TimeService from '@/services/time.service';
import SspUnitService from '@/pages/operation/ssp/service';
import AuthService from '@/services/auth.service';

export function transformSspEpList(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { sspUnit, sspId, id: sspIntegrationId, createTime, updateTime, managerIds } = item;

      const operationId = SspUnitService.generatorOperationId(item);

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const managerAuthed = AuthService.authOperationData(managerIds);

      const dataAuthed = AuthService.authorizedSsp(sspId);

      const [sspName, dataCenter, adFormat] = sspUnit?.split('-');

      const sspUnitName = `${sspName}#${sspId}-${dataCenter}-${adFormat}#${sspIntegrationId}`;

      return {
        ...item,
        sspUnitName,
        operationId,
        createTimeText,
        updateTimeText,
        userAuthed: managerAuthed || dataAuthed,
      };
    });
  }

  return [];
}
