class SspBillingInitData {
  static sspBillingData = {
    list: [],
    summary: {
      // page summary
      systemRevenue: 0,
      systemPayout: 0,
      finalPayout: 0,
      deduction: 0,
      profit: 0,

      // total summary
      totalSystemRevenue: 0,
      totalSystemPayout: 0,
      totalFinalPayout: 0,
      totalDeduction: 0,
      totalProfit: 0,
    },
    paginator: {
      totalNum: 1,
      totalPage: 1,
      pageSize: 20,
      pageIndex: 1,
    },
  };
}

export default SspBillingInitData;
