import { isArray } from 'lodash';
import Utils from '@/utils';
import request from '@/http';

export function listSspUnit(params) {
  const url = '/api/adx/operation/ssp';

  return request.get(url, {
    params: Utils.checkParams(params),
  });
}

export function listOperationDspApproveUnit(params) {
  const url = '/api/adx/operation/ssp/operation/approve/dsp/units';

  return request.get(url, {
    params,
  });
}

export function listOperationDspBlockUnit(params) {
  const url = '/api/adx/operation/ssp/operation/block/dsp/units';

  return request.get(url, {
    params,
  });
}

export function listDspOffer(params) {
  const url = '/api/adx/operation/ssp/offer/adset/list';

  return request.get(url, {
    params,
  });
}

export function detailExternalSspApprovedUnit(params) {
  const url = '/api/adx/operation/ssp/approve/external';

  return request.get(url, {
    params,
  });
}

export function detailInternalSspApprovedUnit(params) {
  const url = '/api/adx/operation/ssp/approve/internal';

  return request.get(url, {
    params,
  });
}

export function approveInternalSspUnit(body) {
  const url = '/api/adx/operation/ssp/approve/internal';

  return request.post(url, {
    data: body,
  });
}

export function approveExternalSspUnit(body) {
  const url = '/api/adx/operation/ssp/approve/external';

  return request.post(url, {
    data: body,
  });
}

export function detailBlockSspUnit(params) {
  const url = '/api/adx/operation/ssp/block';

  return request.get(url, {
    params,
  });
}

export function blockSspUnit(body) {
  const url = '/api/adx/operation/ssp/block';

  return request.post(url, {
    data: body,
  });
}

export function deleteSspUnit(body) {
  const url = '/api/adx/operation/ssp/block';

  return request.delete(url, {
    data: body,
  });
}

// multiple operations
// list approved ssp
export function listSspApprovedDsp(body) {
  const url = '/api/adx/operation/ssp/approve/detail/batch';

  return request.post(url, {
    data: body,
  });
}

// approval dsp ssp
export function multipleApproveSspDsp(body) {
  const url = '/api/adx/operation/ssp/approve/batch';

  return request.post(url, {
    data: body,
  });
}

// list blocked
export function listSspBlocked(body) {
  const url = '/api/adx/operation/ssp/block/detail/batch';

  return request.post(url, {
    data: body,
  });
}

// multiple block dsp ssp
export function multipleBlockSspDsp(body) {
  const url = '/api/adx/operation/ssp/block/batch';

  return request.post(url, {
    data: body,
  });
}

export function getSspEpIntegrationConfig(integrationId) {
  const url = ` /api/adx/ssp/integration/block/${integrationId}`;

  return request.get(url);
}

export function listInternalDspEp(data) {
  const { dspId = 52, ...params } = data;

  const url = `/api/adx/operation/dsp/${dspId}/integrations`;

  return request.get(url, {
    params,
  });
}

// multiple - list internal approved
export function detailExternalSspApprovedUnitBatch(body) {
  const url = ' /api/adx/operation/ssp/approve/external/detail/batch';

  return request.post(url, {
    data: body,
  });
}

// multiple - list external approved
export function detailInternalSspApprovedUnitBatch(body) {
  const url = '/api/adx/operation/ssp/approve/internal/detail/batch';

  return request.post(url, {
    data: body,
  });
}

// multiple - approve external
export function approveExternalSspUnitBatch(body) {
  const url = '/api/adx/operation/ssp/approve/external/batch';

  return request.post(url, {
    data: body,
  });
}

// multiple - approve external
export function approveInternalSspUnitBatch(body) {
  const url = '/api/adx/operation/ssp/approve/internal/batch';

  return request.post(url, {
    data: body,
  });
}
