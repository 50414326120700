import { isArray } from 'lodash';
import type { NSsp } from '@/models/ssp/interface';
import Utils from '@/utils';
import { MatchTypeEnum } from '@/models/dsp/enum';
import { PxTagEnum } from '@/models/ssp/enum';
import SspConstant from '@/pages/ssp/ssp.constant';
import AuthService from '@/services/auth.service';
import TimeService from '@/services/time.service';

export function transformSspList(list: NSsp.SspItem[]) {
  if (isArray(list) && list.length) {
    return list.map((ssp) => {
      const { createTime, updateTime, managers, countrys, tags, memos } = ssp;

      const formatMemos =
        isArray(memos) && memos.length
          ? memos.map((memo) => {
              return {
                ...memo,
                createTimeText: TimeService.formatTime(memo.createTime),
              };
            })
          : [];

      const managerAuthed = AuthService.authOperationData(
        Utils.getValuesWithProperty(managers, 'id'),
      );

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      return {
        ...ssp,
        createTimeText,
        updateTimeText,
        userAuthed: managerAuthed,
        memos: formatMemos,
        managerText: Utils.getValuesWithProperty(managers, 'name').join(', ') || '--',
        locationText: Utils.getValuesWithProperty(countrys, 'abbr').join(', ') || '--',
        tagText: Utils.getValuesWithProperty(tags, 'value').join(', ') || '--',
      };
    });
  }

  return [];
}

export function transformSspDetail(sspDetail: NSsp.SspDetail) {
  if (!Utils.falsely(sspDetail)) {
    const { createTime, updateTime, deviceLoads = [] } = sspDetail;

    const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
      createTime,
      updateTime,
    ]);

    return {
      ...sspDetail,
      createTimeText,
      updateTimeText,
      userAuthed: AuthService.authorizedSsp(sspDetail.id),
      deviceLoads: transformSspDetailDeviceLoad(deviceLoads),
    };
  }

  return [];
}

export function getTrafficTargetDeviceOsText(trafficTargets: any[]) {
  if (isArray(trafficTargets)) {
    const ret: string[] = [];

    trafficTargets.forEach((item) => {
      if (isArray(item.osTargets)) {
        item.osTargets.forEach((child: any) => {
          const { deviceName, osName } = child;

          const name = `${deviceName}(${osName})`;

          ret.push(name);
        });
      }
    });

    return ret;
  }

  return [];
}

export function transformSspIntegration(list: NSsp.SspIntegrationItem[]) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { createTime, updateTime, trafficTargets, memos } = item;

      const trafficTargetTexts = getTrafficTargetDeviceOsText(trafficTargets);

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const formatMemos =
        isArray(memos) && memos.length
          ? memos.map((memo) => {
              return {
                ...memo,
                createTimeText: TimeService.formatTime(memo.createTime),
              };
            })
          : [];

      return {
        ...item,
        trafficTargets,
        createTimeText,
        updateTimeText,
        memos: formatMemos,
        trafficTargetTexts: [...new Set(trafficTargetTexts)],
        pxTagText: item.pxTag === PxTagEnum.ON ? 'ON' : 'OFF',
      };
    });
  }

  return [];
}

export function transformSspDetailDeviceLoad(list: NSsp.DeviceLoadItem[]) {
  if (list && isArray(list) && list) {
    return list.map((item, idx) => {
      const { matchType, geo, geoName, osTargets } = item;

      return {
        ...item,
        index: idx,
        geoName: geo === -1 ? 'All Country' : geoName,
        matchTypeText: matchType === MatchTypeEnum.Include ? 'Include' : 'Exclude',
        osTargetList: osTargets?.map((os) => {
          const {
            deviceName,
            osName,
            osVersionMax,
            osVersionMaxName,
            osVersionMin,
            osVersionMinName,
          } = os;

          const maxName =
            osVersionMax === SspConstant.UnlimitedOsSelectedOptionId
              ? 'Unlimited'
              : osVersionMaxName;

          const minName =
            osVersionMin === SspConstant.UnlimitedOsSelectedOptionId
              ? 'Unlimited'
              : osVersionMinName;

          return `${osName}(${deviceName})-min(${minName})-max(${maxName})`;
        }),
      };
    });
  }

  return [];
}
