export enum DspUnitStatus {
  Active = 'Active',

  Paused = 'Paused',

  Deleted = 'Deleted',
}

export enum OperationType {
  Approve,

  Block,
}

export enum DspOperation {
  MULTIPLE,

  OPERATION,
}

export enum OperationRuleType {
  Float,

  Fixed,

  Open,
}
