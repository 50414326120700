import Utils from '@/utils';

export class UserStatusEnum {
  static Enabled = 1;

  static Disabled = 0;

  static getText(value) {
    const map = {
      [UserStatusEnum.Enabled]: 'Enabled',
      [UserStatusEnum.Disabled]: 'Disabled',
    };

    if (!Utils.falsely(value)) {
      const text = map[value];

      return text || 'Unknown';
    }

    return 'Unknown';
  }
}
