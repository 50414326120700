import { defaultListData } from '@/models/common.js';
import { listApprovalSspEp, listDspEpOperation } from './api';
import { transformDspEpList, splitDoubleClickEpAndOther } from './util';

const operationDspEpModel = {
  namespace: 'operationDspEp',
  state: {
    dspEpData: defaultListData,
    sspEpList: [],
    dcEpList: [],
    operationState: {},
    hasOperatedIds: [],
    filterFormSspOptions: [],
    approvedSspOptions: [],
  },
  effects: {
    *listDspEpOperation({ payload }, { put, call }) {
      const data = yield call(listDspEpOperation, payload);

      yield put({
        type: 'listDspEpOperationSuccess',
        payload: data,
      });
    },

    *listApprovalSspEp({ payload }, { put, call }) {
      const data = yield call(listApprovalSspEp, payload);

      yield put({
        type: 'listApprovalSspEpSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listDspEpOperationSuccess(state, { payload }) {
      const { list, paginator } = payload;

      return {
        ...state,
        dspEpData: {
          list: transformDspEpList(list),
          paginator,
        },
      };
    },

    listApprovalSspEpSuccess(state, { payload }) {
      const { sspEpList, dcEpList } = splitDoubleClickEpAndOther(payload);

      return {
        ...state,
        sspEpList,
        dcEpList,
      };
    },

    updateOperationState(state, { payload }) {
      const { id, isLoading } = payload;

      const { operationState, hasOperatedIds } = state;

      const flag = hasOperatedIds.includes(id);

      operationState[id] = isLoading;

      if (!flag) {
        hasOperatedIds.push(id);
      }

      return {
        ...state,
        hasOperatedIds: [...hasOperatedIds],
        operationState: { ...operationState },
      };
    },

    updateFilterFormSspOptions(state, { payload }) {
      return {
        ...state,
        filterFormSspOptions: payload?.map((item) => ({
          ...item,
          name: `${item.name}#${item.id}`,
        })),
      };
    },

    updateApprovedSspOptions(state, { payload }) {
      return {
        ...state,
        approvedSspOptions: payload,
      };
    },
  },
};

export default operationDspEpModel;
