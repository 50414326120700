class ModelConstant {
  static Paginator = {
    pageSize: 0,
    pageIndex: 1,
    totalNum: 0,
    totalPage: 0,
  };

  static ListResponse = {
    list: [],
    paginator: ModelConstant.Paginator,
  };
}

export default ModelConstant;
