export class DspUnitStatus {
  static Active = 'Active';

  static Paused = 'Paused';

  static Deleted = 'Deleted';
}

export class OperationType {
  static Approve = 'Approve';

  static Block = 'Block';
}

export class OperationRuleType {
  static Float = 0;

  static Fixed = 1;

  static Open = 2;

  static Share = 3;
}

export class EpDisabledReason {
  static SuibianDatacenter = "It's suibian and the data center is different";

  static DcDatacenter = "It's DC and the data center is different";

  static InternalDsp = "It's internal dsp";

  static SameBrand = 'Same brand';

  static Approved = 'Approved already';
}
