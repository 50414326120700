import { isArray } from 'lodash';
import Dispatch from '@/class/Dispatch';
import {
  addIdentity,
  updateIdentity,
  changeIdentityStatus,
  markIdentityDefault,
  addWire,
  updateWire,
  changeWireStatus,
  markWireDefault,
  addPayonner,
  updatePayonner,
  changePayonnerStatus,
  markPayonnerDefault,
  addPaypal,
  updatePaypal,
  changePaypalStatus,
  markPaypalDefault,
  senderInfo,
} from '@/models/settings/collection/collection.api';
import { CollectionStateEnum } from '@/models/settings/collection/collection.enum';
import PermissionService from '@/services/permission.service';
import Utils from '@/utils';

class CollectionService extends Dispatch {
  senderInfo() {
    this.dispatch({
      type: 'settingsCollectionState/senderInfo',
    });
  }

  listIdentity() {
    this.dispatch({
      type: 'settingsCollectionState/listIdentity',
    });
  }

  static addIdentity(data) {
    return addIdentity(data);
  }

  static updateIdentity(data) {
    return updateIdentity(data);
  }

  static changeIdentityStatus(id, status = CollectionStateEnum.DISABLE) {
    return changeIdentityStatus(id, status);
  }

  static markIdentityDefault(id) {
    return markIdentityDefault(id);
  }

  /**================= wire ===================**/
  listWire() {
    this.dispatch({
      type: 'settingsCollectionState/listWire',
    });
  }

  static addWire(data) {
    return addWire(data);
  }

  static updateWire(data) {
    return updateWire(data);
  }

  static changeWireStatus(id, status = CollectionStateEnum.DISABLE) {
    return changeWireStatus(id, status);
  }

  static markWireDefault(id) {
    return markWireDefault(id);
  }

  /**================= payonner ===================**/
  listPayonner() {
    this.dispatch({
      type: 'settingsCollectionState/listPayonner',
    });
  }

  static addPayonner(data) {
    return addPayonner(data);
  }

  static updatePayonner(data) {
    return updatePayonner(data);
  }

  static changePayonnerStatus(id, status = CollectionStateEnum.DISABLE) {
    return changePayonnerStatus(id, status);
  }

  static markPayonnerDefault(id) {
    return markPayonnerDefault(id);
  }

  /**================= paypal ===================**/
  listPaypal() {
    this.dispatch({
      type: 'settingsCollectionState/listPaypal',
    });
  }

  static addPaypal(data) {
    return addPaypal(data);
  }

  static updatePaypal(data) {
    return updatePaypal(data);
  }

  static changePaypalStatus(id, status = CollectionStateEnum.DISABLE) {
    return changePaypalStatus(id, status);
  }

  static markPaypalDefault(id) {
    return markPaypalDefault(id);
  }

  static updateLocalSender(sender) {
    if (sender) {
      localStorage.setItem('sender', JSON.stringify(sender));
    }
  }

  static getLocalSender() {
    let sender = {};

    try {
      sender = JSON.parse(localStorage.getItem('sender'));
    } catch (e) {}

    return sender;
  }

  static clearSender() {
    localStorage.removeItem('sender');
  }

  static initLocalSender = (menuData = []) => {
    const collectionRoute = '/settings/collection';

    const localSender = CollectionService.getLocalSender();

    if (isArray(menuData) && menuData.length) {
      if (PermissionService.routeValidate(menuData, 'path', collectionRoute)) {
        CollectionService.changeSenderInfoImageBase64ToLocal();
      }
    } else {
      if (!localSender || Utils.falsely(localSender.logoBase64)) {
        CollectionService.changeSenderInfoImageBase64ToLocal();
      }
    }
  };

  static changeSenderInfoImageBase64ToLocal() {
    senderInfo().then((sender) => {
      if (sender) {
        const { logo } = sender;

        CollectionService.updateLocalSender(sender);

        Utils.fetchNetworkImageToBase64(logo, (logoBase64) => {
          const localSender = CollectionService.getLocalSender();

          CollectionService.updateLocalSender({
            ...localSender,
            ...sender,
            logoBase64,
          });
        });
      }
    });
  }
}

export default CollectionService;
