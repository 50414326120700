import request from '@/http';
import Utils from '@/utils';
import type { NDsp } from '@/models/dsp/interface';
import { DspIdentityStatusEnum } from '@/models/dsp/enum';

export function listDsp(params: NDsp.ListDspParams) {
  const url = '/api/adx/dsp';

  return request.get(url, {
    params: Utils.filterNullable(params),
  });
}

export function createDsp(dsp: NDsp.AddDspParams) {
  const url = '/api/adx/dsp';

  return request.post(url, {
    data: dsp,
    useLoading: true,
  });
}

// list update
export function updateListDsp(dsp: NDsp.UpdateDspParams) {
  const url = '/api/adx/dsp';

  return request.put(url, {
    data: dsp,
    useLoading: true,
  });
}

// list update
export function updateDetailDsp(dsp: NDsp.UpdateDspParams) {
  const url = '/api/adx/dsp/detail';

  return request.put(url, {
    data: dsp,
    useLoading: true,
  });
}

export function deleteDsp(dspIds: number[]) {
  const url = '/api/adx/dsp';

  return request.delete(url, {
    params: {
      ids: dspIds,
    },
  });
}

// detail
export function detailDsp(dspId: number) {
  const url = `/api/adx/dsp/detail/${dspId}`;

  return request.get(url);
}

// dsp integration
export function listDspIntegration(body: NDsp.ListDspIntegrationParams) {
  const { dspId, ...params } = body;

  const url = `/api/adx/dsp/integration/${dspId}`;

  return request.get(url, {
    params,
  });
}

// add dsp integration
export function addDspIntegration(body: NDsp.AddUpdateIntegrationBody) {
  const url = '/api/adx/dsp/integration';

  return request.post(url, {
    data: body,
    useLoading: true,
  });
}

// edit dsp integration
export function updateDspIntegration(body: NDsp.AddUpdateIntegrationBody) {
  const { id, ...data } = body;

  const url = `/api/adx/dsp/integration/${id}`;

  return request.put(url, {
    data,
  });
}

// 检查当前dsp integration 是否已经配置了 ssp (operation)
export function checkDspIntegrationUsing(params: NDsp.CheckDspUpdateParams) {
  const { id, ...restBody } = params;

  const url = `/api/adx/dsp/integration/operation/check/${id}`;

  return request.post(url, {
    data: {
      ...restBody,
    },
    getResponse: true,
    useToast: false,
  });
}

// list dsp identity
export function listDspIdentity(dspId: number) {
  const url = `/api/adx/dsp/${dspId}/invoice/identity`;

  return request.get(url);
}

// add identity
export function addDspIdentity(params: NDsp.AddIdentityParams) {
  const { dspId, ...data } = params;

  const url = `/api/adx/dsp/${dspId}/invoice/identity`;

  return request.post(url, {
    data,
  });
}

// update identity
export function updateDspIdentity(data: NDsp.AddIdentityParams) {
  const url = '/api/adx/dsp/invoice/identity';

  return request.put(url, {
    data,
  });
}

// delete adv identity 禁用就是删除
export function deleteDspIdentity(id: number) {
  return updateDspIdentity({ id, status: DspIdentityStatusEnum.Disabled });
}

export function markDspIdentityDefault(id: number) {
  const url = '/api/adx/dsp/invoice/identity/default';

  return request.put(url, {
    data: id,
  });
}

// 获取dsp/ssp ep 通用设置
export function getDspEpIntegrationConfig(integrationId: number) {
  const url = `/api/adx/dsp/integration/approve/block/${integrationId}`;

  return request.get(url);
}

// 根据integration id 获取info
export function getDspEpIntegrationInfo(integrationId: number) {
  const url = `/api/adx/dsp/integration/${integrationId}/infos`;

  return request.get(url);
}
