import type { NUser } from '@/models/user/interface';
import { userLogin, updateUserPassword, getUserInfo } from '@/models/user/api';
import Utils from '@/utils';
import AuthService from '@/services/auth.service';

class UserService {
  static userLogin(body: NUser.LoginBody) {
    return userLogin(body);
  }

  static async getUserInfo() {
    const user = await getUserInfo();

    if (user) {
      UserService.updateLocalUser(user);
    }

    return user;
  }

  static updateUserPassword(body: NUser.ChangePasswordBody) {
    return updateUserPassword(body);
  }

  static updateLocalUser(user: NUser.User) {
    if (!Utils.falsely(user)) {
      window.localStorage.setItem('user', JSON.stringify(user));
    }
  }

  static getLocalUser(): NUser.User | null {
    // @ts-ignore
    let user: NUser.User = null;

    try {
      const userString = window.localStorage.getItem('user');

      if (!Utils.falsely(userString)) {
        // @ts-ignore
        user = JSON.parse(userString);
      }
    } catch (e) {}

    return user;
  }

  static clearLocalUser() {
    window.localStorage.removeItem('user');

    window.localStorage.removeItem('sender');
  }

  static updateLocalToken(token: string): void {
    window.localStorage.setItem('token', token);
  }

  static getLocalToken(): string | null {
    return window.localStorage.getItem('token');
  }

  static clearLocalToken() {
    window.localStorage.removeItem('token');
  }

  // 获取user group
  static getUserAuthorizedGroup() {
    const user = UserService.getLocalUser();

    const { authorizedDspIds = [], authorizedSspIds = [], groups = [] } = user || {};

    const groupNames = Utils.getValuesWithProperty(groups, 'name');

    return { authorizedDspIds, authorizedSspIds, groups, groupNames };
  }

  // 是否是外部用户
  static getIsExternalUser() {
    const user = UserService.getLocalUser();

    const demonstration = AuthService.authorizedDemonstration();

    return user?.external || demonstration;
  }
}

export default UserService;
