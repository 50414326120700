export class AdFormat {
  static Banner = 'banner';

  static Native = 'native';

  static Video = 'video';
}

export class AdSetStateEnum {
  static Active = 'Active';

  static Paused = 'Paused';
}

export class AutoJumpCreativeState {
  static Passive = 0;

  static Active = 1;
}

export class IpCheckingState {
  static OFF = 0;

  static ON = 1;
}

export class CreativeCheckingState {
  static OFF = 0;

  static ON = 1;
}
