import {
  isArray,
  head,
  isNaN,
  isNumber,
  nth,
  toLower,
  startsWith,
  omit,
  get,
  isEqual,
  difference,
} from 'lodash';
import Dispatch from '@/class/Dispatch';
import {
  approveDspUnit,
  blockDspUnit,
  deleteDspUnit,
  detailApproveDspUnit,
  detailBlockDspUnit,
  reFloatRule,
} from '@/models/operation/dsp/api';
import type { NDspOperation } from '@/models/operation/dsp/interface';
import type { NDsp } from '@/models/dsp/interface';
import Utils from '@/utils';
import UserService from '@/pages/user/user.service';
import { OperationRuleType } from '@/models/operation/dsp/enum';
import DspMultipleApproveService from '@/pages/operation/dsp/approve/service';
import DspUnitConstant from '@/pages/operation/dsp/constant';
import DspSspOperationService from '@/pages/operation/operation.service';
import { OperationLogModule } from '@/models/operation-log/enum';
import OperationLogService from '@/models/operation-log/operation-log.service';

class DspUnitService extends Dispatch {
  listDspUnit(params: NDspOperation.ListDspUnitParams) {
    this.dispatch({
      type: 'operationDspUnitState/listDspUnit',
      payload: params,
    });
  }

  listOperationSspUnit(params: NDspOperation.ListSspUnitParams) {
    this.dispatch({
      type: 'operationDspUnitState/listOperationSspUnit',
      payload: params,
    });
  }

  updateOperationState(params: NDspOperation.IOperationState) {
    this.dispatch({
      type: 'operationDspUnitState/updateOperationState',
      payload: params,
    });
  }

  updateFilterFormSspOptions(list: any[]) {
    this.dispatch({
      type: 'operationDspUnitState/updateFilterFormSspOptions',
      payload: list,
    });
  }

  updateApprovedSspOptions(list: any[]) {
    this.dispatch({
      type: 'operationDspUnitState/updateApprovedSspOptions',
      payload: list,
    });
  }

  static approveDspUnit(body: NDspOperation.ApproveDspUnitBody) {
    return approveDspUnit(body);
  }

  static blockDspUnit(body: NDspOperation.BlockDspUnitBody) {
    return blockDspUnit(body);
  }

  static deleteDspUnit(body: NDspOperation.BlockDspUnitBody) {
    return deleteDspUnit(body);
  }

  static detailApproveDspUnit(params: NDspOperation.DetailOperation) {
    return detailApproveDspUnit(params);
  }

  static detailBlockDspUnit(params: NDspOperation.DetailOperation) {
    return detailBlockDspUnit(params);
  }

  static reFloatRule(sspOperationId: number) {
    return reFloatRule(sspOperationId);
  }

  static getOperationParams(item: NDspOperation.DspUnitItem): NDspOperation.ListSspUnitParams {
    if (!Utils.falsely(item)) {
      const { dspIntegrationId, countryId, os, adFormat } = item;

      return {
        dspIntegrationId,
        countryId,
        os,
        adFormat,
      };
    }

    return {
      dspIntegrationId: null,
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  static generalSspSortList(ids: number[]) {
    if (isArray(ids)) {
      return ids.map((id) => ({
        sspIntegrationId: id,
        time: +new Date(),
      }));
    }

    return [];
  }

  static splitBundleIds(bundleIds?: string[]) {
    const androidBundleIds: string[] = [];

    const iosBundleIds: string[] = [];

    if (isArray(bundleIds) && bundleIds.length) {
      bundleIds.forEach((bundleId) => {
        const lower = toLower(bundleId);

        // iOS
        if (startsWith(lower, 'id') || !lower.includes('.')) {
          iosBundleIds.push(bundleId);

          return;
        }

        // Android
        androidBundleIds.push(bundleId);
      });

      return {
        androidBundleIds,
        iosBundleIds,
      };
    }

    return {
      androidBundleIds,
      iosBundleIds,
    };
  }

  static mergeConfigApproveDetail(
    config: NDsp.DspEpIntegrationConfig,
    detail: NDspOperation.DspUnitApproveDetail,
    dspItem: NDspOperation.DspUnitItem,
  ): NDspOperation.DspUnitApproveDetail {
    const existConfig =
      config && (config.approveHighWeights?.length || config.approveWhiteLists?.length);

    // 第一次审批
    if (!detail) {
      const merge: any = {
        highWeights: [],
        whiteLists: [],
      };

      // dsp 存在通用配置
      if (existConfig) {
        const { approveHighWeights, approveWhiteLists } = config;

        const lowerOsName = toLower(dspItem?.osName);

        const highWeightsBundle = DspUnitService.splitBundleIds(approveHighWeights);

        const whiteListsBundle = DspUnitService.splitBundleIds(approveWhiteLists);

        const isIOS = lowerOsName === 'ios';

        merge.highWeights = isIOS
          ? highWeightsBundle.iosBundleIds
          : highWeightsBundle.androidBundleIds;

        merge.whiteLists = isIOS
          ? whiteListsBundle.iosBundleIds
          : whiteListsBundle.androidBundleIds;

        if (merge.highWeights.length && merge.whiteLists.length) {
          merge.highWeights = [];
        }
      }

      return {
        ...merge,
        floorPriceRangeMax: dspItem?.maxBidFloor || 9999,
        floorPriceRangeMin: dspItem?.minBidFloor || 0,
      };
    }

    return detail;
  }

  static checkAvailableBlockConfig(blockData: any) {
    if (blockData) {
      const { bundleIds, pubIds, ips } = blockData;

      return !!bundleIds.length || !!pubIds.length || !!ips.length;
    }

    return false;
  }

  static mergeConfigBlockDetail(
    config: NDsp.DspEpIntegrationConfig,
    detail: NDspOperation.BlockDspUnitDetail,
    osName?: string,
  ): NDspOperation.BlockDspUnitDetail {
    const existConfig =
      config &&
      (config.blockIps?.length ||
        config.blockBundleIds?.length ||
        config.blockPubIds?.length ||
        config.blockCreativeIds?.length);

    if (existConfig && !detail) {
      const { blockIps = [], blockBundleIds, blockPubIds = [] } = config;

      const isIos = toLower(osName) === 'ios';

      const bundleIdList = DspUnitService.splitBundleIds(blockBundleIds);

      return {
        ips: blockIps,
        pubIds: blockPubIds,
        bundleIds: isIos ? bundleIdList.iosBundleIds : bundleIdList.androidBundleIds,
      };
    }

    return detail || {};
  }

  static mergeQpsRuleType = (item: NDspOperation.SspOperationItem) => {
    const { fixed, floated, opened } = item;

    const data = {
      [OperationRuleType.Float]: floated !== -1,
      [OperationRuleType.Fixed]: fixed,
      [OperationRuleType.Open]: opened,
    };

    const type = head(
      Object.keys(data).filter((key: string) => {
        return !!data[key];
      }),
    );

    // @ts-ignore
    return !isNaN(type) ? +type : type;
  };

  static dspUnitApproveDetailToForm(unit: NDspOperation.DspUnitApproveDetail) {
    const {
      floorPriceRangeMin,
      floorPriceRangeMax,
      whiteLists,
      highWeights,
      sspOperations,
      ...restApprovedDspUnit
    } = unit;

    const sspList: number[] = [];

    const sspOperationList: any[] = [];

    if (isArray(sspOperations) && sspOperations.length) {
      sspOperations.forEach((item) => {
        const sspIntegrationId = item.sspIntegrationId;

        sspList.push(sspIntegrationId);

        const {
          qps,
          floorIncrease,
          payoutDecrease,
          fixed,
          floated,
          opened,
          qpsFloatRulesName,
          id,
        } = item;

        const qpsFloatRule = {
          fixed,
          ruleId: floated,
          originRuleId: floated,
          qpsFloatRulesName,
          opened,
        };

        const qpsRuleType = DspUnitService.mergeQpsRuleType(item);

        sspOperationList.push({
          qpsRuleType,
          id,
          qps,
          floorIncrease,
          payoutDecrease,
          qpsFloatRule,
          sspIntegrationId,
        });
      });
    }

    return {
      floorPriceRangeMin:
        isNumber(floorPriceRangeMin) && floorPriceRangeMin >= 0 ? floorPriceRangeMin : 0,
      floorPriceRangeMax: isNumber(floorPriceRangeMax) ? floorPriceRangeMax : 9999,
      sspOperations: sspOperationList,
      restApprovedDspUnit: DspUnitService.omitApproveRestParams(restApprovedDspUnit),
      whiteLists,
      highWeights,
      sspList,
    };
  }

  static omitApproveRestParams(params: any) {
    return omit(params, ['bidPriceRangeMin', 'bidPriceRangeMax', 'internalDspOperationSwitch']);
  }

  static getApprovedSspOptions(list: NDspOperation.SspOperationItem[]) {
    if (isArray(list)) {
      return list.map((item) => {
        const { sspIntegrationId, sspUnit } = item;

        return {
          id: sspIntegrationId,
          name: sspUnit,
        };
      });
    }

    return [];
  }

  static dspUnitBlockDetailToForm(unit: NDspOperation.BlockDspUnitDetail) {
    const { ips, pubIds, bundleIds, sspOperations, ...restBlockDspUnit } = unit;

    return {
      sspUnits: isArray(sspOperations) ? sspOperations.map((item) => item.sspIntegrationId) : [],
      ips,
      pubIds,
      bundleIds,
      restBlockDspUnit,
    };
  }

  static sortSspOperations(operations: any[], sortList: any[]) {
    if (isArray(operations) && isArray(sortList) && sortList.length) {
      return operations
        .map((item) => {
          const sorter = sortList.find((child) => child.sspIntegrationId === item.sspIntegrationId);

          if (sorter) {
            return {
              ...item,
              updateTime: sorter.time,
            };
          }

          return item;
        })
        .sort((a, b) => {
          return b.updateTime - a.updateTime;
        });
    }

    return operations;
  }

  static dspUnitSspListToBody(list: any[]) {
    if (isArray(list)) {
      return list.map((item) => {
        const { qpsFloatRule, qpsRuleType, ...rest } = item;

        const { ruleId } = qpsFloatRule;

        const fixed = qpsRuleType === OperationRuleType.Fixed;

        const opened = qpsRuleType === OperationRuleType.Open;

        const floated = qpsRuleType === OperationRuleType.Float;

        return {
          ...rest,
          fixed,
          floated: floated ? (ruleId === -1 ? 0 : ruleId) : -1,
          opened: opened ? 1 : 0,
        };
      });
    }

    return [];
  }

  static compareArrayOneRepeat(source: string[], target: string[]) {
    if (!source?.length || !target?.length) {
      return false;
    }

    return source.length > 0 ? target.some((str) => source.includes(str)) : false;
  }

  static generatorOperationId(item: any) {
    const { dspIntegrationId, countryId, os, adFormat } = item;

    return [dspIntegrationId, countryId, os, adFormat].join('_');
  }

  static decodeOperationId(operationId: string) {
    if (operationId && operationId.includes('_')) {
      const [dspIntegrationId, countryId, os, adFormat] = operationId
        .split('_')
        ?.map((item: any) => DspMultipleApproveService.number(item));

      return {
        dspIntegrationId,
        countryId,
        os,
        adFormat,
      };
    }

    return {
      dspIntegrationId: null,
      countryId: null,
      os: null,
      adFormat: null,
    };
  }

  static getDatacenterFromUnit(unit: string) {
    if (unit && unit.includes('-')) {
      // 名字里面可能带有'-' 倒着取
      const datacenterList = unit.split('-').slice(-5);

      if (datacenterList.length > 0) {
        return nth(datacenterList, 0);
      }

      return '';
    }

    return '';
  }

  static getBrandNameFromUnit(unit: string) {
    if (unit && unit.includes('-')) {
      // 名字里面可能带有'-' 倒着取
      return head(unit.split('-'));
    }

    return '';
  }

  static transformSspUnits(
    list: NDspOperation.SspUnitItem[],
    aggregationConfigList: NDspOperation.DspSspEpConfigItem[],
    dsp: NDspOperation.DspUnitItem,
  ) {
    if (isArray(list)) {
      const isExternalUser = UserService.getIsExternalUser();

      return list.map((item) => {
        const aggregationConfigSspEpIds = isArray(aggregationConfigList)
          ? aggregationConfigList.map((item) => item.sspIntegrationId)
          : [];

        const sspDataCenter = DspUnitService.getDatacenterFromUnit(item.sspUnit);

        const dspDataCenter = DspUnitService.getDatacenterFromUnit(dsp.dspUnit);

        const dspBrand = DspUnitService.getBrandNameFromUnit(dsp.dspUnit);

        const sspBrand = DspUnitService.getBrandNameFromUnit(item.sspUnit);

        const isRvInHouse = item.sspUnit?.includes(DspUnitConstant.ONLINE_RV_INHOUSE_PREFIX);

        const mergeSuibianStatus = isRvInHouse || sspDataCenter !== dspDataCenter;

        const isDisabledSuibian =
          dsp?.dspId === DspUnitConstant.ONLINE_DSP_SUIBIAN_ID ? mergeSuibianStatus : false;

        // 同品牌禁用，自己不给批给自己
        const dspSspSameBrand = toLower(dspBrand) === toLower(sspBrand);

        // 是否已经在new operation审批过的ep
        const isApprovedInAggregation = aggregationConfigSspEpIds.length
          ? aggregationConfigSspEpIds.includes(item.sspIntegrationId)
          : false;

        const reasonObj = {
          suiBianDatacenter: !isExternalUser && isDisabledSuibian,
          sameBrand: dspSspSameBrand,
          approved: isApprovedInAggregation,
        };

        const reason = DspSspOperationService.getDisableReason(reasonObj);

        const disabled =
          (!isExternalUser && (isDisabledSuibian || dspSspSameBrand)) || isApprovedInAggregation;

        return {
          ...item,
          disabled,
          title: reason.join('.\n'),
        };
      });
    }

    return [];
  }

  static getSspOperationEffectMemos(changedFields: any[], formValues: any, updateTarget: any) {
    const getValueDiff = (oldValue: number[], value: number[]) => {
      const action = value.length > oldValue.length;

      const diffValue = action ? difference(value, oldValue) : difference(oldValue, value);

      return {
        value: diffValue,
        action: action ? 'Add' : 'Remove',
      };
    };

    const changed: any[] = [];

    const collectionSspMemos = (changedSsp: any) => {
      const { name, value, plug } = changedSsp || {};

      const oldValue = get(formValues, name);

      if (!isEqual(oldValue, value)) {
        const diff = isArray(value)
          ? getValueDiff(oldValue, value)
          : {
              value,
              action: 'Add',
            };

        // ssp id list
        if (isArray(diff.value)) {
          diff.value.forEach((sspId) => {
            const changedRecord = {
              plug,
              action: `From OPA DSP ${diff.action}`,
              path: name,
              from: null,
              to: updateTarget.dspIntegrationId,
            };

            changed.push({
              os: updateTarget.os,
              countryId: updateTarget.countryId,
              sspIntegrationId: sspId,
              content: {
                operations: [changedRecord],
              },
              page: OperationLogModule.SspOperation,
            });
          });
        }
      }
    };

    const collectionSspOptionMemos = (changedSspOperation: any) => {
      const { name, value, plug } = changedSspOperation || {};

      const oldValue = get(formValues, name);

      if (!isEqual(oldValue, value)) {
        const changedRecord = {
          plug,
          action: 'From OPA DSP Update',
          path: name,
          from: oldValue,
          to: value,
        };

        const hasSspMemoItem = changed.some(
          (sspMemoItem) => sspMemoItem.sspIntegrationId === plug.sspIntegrationId,
        );

        if (hasSspMemoItem) {
          changed.forEach((changedItem) => {
            if (changedItem.sspIntegrationId === plug.sspIntegrationId) {
              changedItem.content.operations.push(changedRecord);
            }
          });

          return;
        }

        changed.push({
          os: updateTarget.os,
          countryId: updateTarget.countryId,
          sspIntegrationId: plug.sspIntegrationId,
          content: {
            operations: [changedRecord],
          },
          page: OperationLogModule.SspOperation,
        });
      }
    };

    if (formValues && changedFields && changedFields.length) {
      changedFields.forEach((item) => {
        const changeName = head(item.name);

        if (changeName === 'sspList') {
          collectionSspMemos(item);
        }

        if (changeName === 'sspOperations' && OperationLogService.isListNameField(item.name)) {
          collectionSspOptionMemos(item);
        }
      });
    }

    return changed;
  }
}

export default DspUnitService;
