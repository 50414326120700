import request from '@/http';
import { CollectionStateEnum } from '@/models/settings/collection/collection.enum';

// sender
export function senderInfo() {
  const url = '/api/adx/invoice/sender';

  return request.get(url);
}

// list identity
export function listIdentity() {
  const url = '/api/adx/identity';

  return request.get(url);
}

// add identity
export function addIdentity(data) {
  const url = '/api/adx/identity';

  return request.post(url, {
    data,
  });
}

// update identity
export function updateIdentity(data) {
  const url = '/api/adx/identity';

  return request.put(url, {
    data,
  });
}

// identity status
export function changeIdentityStatus(id, status = CollectionStateEnum.DISABLE) {
  const url = '/api/adx/identity';

  return request.put(url, {
    data: {
      id,
      status,
    },
  });
}

// default
export function markIdentityDefault(id) {
  const url = '/api/adx/identity/default';

  return request.put(url, {
    data: id,
  });
}
/**======================== Wire ==============================**/

// wire list
export function listWire() {
  const url = '/api/adx/payment/wire';

  return request.get(url);
}

// add wire
export function addWire(body) {
  const url = '/api/adx/payment/wire';

  return request.post(url, {
    data: body,
  });
}

// update wire
export function updateWire(body) {
  const url = '/api/adx/payment/wire';

  return request.put(url, {
    data: body,
  });
}

// mart default
export function markWireDefault(id) {
  const url = '/api/adx/payment/wire/default';

  return request.put(url, {
    data: id,
  });
}

// wire status
export function changeWireStatus(id, status = CollectionStateEnum.DISABLE) {
  const url = '/api/adx/payment/wire';

  return request.put(url, {
    data: {
      id,
      status,
    },
  });
}

/**======================== Payonner ==============================**/

// payonner list
export function listPayonner() {
  const url = '/api/adx/payment/payonner';

  return request.get(url);
}

// add wire
export function addPayonner(body) {
  const url = '/api/adx/payment/payonner';

  return request.post(url, {
    data: body,
  });
}

// update wire
export function updatePayonner(body) {
  const url = '/api/adx/payment/payonner';

  return request.put(url, {
    data: body,
  });
}

// mart default
export function markPayonnerDefault(id) {
  const url = '/api/adx/payment/payonner/default';

  return request.put(url, {
    data: id,
  });
}

// wire status
export function changePayonnerStatus(id, status = CollectionStateEnum.DISABLE) {
  const url = '/api/adx/payment/payonner';

  return request.put(url, {
    data: {
      id,
      status,
    },
  });
}

/**======================== Paypal ==============================**/

// payonner list
export function listPaypal() {
  const url = '/api/adx/payment/paypal';

  return request.get(url);
}

// add wire
export function addPaypal(body) {
  const url = '/api/adx/payment/paypal';

  return request.post(url, {
    data: body,
  });
}

// update wire
export function updatePaypal(body) {
  const url = '/api/adx/payment/paypal';

  return request.put(url, {
    data: body,
  });
}

// mart default
export function markPaypalDefault(id) {
  const url = '/api/adx/payment/paypal/default';

  return request.put(url, {
    data: id,
  });
}

// wire status
export function changePaypalStatus(id, status = CollectionStateEnum.DISABLE) {
  const url = '/api/adx/payment/paypal';

  return request.put(url, {
    data: {
      id,
      status,
    },
  });
}
