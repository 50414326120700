import { isArray } from 'lodash';
import DspSspOperationService from '@/pages/operation/operation.service';
import TimeService from '@/services/time.service';
import AuthService from '@/services/auth.service';
import OperationDspSspConstant from '@/pages/operation/operation.constant';

export function transformDspEpList(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const { dspUnit, dspId, id: dspIntegrationId, createTime, updateTime, managerIds } = item;

      const operationId = DspSspOperationService.generatorOperationId(item);

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const managerAuthed = AuthService.authOperationData(managerIds);

      const dataAuthed = AuthService.authorizedDsp(item.dspId);

      const [dspName, dataCenter, adFormat] = dspUnit?.split('-');

      const dspUnitName = `${dspName}#${dspId}-${dataCenter}-${adFormat}#${dspIntegrationId}`;

      return {
        ...item,
        dspUnitName,
        operationId,
        createTimeText,
        updateTimeText,
        userAuthed: managerAuthed || dataAuthed,
      };
    });
  }

  return [];
}

export function splitDoubleClickEpAndOther(list) {
  const sspEpList = [];

  const dcEpList = [];

  if (isArray(list) && list.length) {
    list.forEach((item) => {
      const { sspUnit, sspId } = item;

      const [sspName, dataCenter, adFormat] = sspUnit?.split('-');

      // 带id显示的时候用的
      const sspUnitText = `${sspName}#${sspId}-${dataCenter}-${adFormat}`;

      // 在进行比较的时候用的
      const sspUnitName = `${sspName}-${dataCenter}-${adFormat}`;

      const formatItem = {
        ...item,
        sspUnitText,
        sspUnit: sspUnitName,
      };

      if (item.sspId === OperationDspSspConstant.ONLINE_SSP_DC_ID) {
        dcEpList.push(formatItem);

        return;
      }

      sspEpList.push(formatItem);
    });
  }

  return {
    sspEpList,
    dcEpList,
  };
}
