import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import relativeTime from 'dayjs/plugin/relativeTime';
import Utils from '@/utils';
import { isArray, isFunction } from 'lodash';

import 'dayjs/locale/zh-cn';

dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.locale('zh-cn');

class TimeService {
  static GMT_8_Id = 28;

  static GMT_0_Id = 16;

  static GMT_0_zone_id = 'Etc/GMT';

  static TimeType = {
    Today: 'getToday',

    Yesterday: 'getYesterday',

    ThisWeek: 'getThisWeek',

    LastWeek: 'getLastWeek',

    ThisMonth: 'getThisMonth',

    LastMonth: 'getLastMonth',
  };

  static getTime(type: string) {
    return isFunction(TimeService[type])
      ? TimeService[type]()
      : TimeService[TimeService.TimeType.Today]();
  }

  static getToday() {
    const startDate = dayjs().startOf('day');

    const endDate = dayjs().endOf('day');

    return [startDate, endDate];
  }

  static getYesterday() {
    const start = dayjs().subtract(1, 'days').startOf('day');

    const end = dayjs().subtract(1, 'days').endOf('day');

    return [start, end];
  }

  static getThisWeek() {
    const start = dayjs().startOf('week');

    const end = dayjs().endOf('week');

    return [start, end];
  }

  static getLastWeek() {
    const start = dayjs().add(-1, 'week').startOf('week');

    const end = dayjs().add(-1, 'week').endOf('week');

    return [start, end];
  }

  static getThisMonth() {
    const startDate = dayjs().startOf('month');

    const endDate = dayjs().endOf('month');

    return [startDate, endDate];
  }

  static getLastMonth() {
    const startDate = dayjs().add(-1, 'month').startOf('month');

    const endDate = dayjs().add(-1, 'month').endOf('month');

    return [startDate, endDate];
  }

  static getClientTimezone() {
    const oDate = new Date();

    const nTimezone = -oDate.getTimezoneOffset() / 60;

    return +nTimezone.toFixed(2);
  }

  static getIntlDateTimeOptions() {
    return Intl.DateTimeFormat().resolvedOptions();
  }

  static formatTime(timestamp: number, format = 'YYYY-MM-DD HH:mm:ss') {
    return timestamp ? dayjs(timestamp).format(format) : null;
  }

  static fromTimezone(timestamp: number, fmt = 'YYYY-MM-DD HH:mm:ss') {
    if (!Utils.falsely(timestamp)) {
      return TimeService.formatTime(timestamp, fmt);
    }

    return '--';
  }

  /**
   *@description:获取时长
   *@param{any} time
   *@returns{number} 间隔时长
   */
  static getTimeDurationNumber(time: any) {
    if (isArray(time)) {
      const [start, end] = time;

      const timeStart = dayjs(start);

      const timeEnd = dayjs(end);

      return timeEnd.diff(timeStart, 'days');
    }

    return 0;
  }

  static multipleFormatTime(times: any[], fmt: string = 'YYYY-MM-DD HH:mm:ss') {
    if (isArray(times)) {
      return times.map((time) => TimeService.formatTime(time, fmt));
    }

    return [];
  }
}

export default TimeService;
