import request from '@/http';
import { isArray } from 'lodash';

// 获取预算类型为 by ssp的存量列表
export function listSspBudgetUnit(body) {
  const url = '/api/adx/dsp/offer/adset/dsp/ssp/budget/list';

  return request.post(url, {
    data: body,
  });
}

export function listSetSspBudgetUnit(body) {
  const url = '/api/adx/dsp/offer/adset/dsp/ssp/budget/detail/list';

  return request.post(url, {
    data: body,
  });
}

// 更新by ssp 预算
export function operationSspBudget(body) {
  const url = '/api/adx/dsp/offer/adset/dsp/ssp/budget/operation';

  return request.post(url, {
    data: body,
  });
}

export function deleteSspBudgetUnit(ids) {
  const url = '/api/adx/dsp/offer/adset/dsp/ssp/budget';

  const merge = isArray(ids) ? ids : [ids];

  return request.delete(url, {
    useLoading: true,
    data: {
      ids: merge,
    },
  });
}

// 获取adset的配置信息
export function listAdSetSspUnit(params) {
  const url = '/api/adx/dsp/offer/adset/dsp/ssp/budget/units';

  return request.get(url, {
    params,
  });
}

// 获取bid rate
export function listSspUnitBidRate(id) {
  const url = `/api/adx/dsp/offer/adset/dsp/ssp/budget/${id}/bidrate`;

  return request.get(url);
}

// 获取budget更新记录
export function listBidRateMemos(id) {
  const url = `/api/adx/dsp/offer/adset/dsp/ssp/budget/${id}/bidrate/memos`;

  return request.get(url);
}

// 获取budget主记录更新记录
export function listBidRateUpdateMemos(id) {
  const url = `/api/adx/dsp/offer/adset/dsp/ssp/budget/${id}/memos`;

  return request.get(url);
}
