import UserService from '@/pages/user/user.service';
import PermissionService from '@/services/permission.service';

interface globalModelState {}

interface GlobalModel {
  namespace: string;
  state: globalModelState;
  reducers: {};
  subscriptions: any;
}

const globalModel: GlobalModel = {
  namespace: 'globalState',
  state: {},
  reducers: {},
  subscriptions: {
    setup({ history }: any) {
      return history.listen(({ pathname }: any) => {
        const user = UserService.getLocalUser();

        const changePasswordPath = '/change-password';

        if (user && user.passwordChangeRequired && pathname !== changePasswordPath) {
          history.replace(changePasswordPath);

          return;
        }

        PermissionService.verificationRoute(pathname);
      });
    },
  },
};

export default globalModel;
