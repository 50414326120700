import request from '@/http';
import type { NExport } from './interface';

export function listExport(params: NExport.ListDspParams) {
  const url = '/api/adx/export';

  return request.get(url, {
    params,
  });
}

// 删除导出
export function deleteExportRecord(id: number) {
  const url = `/api/adx/export/${id}`;

  return request.delete(url);
}
