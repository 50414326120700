// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/Users/van/WebstormProjects/admile-adx-platform-web/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelDspBillingModel0 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/billing/dsp/dsp.billing.model.js';
import ModelSspBillingModel1 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/billing/ssp/ssp.billing.model.js';
import ModelModel2 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/common/model.ts';
import ModelModel3 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/dsp/model.ts';
import ModelModel4 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/export/model.ts';
import ModelModel5 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/global/model.ts';
import ModelGroupModel6 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/management/group/group.model.js';
import ModelPermissionModel7 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/management/permission/permission.model.js';
import ModelUserModel8 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/management/user/user.model.js';
import ModelModel9 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/operation/common/model.js';
import ModelModel10 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/operation/dsp-ep/model.js';
import ModelModel11 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/operation/dsp-unit/model.js';
import ModelModel12 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/operation/dsp/model.ts';
import ModelModel13 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/operation/ssp-ep/model.js';
import ModelModel14 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/operation/ssp-unit/model.js';
import ModelModel15 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/operation/ssp/model.js';
import ModelModel16 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/register/model.js';
import ModelModel17 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/report/dsp/model.js';
import ModelModel18 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/rtb/ad-set/model.js';
import ModelModel19 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/rtb/by-ssp-unit/model.js';
import ModelMode20 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/rtb/creative/mode.js';
import ModelOfferModel21 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/rtb/offer/offer.model.js';
import ModelCollectionModel22 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/settings/collection/collection.model.js';
import ModelModel23 from '/Users/van/WebstormProjects/admile-adx-platform-web/src/models/ssp/model.ts';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'dsp.billing.model', ...ModelDspBillingModel0 });
app.model({ namespace: 'ssp.billing.model', ...ModelSspBillingModel1 });
app.model({ namespace: 'model', ...ModelModel2 });
app.model({ namespace: 'model', ...ModelModel3 });
app.model({ namespace: 'model', ...ModelModel4 });
app.model({ namespace: 'model', ...ModelModel5 });
app.model({ namespace: 'group.model', ...ModelGroupModel6 });
app.model({ namespace: 'permission.model', ...ModelPermissionModel7 });
app.model({ namespace: 'user.model', ...ModelUserModel8 });
app.model({ namespace: 'model', ...ModelModel9 });
app.model({ namespace: 'model', ...ModelModel10 });
app.model({ namespace: 'model', ...ModelModel11 });
app.model({ namespace: 'model', ...ModelModel12 });
app.model({ namespace: 'model', ...ModelModel13 });
app.model({ namespace: 'model', ...ModelModel14 });
app.model({ namespace: 'model', ...ModelModel15 });
app.model({ namespace: 'model', ...ModelModel16 });
app.model({ namespace: 'model', ...ModelModel17 });
app.model({ namespace: 'model', ...ModelModel18 });
app.model({ namespace: 'model', ...ModelModel19 });
app.model({ namespace: 'mode', ...ModelMode20 });
app.model({ namespace: 'offer.model', ...ModelOfferModel21 });
app.model({ namespace: 'collection.model', ...ModelCollectionModel22 });
app.model({ namespace: 'model', ...ModelModel23 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
