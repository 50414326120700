import { isArray, isNumber } from 'lodash';

export function transformSspBillingData(data) {
  if (data) {
    const { list, summary, ...rest } = data;

    // 当前页汇总
    const pageSummary = {
      systemRevenue: 0,
      systemPayout: 0,
      systemCost: 0,

      finalPayout: 0,
      deduction: 0,
      profit: 0,
    };

    const defaultTotalSummary = {
      totalSystemRevenue: 0,
      totalSystemPayout: 0,
      totalSystemCost: 0,
      totalFinalPayout: 0,
      totalDeduction: 0,
      totalProfit: 0,
    };

    const summaryKeys = Object.keys(pageSummary);

    const ret = isArray(list)
      ? list.map((billing) => {
          const { deductionRate, sspId, sspName } = billing;

          summaryKeys.forEach((key) => {
            // 调整数字的精度(不包含百分比)
            const value = isNumber(billing[key]) ? billing[key] : 0;

            const format = Number(value > 1 ? value.toFixed(2) : value);

            billing[key] = format;

            pageSummary[key] += isNumber(format) ? format : 0;
          });

          return {
            ...billing,
            ssp: `${sspName}#${sspId}`,
            deductionRate: deductionRate * 100,
          };
        })
      : [];

    return {
      ...rest,
      list: ret,
      summary: {
        ...pageSummary,
        ...(summary ? summary : defaultTotalSummary),
      },
    };
  }

  return {
    list: [],
    paginator: {},
    summary: {},
  };
}
