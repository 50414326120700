import { useCallback } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import { history } from 'umi';
import { stringify } from 'querystring';
import UserService from '@/pages/user/user.service';

import styles from './index.less';

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = () => {
  const { query = {}, search, pathname } = history.location;

  const { redirect } = query;

  UserService.clearLocalUser();

  UserService.clearLocalToken();

  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    history.replace({
      pathname: '/user/login',
      search: stringify({
        redirect: pathname + search,
      }),
    });
  }
};

enum Menu {
  Logout,

  ChangePassword,
}

const AvatarDropdown = () => {
  const currentUser = UserService.getLocalUser();

  const onMenuClick = useCallback((key) => {
    if (key === Menu.Logout) {
      loginOut();

      return;
    }

    if (key === Menu.ChangePassword) {
      history.push('/change-password');

      return;
    }
  }, []);

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: (
              <div
                onClick={() => {
                  onMenuClick(Menu.ChangePassword);
                }}
              >
                <LogoutOutlined className="mr-5" />
                <span>Change Password</span>
              </div>
            ),
            key: Menu.ChangePassword,
          },
          {
            label: (
              <div
                onClick={() => {
                  onMenuClick(Menu.Logout);
                }}
              >
                <LogoutOutlined className="mr-5" />
                <span>Logout</span>
              </div>
            ),
            key: Menu.Logout,
          },
        ],
      }}
    >
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar size="small" className={styles.avatar} alt="avatar" />
        <span className="text-white">{currentUser?.name}</span>
      </span>
    </Dropdown>
  );
};

export default AvatarDropdown;
