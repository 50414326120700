import ModelConstant from '@/models/constant';
import {
  listSystemUser,
  getSystemUserAuthorizePermission,
  getUserOperationLogSummary,
  listUserOperationLog,
} from './user.api';
import { transformUserList, transformUserOperationSummary } from './user.util';

const managementUserModel = {
  namespace: 'managementUserState',
  state: {
    userData: ModelConstant.ListResponse,
    userAuthorizePermission: {
      data: [],
      page: [],
    },
    userOperationSummary: {
      first: {},
      last: {},
    },
    userOperationData: ModelConstant.ListResponse,
  },
  effects: {
    *listSystemUser({ payload }, { call, put }) {
      const data = yield call(listSystemUser, payload);

      yield put({
        type: 'listSystemUserSuccess',
        payload: data,
      });
    },

    *getSystemUserAuthorizePermission({ payload }, { call, put }) {
      const data = yield call(getSystemUserAuthorizePermission, payload);
      yield put({
        type: 'getSystemUserAuthorizePermissionSuccess',
        payload: data,
      });
    },

    *getUserOperationLogSummary({ payload }, { call, put }) {
      const data = yield call(getUserOperationLogSummary, payload);

      yield put({
        type: 'getUserOperationLogSummarySuccess',
        payload: data,
      });
    },

    *listUserOperationLog({ payload }, { call, put }) {
      const data = yield call(listUserOperationLog, payload);

      yield put({
        type: 'listUserOperationLogSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listSystemUserSuccess(state, { payload }) {
      const { list = [], paginator = ModelConstant.Paginator } =
        payload || ModelConstant.ListResponse;

      return {
        ...state,
        userData: {
          list: transformUserList(list),
          paginator,
        },
      };
    },

    getSystemUserAuthorizePermissionSuccess(state, { payload }) {
      return {
        ...state,
        userAuthorizePermission: payload,
      };
    },

    getUserOperationLogSummarySuccess(state, { payload }) {
      return {
        ...state,
        userOperationSummary: transformUserOperationSummary(payload),
      };
    },

    listUserOperationLogSuccess(state, { payload }) {
      const { list = [], paginator = ModelConstant.Paginator } =
        payload || ModelConstant.ListResponse;

      return {
        ...state,
        userOperationData: {
          list,
          paginator,
        },
      };
    },
  },
};

export default managementUserModel;
