import type { Effect, Reducer } from 'umi';
import type { ListResponse } from '@/models/interface';
import type { NSsp } from '@/models/ssp/interface';
import { listSsp, detailSsp, listSspIntegration } from './api';
import { defaultListData } from '@/models/common.js';
import { transformSspDetail, transformSspList, transformSspIntegration } from './util';

interface SspModelState {
  sspData: ListResponse<NSsp.SspItem>;
  sspDetail: NSsp.SspDetail;
  sspIntegrationData: ListResponse<NSsp.SspIntegrationItem>;
}

interface SspModel {
  namespace: string;
  state: SspModelState;
  effects: {
    listSsp: Effect;
    detailSsp: Effect;
    listSspIntegration: Effect;
  };
  reducers: {
    listSspSuccess: Reducer;
    detailSspSuccess: Reducer;
    listSspIntegrationSuccess: Reducer;
  };
}

const sspModel: SspModel = {
  namespace: 'sspState',
  state: {
    sspData: defaultListData,
    sspDetail: {} as NSsp.SspDetail,
    sspIntegrationData: defaultListData,
  },
  effects: {
    *listSsp({ payload }, { call, put }) {
      const data = yield call(listSsp, payload);

      yield put({
        type: 'listSspSuccess',
        payload: data,
      });
    },

    *detailSsp({ payload }, { call, put }) {
      const data = yield call(detailSsp, payload);

      yield put({
        type: 'detailSspSuccess',
        payload: data,
      });
    },

    *listSspIntegration({ payload }, { call, put }) {
      const data = yield call(listSspIntegration, payload);

      yield put({
        type: 'listSspIntegrationSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listSspSuccess(state, { payload }) {
      return {
        ...state,
        sspData: {
          list: transformSspList(payload.list),
          paginator: payload.paginator,
        },
      };
    },
    detailSspSuccess(state, { payload }) {
      return {
        ...state,
        sspDetail: transformSspDetail(payload),
      };
    },

    listSspIntegrationSuccess(state, { payload }) {
      const { list, paginator } = payload;

      return {
        ...state,
        sspIntegrationData: {
          list: transformSspIntegration(list),
          paginator,
        },
      };
    },
  },
};

export default sspModel;
