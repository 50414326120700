import { isArray, isNumber } from 'lodash';
import { OfferStateEnum } from '@/models/rtb/offer/offer.enum';
import { AdSetStateEnum } from '@/models/rtb/ad-set/enum';
import { DspOperationStateEnum } from '@/models/operation/ssp/enum';

export function transformSspUnit(data) {
  if (data) {
    const { list, summary } = data;

    const totalBudget = summary?.budget || 0;

    const yesterdayPayout = summary?.yesterdayPayout || 0;

    const mergeList =
      isArray(list) && list.length
        ? list.map((item) => {
            // 影响预算的状态

            const {
              offerStatus,
              adSetStatus,

              dspStatus,
              dspIntegrationStatus,

              sspStatus,
              sspIntegrationStatus,

              internalDspOperationSwitch,

              budget,
              yesterdayPayout,
            } = item;

            const activeOffer = offerStatus === OfferStateEnum.Active;

            const activeAdset = adSetStatus === AdSetStateEnum.Active;

            const activeDsp = dspStatus === 'Active';

            const activeDspIntegrationStatus = dspIntegrationStatus === 'Active';

            const activeSspStatus = sspStatus === 'Active';

            const activeSspIntegrationStatus = sspIntegrationStatus === 'Active';

            const isOpenSspEpBudget = internalDspOperationSwitch === DspOperationStateEnum.ON;

            const budgetStatusList = [
              {
                label: 'ssp ep traffic switch closed',
                status: isOpenSspEpBudget ? 'ON' : 'OFF',
                flag: isOpenSspEpBudget,
              },
              {
                label: 'offer status',
                status: offerStatus,
                flag: activeOffer,
              },
              {
                label: 'adset status',
                status: adSetStatus,
                flag: activeAdset,
              },
              {
                label: 'dsp status',
                status: dspStatus,
                flag: activeDsp,
              },
              {
                label: 'dsp ep status',
                status: dspIntegrationStatus,
                flag: activeDspIntegrationStatus,
              },
              {
                label: 'ssp status',
                status: sspStatus,
                flag: activeSspStatus,
              },
              {
                label: 'ssp ep status',
                status: sspIntegrationStatus,
                flag: activeSspIntegrationStatus,
              },
            ];

            const budgetRunningList = budgetStatusList
              .filter((item) => !item.flag)
              .map((item) => {
                return `Stop by ${item.label}: ${item.status}`;
              });

            // 是否是有效的预算
            const isAvailableBudget = budgetStatusList.every((item) => item.flag);

            // 花费占比
            const yesterdayPayoutRate =
              isNumber(yesterdayPayout) && isNumber(budget) && budget > 0
                ? yesterdayPayout / budget
                : 1;

            return {
              ...item,
              budgetRunningList,
              isAvailableBudget,
              yesterdayPayoutRate,
            };
          })
        : [];

    const budget = mergeList.reduce((num, item) => {
      num += isNumber(item.budget) && item.isAvailableBudget ? item.budget : 0;

      return num;
    }, 0);

    return {
      ...data,
      list: mergeList,
      summary: {
        ...summary,
        budget,
        totalBudget,
        yesterdayPayout,
      },
    };
  }

  return {
    list: [],
    paginator: {},
    summary: {},
  };
}
