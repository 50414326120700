import { listRtbOffer, listOfferWithAdset, getAppInfoByBundleId } from './offer.api';
import { defaultListData } from '@/models/common.js';
import { transformRtbOfferData, translateOfferWithAdset } from './offer.util';

const rtbOfferModel = {
  namespace: 'rtbOfferState',
  state: {
    offerData: defaultListData,
    offerTreeData: defaultListData,
    appInfo: {},
  },
  effects: {
    *listRtbOffer({ payload }, { call, put }) {
      const data = yield call(listRtbOffer, payload);

      yield put({
        type: 'listRtbOfferSuccess',
        payload: data,
      });

      return data;
    },

    *listOfferWithAdset({ payload }, { call, put }) {
      const data = yield call(listOfferWithAdset, payload);

      yield put({
        type: 'listOfferWithAdsetSuccess',
        payload: data,
      });

      return data;
    },

    *getAppInfoByBundleId({ payload }, { call, put }) {
      const data = yield call(getAppInfoByBundleId, payload);

      yield put({
        type: 'getAppInfoByBundleIdSuccess',
        payload: data,
      });

      return data;
    },
  },
  reducers: {
    listRtbOfferSuccess(state, { payload }) {
      return {
        ...state,
        offerData: transformRtbOfferData(payload),
      };
    },

    listOfferWithAdsetSuccess(state, { payload }) {
      return {
        ...state,
        offerTreeData: translateOfferWithAdset(payload),
      };
    },

    getAppInfoByBundleIdSuccess(state, { payload }) {
      const { icon, ...rest } = payload || {};

      return {
        ...state,
        appInfo: payload,
      };
    },
  },
};

export default rtbOfferModel;
