import request from '@/http';
import Utils from '@/utils';

export function listDspUnitOperation(params) {
  const url = '/api/adx/operation/dsp/unit/aggregation/list';

  return request(url, {
    params: Utils.checkParams(params),
  });
}

// 获取ssp unit下拉列表
export function listApprovalSspUnit(params) {
  const url = `/api/adx/operation/dsp/unit/aggregation/matched/ssp/unit/list`;

  return request.get(url, {
    params,
  });
}

// 获取dsp unit 按照os维度已经审批过的 floor range
export function listDspUnitOsFloorRange(param) {
  const { dspIntegrationId, countryId, ...params } = param;

  const url = `/api/adx/dsp/integration/geo/os/bid/floor/range/${dspIntegrationId}/${countryId}`;

  return request.get(url, {
    params,
  });
}

// 更新bid floor
export function updateDspUnitOsFloorRange(ranges) {
  const url = '/api/adx/dsp/integration/geo/os/bid/floor/range';

  return request.post(url, {
    data: {
      dspEpGeoOsBidFloorRanges: ranges,
    },
  });
}

// 获取dsp ssp ep geo 原始信息
export function listDspMatchedSspEpOsConfig(params) {
  const url = '/api/adx/operation/dsp/unit/aggregation/matched/ssp/ep/geo/list';

  return request.get(url, {
    params,
  });
}

// 获取dsp unit ssp ep geo approved配置信息
export function listApprovedSspEpConfig(params) {
  const url = '/api/adx/operation/dsp/unit/aggregation/approve/ssp/ep/config/list';

  return request.get(url, {
    params,
  });
}

// 审批 ssp ep geo
export function approvalDspSspEpOs(body) {
  const url = '/api/adx/operation/dsp/unit/aggregation/approve/ssp/ep/geo/config';

  return request.post(url, {
    data: body,
  });
}

export function detailApproveDspUnit(data) {
  const { dspIntegrationId, countryId, ...params } = data;

  const url = `/api/adx/operation/dsp/unit/aggregation/${dspIntegrationId}/${countryId}/approve/detail`;

  return request.get(url, {
    params,
  });
}

export function approveDspUnit(body) {
  const url = '/api/adx/operation/dsp/unit/aggregation/approve';

  return request.post(url, {
    data: body,
  });
}

export function detailBlockDspUnit(param) {
  const { dspIntegrationId, countryId, ...params } = param;

  const url = `/api/adx/operation/dsp/unit/aggregation/${dspIntegrationId}/${countryId}/block/detail`;

  return request.get(url, {
    params,
  });
}

export function blockDspUnit(body) {
  const url = '/api/adx/operation/dsp/unit/aggregation/block';

  return request.post(url, {
    data: body,
  });
}

export function deleteDspUnit(body) {
  const url = '/api/adx/operation/dsp/unit/aggregation/block';

  return request.delete(url, {
    data: body,
  });
}

export function reFloatRule(sspOperationId) {
  const url = `/api/adx/operation/dsp/unit/aggregation/refloated/${sspOperationId}`;

  return request.post(url);
}

export function reFloatOsRule(sspOperationId) {
  const url = `/api/adx/operation/dsp/unit/aggregation/ssp/ep/config/refloated/${sspOperationId}`;

  return request.post(url);
}
