import { Settings as LayoutSettings } from '@ant-design/pro-layout';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string | null;
} = {
  title: 'Adx Platform',
  navTheme: 'light',
  layout: 'top',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  pwa: false,
  logo: null,
  splitMenus: false,
  footerRender: false,
  siderMenuType: 'group',
};

export default Settings;
