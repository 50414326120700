import Utils from '@/utils';
import request from '@/http';

export function listSspEp(params) {
  const url = '/api/adx/operation/ssp/ep/aggregation/list';

  return request.get(url, {
    params: Utils.checkParams(params),
  });
}

// 获取ssp unit 按照os维度已经审批过的 floor range
export function listSspEpGeoFloorRange(param) {
  const { sspIntegrationId, ...params } = param;

  const url = `/api/adx/ssp/integration/geo/bid/floor/range/${sspIntegrationId}`;

  return request.get(url, {
    params,
  });
}

// 更新floor price
export function updateSspEpGeoFloorRange(ranges) {
  const url = '/api/adx/ssp/integration/geo/bid/floor/range';

  return request.post(url, {
    data: {
      sspEpGeoBidFloorRanges: ranges,
    },
  });
}

export function listOperationDspApproveUnit(data) {
  const { sspIntegrationId, ...params } = data;

  const url = `/api/adx/operation/ssp/ep/aggregation/${sspIntegrationId}/matched/dsp/ep/list`;

  return request.get(url, {
    params,
  });
}

export function getSspEpApproveDetail(data) {
  const { sspIntegrationId, countryId, ...params } = data;

  const url = `/api/adx/operation/ssp/ep/aggregation/${sspIntegrationId}/approve/detail`;

  return request.get(url, {
    params,
  });
}

export function approveSspEp(data) {
  const url = '/api/adx/operation/ssp/ep/aggregation/approve';

  return request.post(url, {
    data,
  });
}

export function listSspEpApprovedDspGeoConfig(params) {
  const url = '/api/adx/operation/ssp/ep/aggregation/approve/dsp/ep/config/list';

  return request.get(url, {
    params,
  });
}

export function listSspEpApprovalDspGeoConfig(params) {
  const url = '/api/adx/operation/ssp/ep/aggregation/matched/dsp/ep/geo/list';

  return request.get(url, {
    params,
  });
}

export function updateSspUnitApprovedConfig(config) {
  const url = '/api/adx/operation/ssp/ep/aggregation/approve/dsp/ep/geo/config';

  return request.post(url, {
    data: config,
  });
}

export function blockSspEp(body) {
  const url = '/api/adx/operation/ssp/ep/aggregation/block';

  return request.post(url, {
    data: body,
  });
}

export function deleteSspEp(body) {
  const url = '/api/adx/operation/ssp/ep/aggregation/block';

  return request.delete(url, {
    data: body,
  });
}

export function detailBlockSspEp(data) {
  const { sspIntegrationId, ...params } = data;

  const url = `/api/adx/operation/ssp/ep/aggregation/${sspIntegrationId}/block/detail`;

  return request.get(url, {
    params,
  });
}

export function reFloatRule(id) {
  const url = `/api/adx/operation/ssp/ep/aggregation/refloated/${id}`;

  return request.post(url);
}

export function reFloatOsRule(id) {
  const url = `/api/adx/operation/ssp/ep/aggregation/dsp/ep/config/refloated/${id}`;

  return request.post(url);
}

export function detailSspEpApprovedBudget(data) {
  const { sspIntegrationId, ...params } = data;

  const url = `/api/adx/operation/ssp/ep/aggregation/${sspIntegrationId}/budget/config/detail`;

  return request.get(url, {
    params,
  });
}

export function listInternalDspEp(data) {
  const { dspId = 52, ...params } = data;

  const url = `/api/adx/operation/dsp/aggregation/${dspId}/integrations`;

  return request.get(url, {
    params,
  });
}

export function listDspOffer(params) {
  const url = '/api/adx/operation/ssp/aggregation/offer/adset/list';

  return request.get(url, {
    params,
  });
}

export function listMatchedSspEp(data) {
  const { sspIntegrationId, ...params } = data;

  const url = `/api/adx/operation/ssp/aggregation/ep/${sspIntegrationId}/target/list`;

  return request.get(url, {
    params,
  });
}

// 获取聚合ssp ep下面的所有情况 来配置budget
export function listSspEpTarget(data) {
  const { sspIntegrationId, ...params } = data;

  const url = `/api/adx/operation/ssp/ep/${sspIntegrationId}/target/list`;

  return request.get(url, {
    params,
  });
}

// 审批ssp ep 和内部dsp ep的预算
export function approveSspEpForDspEpBudget(body) {
  const url = '/api/adx/operation/ssp/ep/aggregation/budget/config';

  return request.post(url, {
    data: body,
  });
}

export function getSspEpIntegrationConfig(integrationId) {
  const url = ` /api/adx/ssp/integration/block/${integrationId}`;

  return request.get(url);
}

// ----------------------------------------------------------------
export function listOperationDspBlockUnit(params) {
  const url = '/api/adx/operation/ssp/operation/block/dsp/units';

  return request.get(url, {
    params,
  });
}
