import { listDspSspEpConfigInAggregation, listDspSspEpConfigInOperation } from './api';

const operationCommonModel = {
  namespace: 'operationCommonState',
  state: {
    aggregationConfigList: [],
    internalDspAggregationConfigList: [],

    operationConfigList: [],
    internalOperationConfigList: [],
  },
  effects: {
    *listDspSspEpConfigInAggregation({ payload }, { put, call }) {
      const data = yield call(listDspSspEpConfigInAggregation, payload);

      yield put({
        type: 'listDspSspEpConfigInAggregationSuccess',
        payload: data,
      });
    },

    *listInternalDspSspEpConfigInAggregation({ payload }, { put, call }) {
      const data = yield call(listDspSspEpConfigInAggregation, payload);

      yield put({
        type: 'listInternalDspSspEpConfigInAggregationSuccess',
        payload: data,
      });
    },

    *listDspSspEpConfigInOperation({ payload }, { put, call }) {
      const data = yield call(listDspSspEpConfigInOperation, payload);

      yield put({
        type: 'listDspSspEpConfigInOperationSuccess',
        payload: data,
      });
    },

    *listInternalDspSspEpConfigInOperation({ payload }, { put, call }) {
      const data = yield call(listDspSspEpConfigInOperation, payload);

      yield put({
        type: 'listInternalDspSspEpConfigInOperationSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listDspSspEpConfigInAggregationSuccess(state, { payload }) {
      return {
        ...state,
        aggregationConfigList: payload,
      };
    },

    listInternalDspSspEpConfigInAggregationSuccess(state, { payload }) {
      return {
        ...state,
        internalDspAggregationConfigList: payload,
      };
    },

    listDspSspEpConfigInOperationSuccess(state, { payload }) {
      return {
        ...state,
        operationConfigList: payload,
      };
    },

    listInternalDspSspEpConfigInOperationSuccess(state, { payload }) {
      return {
        ...state,
        internalOperationConfigList: payload,
      };
    },
  },
};

export default operationCommonModel;
