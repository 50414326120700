import dayjs from 'dayjs';
import { AdSetStateEnum, AdFormat } from '@/models/rtb/ad-set/enum';
import { CostInterval, CostType } from '@/models/rtb/offer/offer.enum';

class AdSetConstant {
  static adSetGeoFieldName = 'geo';

  static adSetDeviceFieldName = 'device';

  static statusList = [
    {
      id: AdSetStateEnum.Active,
      name: 'Active',
    },
    {
      id: AdSetStateEnum.Paused,
      name: 'Paused',
    },
  ];

  static adFormatList = [
    {
      id: AdFormat.Banner,
      name: 'banner',
    },
    {
      id: AdFormat.Native,
      name: 'native',
    },
    {
      id: AdFormat.Video,
      name: 'video',
    },
  ];

  static costIntervalList = [
    {
      id: CostInterval.Total,
      name: 'Total',
    },
    {
      id: CostInterval.Daily,
      name: 'Daily',
    },
  ];

  static costTypeList = [
    {
      id: CostType.Auto,
      name: 'Auto',
    },
    {
      id: CostType.BySsp,
      name: 'By Ssp',
    },
  ];

  static newDeviceItem = {
    deviceId: null,
    osId: null,
    minVersionId: null,
  };

  static newGeoItem = {
    countryId: null,
    regionId: null,
  };

  static newTextItem = {
    language: null,
    bodyContent: null,
    headline: null,
    buttonTitle: 'Install!',
  };

  static initialAdSetFormValues = {
    [AdSetConstant.adSetGeoFieldName]: [],
    status: AdSetStateEnum.Active,
    text: [AdSetConstant.newTextItem],
    geo: [AdSetConstant.newGeoItem],
    duration: [dayjs(), dayjs().add(2, 'year').endOf('year')],
    iabCat: [],
    creativeBlockSspEpIds: [],
    adomain: null,
  };

  static defaultBidRate = [
    {
      priceRange: '0-0.01',
      bidRate: 1,
    },
    {
      priceRange: '0.01-0.05',
      bidRate: 1,
    },
    {
      priceRange: '0.05-0.1',
      bidRate: 0.5,
    },
    {
      priceRange: '0.1-0.2',
      bidRate: 0.24,
    },
    {
      priceRange: '0.2-0.4',
      bidRate: 0.12,
    },
    {
      priceRange: '0.4-0.6',
      bidRate: 0.06,
    },
    {
      priceRange: '0.6-1.0',
      bidRate: 0.01,
    },
    {
      priceRange: '1.0-2.0',
      bidRate: 0.003,
    },
    {
      priceRange: '2.0-4.0',
      bidRate: 0.001,
    },
    {
      priceRange: '4.0-8.0',
      bidRate: 0,
    },
    {
      priceRange: '8.0-15',
      bidRate: 0,
    },
    {
      priceRange: '15-30',
      bidRate: 0,
    },
    {
      priceRange: 'above 30',
      bidRate: 0,
    },
  ];

  static defaultFloorIncrease = 0;

  static sspEpAllItem = {
    sspIntegrationId: -1,
    sspUnit: 'All of them',
  };
}

export default AdSetConstant;
