import numeral from 'numeral';
import { isArray, isNumber } from 'lodash';
import { TreeDataTypeEnum } from '@/models/rtb/offer/offer.enum';
import Utils from '@/utils';
import NumberFormatService from '@/services/numberformat.service';

export function transformRtbOfferData(data) {
  if (!Utils.falsely(data)) {
    const { list, summary, ...rest } = data;

    // 当前页汇总
    const pageSummary = {
      billedEventCounts: 0,
      impressions: 0,
      clicks: 0,

      installs: 0,
      revenue: 0,
      payout: 0,
      profit: 0,
    };

    const defaultTotalSummary = {
      billedEventCounts: 0,
      impressions: 0,
      clicks: 0,

      installs: 0,
      revenue: 0,
      payout: 0,
      profit: 0,
    };

    const summaryKeys = Object.keys(pageSummary);

    list?.forEach((offer) => {
      // page summary
      summaryKeys.forEach((key) => {
        // 调整数字的精度(不包含百分比)
        const value = isNumber(offer[key]) ? offer[key] : 0;

        const format = NumberFormatService.toFixed(value, '0.0000[0]');

        offer[key] = format;

        pageSummary[key] += isNumber(format) ? format : 0;
      });
    });

    return {
      ...rest,
      list,
      summary: {
        pageSummary,
        totalSummary: summary ? summary : defaultTotalSummary,
      },
    };
  }

  return {
    list: [],
    paginator: {},
    summary: {},
  };
}

export function translateAdsetItem(ad, offerId, offerRemainCost, offerCost) {
  if (ad) {
    return {
      ...ad,
      offerCost,
      offerRemainCost,
      offerId,
      uniqueId: `${offerId}_${ad.id}`,
      adSetId: ad.id,
      type: TreeDataTypeEnum.AdSet,
    };
  }

  return {};
}

export function transformOfferItem(item) {
  if (item) {
    const offerId = item.id;

    const offerCost = item.cost;

    const totalAdsetCost = +numeral(
      isArray(item.adSetList)
        ? item.adSetList.reduce((curr, item) => {
            // eslint-disable-next-line no-param-reassign
            curr += item.cost;

            return curr;
          }, 0)
        : 0,
    ).format('0.0[0000]');

    const offerRemainCost = +numeral(item.cost - totalAdsetCost).format('0.0[0000]');

    return {
      ...item,
      offerCost,
      offerId,
      totalAdsetCost,
      offerRemainCost,
      uniqueId: `${offerId}_0`,
      adSetId: null,
      type: TreeDataTypeEnum.Offer,
      adSetList: isArray(item.adSetList)
        ? item.adSetList.map((ad) => {
            const formatAdset = translateAdsetItem(ad, offerId, offerRemainCost, offerCost);

            return {
              ...formatAdset,
              offerName: item.name,
            };
          })
        : [],
    };
  }

  return {};
}

export function translateOfferWithAdset(data) {
  if (data) {
    const { list, ...rest } = data;

    return {
      ...rest,
      list: isArray(list) ? list.map(transformOfferItem) : [],
    };
  }

  return { list: [], paginator: {} };
}
