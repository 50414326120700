import { listSystemUserGroup, getSystemUserGroupPermission } from './group.api';
import { transformGroupList } from './group.util';

const managementGroupModel = {
  namespace: 'managementGroupState',
  state: {
    groupData: {},
    groupAuthorizePermission: {
      page: [],
      data: [],
    },
  },
  effects: {
    *listSystemUserGroup({ payload }, { call, put }) {
      const data = yield call(listSystemUserGroup, payload);

      yield put({
        type: 'listSystemUserGroupSuccess',
        payload: data,
      });
    },

    *getSystemUserGroupPermission({ payload }, { call, put }) {
      const data = yield call(getSystemUserGroupPermission, payload);

      yield put({
        type: 'getSystemUserGroupPermissionSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listSystemUserGroupSuccess(state, { payload }) {
      const { list, ...rest } = payload;

      return {
        ...state,
        groupData: {
          ...rest,
          list: transformGroupList(list),
        },
      };
    },

    getSystemUserGroupPermissionSuccess(state, { payload }) {
      return {
        ...state,
        groupAuthorizePermission: payload,
      };
    },
  },
};

export default managementGroupModel;
