import request from '@/http';

export function listDspTag() {
  const url = '/api/adx/dsp/tag';

  return request.get(url);
}

export function createDspTag(tagList) {
  const url = '/api/adx/dsp/tag';

  return request.post(url, {
    data: {
      tags: tagList,
    },
  });
}

export function listSSPTag() {
  const url = '/api/adx/ssp/tag';

  return request.get(url);
}

export function createSSPTag(tagList) {
  const url = '/api/adx/ssp/tag';

  return request.post(url, {
    data: {
      tags: tagList,
    },
  });
}
