import dayjs from 'dayjs';
import { isArray } from 'lodash';
import { UserStatusEnum } from './user.enum';
import Utils from '@/utils';
import TimeService from '@/services/time.service';

export function transformUserList(list) {
  if (isArray(list) && list.length) {
    return list.map((group) => {
      const { status, createTime, updateTime } = group;

      return {
        ...group,
        statusText: UserStatusEnum.getText(status),
        createTimeText: TimeService.formatTime(createTime),
        updateTimeText: TimeService.formatTime(updateTime),
      };
    });
  }

  return [];
}

function getDurationText(duration) {
  if (!Utils.falsely(duration)) {
    if (duration <= 0) {
      return 'Less than an hour';
    }

    return `${duration} Hours`;
  }

  return 'Unknown';
}

export function transformUserOperationSummary(operation) {
  if (operation) {
    const firstTime = operation?.first?.time;

    const lastTime = operation?.last?.time;

    const onlineDuration =
      firstTime && lastTime ? dayjs(lastTime).diff(dayjs(firstTime), 'hours') : null;

    return {
      ...operation,
      onlineDuration,
      onlineDurationText: getDurationText(onlineDuration),
      lastActiveTimeText: lastTime ? dayjs().to(dayjs(lastTime)) : '--',
    };
  }

  return {
    first: {},
    last: {},
  };
}
