import { getDspReport } from './api';
import { defaultListData } from '@/models/common.js';
import { transformDspReportData } from './util';

const dspReportModel = {
  namespace: 'dspReportState',
  state: {
    dspReportData: {
      list: [],
      summary: {
        successBids: 0,
        failBids: 0,

        successImpressions: 0,
        failImpressions: 0,
        illegalImpressions: 0,

        successClicks: 0,
        failClicks: 0,

        installs: 0,
        billedEvents: 0,
        revenue: 0,
        payout: 0,
        profit: 0,
        matchedRequests: 0,

        totalBids: 0,
        totalSuccessBids: 0,
        totalFailBids: 0,

        totalImpressions: 0,
        totalSuccessImpressions: 0,
        totalUniqueSuccessImpressions: 0,
        totalFailImpressions: 0,

        totalClicks: 0,
        totalSuccessClicks: 0,
        totalUniqueSuccessClicks: 0,
        totalFailClicks: 0,

        totalInstalls: 0,
        totalBilledEvents: 0,
        totalRevenue: 0,
        totalPayout: 0,
        totalProfit: 0,

        totalMatchedRequests: 0,
      },
      paginator: defaultListData.paginator,
    },
  },
  effects: {
    *getDspReport({ payload }, { call, put }) {
      const data = yield call(getDspReport, payload);

      yield put({
        type: 'getDspReportSuccess',
        payload: data,
      });
    },
  },

  reducers: {
    getDspReportSuccess(state, { payload }) {
      return {
        ...state,
        dspReportData: transformDspReportData(payload),
      };
    },
  },
};

export default dspReportModel;
