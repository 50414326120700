import request from '@/http';
import SessionService from '@/services/session.service';
import CollectionService from '@/pages/settings/collection/collection.service';
import PermissionService from '@/services/permission.service';
import CommonService from '@/services/common.service';

export async function listMenu() {
  if (CommonService.getIsRegisterPage()) {
    return [];
  }

  const url = '/api/adx/user/menus';

  const menuData = await request.get(url);

  SessionService.updatePathPermissionConfig(
    PermissionService.getRouterPathPermissionConfig(menuData),
  );

  SessionService.setSessionMenuData(menuData);

  CollectionService.initLocalSender(menuData);

  return menuData;
}
