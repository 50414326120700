import { isArray } from 'lodash';
import {
  listDspManager,
  listSSPManager,
  getCountrySelectList,
  listDsp,
  listSSP,
  listTimezone,
  listDspEp,
  listSspEp,
} from './select.api';
import { listSSPTag, listDspTag } from '@/components/TagSelector/api';
import { listTerminalOs } from '@/models/common/api';
import { listSystemUserGroup } from '@/models/management/group/group.api';
import SelectEnum from './select.enum';
import { GroupStatusEnum } from '@/models/management/group/group.enum';

class SelectService {
  static async listTerminalOsName() {
    const list = await listTerminalOs();

    if (isArray(list)) {
      return Array.from(new Set(list.map((item) => item.name))).map((name) => ({ id: name, name }));
    }

    return [];
  }

  static async listTerminalName() {
    const list = await listTerminalOs();

    if (isArray(list)) {
      return Array.from(new Set(list.map((item) => item.terminalName))).map((name) => ({
        id: name,
        name,
      }));
    }

    return [];
  }

  static async getSystemUserGroup() {
    const params = {
      'page.index': 1,
      'page.size': 999999,
      status: [GroupStatusEnum.Enabled],
    };

    const data = await listSystemUserGroup(params);

    return data?.list || [];
  }

  getSelectList(module, params) {
    const list = Promise.resolve([]);

    if (module) {
      switch (module) {
        case SelectEnum.DSP_LIST:
          return listDsp();

        case SelectEnum.DSP_MANAGER:
          return listDspManager();

        case SelectEnum.SSP_LIST:
          return listSSP();

        case SelectEnum.SSP_MANAGER:
          return listSSPManager();

        case SelectEnum.DSP_TAG:
          return listDspTag();

        case SelectEnum.SSP_TAG:
          return listSSPTag();

        case SelectEnum.COUNTRY:
          return getCountrySelectList();

        case SelectEnum.OS:
          return listTerminalOs();

        case SelectEnum.OS_NAME:
          return SelectService.listTerminalOsName();

        case SelectEnum.DEVICE_TYPE:
          return SelectService.listTerminalName();

        case SelectEnum.TIME_ZONE:
          return listTimezone();

        case SelectEnum.DSP_EP:
          return listDspEp();

        case SelectEnum.SSP_EP:
          return listSspEp();

        case SelectEnum.USER_GROUPS:
          return SelectService.getSystemUserGroup();

        default:
          return list;
      }
    }

    return list;
  }
}

export default SelectService;
