import { isArray, isFunction, isString, isNumber } from 'lodash';
import dayjs from 'dayjs';
import zhCN from 'dayjs/locale/zh-cn';
import weekday from 'dayjs/plugin/weekday';
import relativeTime from 'dayjs/plugin/relativeTime';
import Utils from '@/utils';

dayjs.extend(weekday);
dayjs.extend(relativeTime);

dayjs.locale('zh-cn', {
  ...zhCN,
  weekStart: 1, // 设定周一为一周的开始
});

class TimeService {
  static GMT_8_Id = 28;

  static GMT_0_Id = 16;

  static GMT_0_zone_id = 'Etc/GMT';

  static TimeType = {
    Today: 'getToday',

    Yesterday: 'getYesterday',

    ThisWeek: 'getThisWeek',

    LastWeek: 'getLastWeek',

    ThisMonth: 'getThisMonth',

    LastMonth: 'getLastMonth',
  };

  static getTime(type: string) {
    return isFunction(TimeService[type])
      ? TimeService[type]()
      : TimeService[TimeService.TimeType.Today]();
  }

  static getToday() {
    const startDate = dayjs().startOf('day');

    const endDate = dayjs().endOf('day');

    return [startDate, endDate];
  }

  static getYesterday() {
    const start = dayjs().subtract(1, 'days').startOf('day');

    const end = dayjs().subtract(1, 'days').endOf('day');

    return [start, end];
  }

  static getDayBeforeYesterday() {
    const start = dayjs().subtract(2, 'days').startOf('day');

    const end = dayjs().subtract(2, 'days').endOf('day');

    return [start, end];
  }

  static getThisWeek() {
    const start = dayjs().startOf('week');

    const end = dayjs().endOf('week');

    return [start, end];
  }

  static getLastWeek() {
    const start = dayjs().add(-1, 'week').startOf('week');

    const end = dayjs().add(-1, 'week').endOf('week');

    return [start, end];
  }

  static getThisMonth() {
    const startDate = dayjs().startOf('month');

    const endDate = dayjs().endOf('month');

    return [startDate, endDate];
  }

  static getLastMonth() {
    const startDate = dayjs().add(-1, 'month').startOf('month');

    const endDate = dayjs().add(-1, 'month').endOf('month');

    return [startDate, endDate];
  }

  static getClientTimezone() {
    const oDate = new Date();

    const nTimezone = -oDate.getTimezoneOffset() / 60;

    return +nTimezone.toFixed(2);
  }

  static getIntlDateTimeOptions() {
    return Intl.DateTimeFormat().resolvedOptions();
  }

  static formatTime(timestamp: number, format = 'YYYY-MM-DD HH:mm:ss') {
    return timestamp ? dayjs(timestamp).format(format) : null;
  }

  static fromTimezone(timestamp: number, fmt = 'YYYY-MM-DD HH:mm:ss') {
    if (!Utils.falsely(timestamp)) {
      return TimeService.formatTime(timestamp, fmt);
    }

    return '--';
  }

  /**
   *@description:获取时长
   *@param{any} time
   *@returns{number} 间隔时长
   */
  static getTimeDurationNumber(time: any) {
    if (isArray(time)) {
      const [start, end] = time;

      const timeStart = dayjs(start);

      const timeEnd = dayjs(end);

      return timeEnd.diff(timeStart, 'days');
    }

    return 0;
  }

  static getTimeDurationFromNow(time: any) {
    if (isArray(time)) {
      const now = dayjs();

      const [start, end] = time;

      const timeStart = dayjs(start).isAfter(now) ? now : dayjs(start);

      const timeEnd = dayjs(end).isAfter(now) ? now : dayjs(end);

      return timeEnd.diff(timeStart, 'days');
    }

    return 0;
  }

  static multipleFormatTime(times: any[], fmt?: string) {
    if (isArray(times)) {
      return times.map((time) => TimeService.formatTime(time, fmt));
    }

    return [];
  }

  /**
   * @description:判断传入的时间是否是一天的开始时间 如果是则返回当天结束时间
   * **/
  static toEndOfDay = (date: any) => {
    if (dayjs.isDayjs(date)) {
      const isStartOfDay = date.isSame(date.startOf('day'));

      return isStartOfDay ? date.endOf('day') : date;
    } else if (isString(date) || date instanceof Date) {
      const d = dayjs(date);

      const isStartOfDay = d.isSame(d.startOf('day'));

      return isStartOfDay ? d.endOf('day') : d;
    }

    // 如果不是有效的时间类型，原样返回
    return date;
  };

  // 传入时间 是当月第一天
  static isFirstDayOfMonth(date: any) {
    const mergeDay = dayjs.isDayjs(date) ? date : dayjs(date);

    return mergeDay.date() === 1;
  }

  // 传入时间 是当月最后一天
  static isLastDayOfMonth(date: any) {
    const mergeDay = dayjs.isDayjs(date) ? date : dayjs(date);

    // 月末=以当月月初开始+1月然后-1天， 如果天数对的上说明是结束时间
    const lastDayOfMonth = mergeDay
      .startOf('month')
      .add(1, 'month')
      .startOf('month')
      .subtract(1, 'day');

    // 对比的时候舍弃掉时分秒
    return mergeDay.startOf('day').isSame(lastDayOfMonth.startOf('day'));
  }

  // 根据传入时间判断 开始时间是月初 结束时间是月末
  static isWholeMonth = (range: any[]) => {
    if (isArray(range) && range.length) {
      const [start, end] = range;

      const isFirstDayOfMonth = TimeService.isFirstDayOfMonth(start);

      const isLastDayOfMonth = TimeService.isLastDayOfMonth(end);

      return isFirstDayOfMonth && isLastDayOfMonth;
    }

    return false;
  };

  static isCompletelyInPastDays(timeRange: any[], days: number) {
    if (isArray(timeRange) && isNumber(days)) {
      // 获取当前时间
      const now = dayjs();

      // 获取过去多少天的起始时间
      const pastTime = now.subtract(days, 'day');

      // 解构出时间范围的开始时间和结束时间
      const [start, end] = timeRange;

      // 将传入的时间转换为 dayjs 对象
      const startTime = dayjs.isDayjs(start) ? start : dayjs(start);

      const endTime = dayjs(end).isAfter(now) ? now : dayjs(end);

      // 判断时间范围是否完全在过去 N 天内，并且包含今天
      return startTime.isAfter(pastTime) && endTime.isBefore(now.add(1, 'day'));
    }

    return false;
  }

  /**
   * @description:如果传入时间小时大于0 那么将开始分秒设置成00:00 结束分秒设置在59:59
   * **/
  static formatTimeHourSecond(timeRange: any[]) {
    if (isArray(timeRange) && timeRange.length) {
      const [start, end] = timeRange;

      // 判断开始时间的小时部分
      let startTime = dayjs(start);

      if (startTime.hour() > 0) {
        startTime = dayjs(start).minute(0).second(0).millisecond(0);
      }

      // 判断结束时间的小时部分
      let endTime = dayjs(end);

      if (endTime.hour() > 0) {
        endTime = dayjs(end).minute(59).second(59).millisecond(999);
      }

      return [startTime, endTime];
    }

    return [];
  }

  /**
   * 判断传入的 dayjs 对象/当前时间是否超过指定的时区偏移量
   * @param {date} date
   * @returns {boolean}
   */
  static isTimeExceedOffset(date?: any) {
    const clientTimezoneOffset = TimeService.getClientTimezone();

    // 获取当前时间
    const now = date ? (dayjs.isDayjs(date) ? date : dayjs(date)) : dayjs();

    // 获取当前本地时间的小时数
    const currentHour = now.hour();

    // 获取当前本地时间的分钟数
    const currentHourMinutes = now.minute();

    // 对比分钟
    const gapMinutes = currentHourMinutes + currentHour * 60 - clientTimezoneOffset * 60;

    // 判断当前分钟数是否超过偏移的分钟数, 超过就代表0时区也过完那一天了
    return gapMinutes > 0;
  }
}

export default TimeService;
