export default [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dsp',
    redirect: '/dsp/list',
  },
  {
    path: '/ssp',
    redirect: '/ssp/list',
  },
  {
    path: '/user',
    redirect: '/user/login',
  },
  {
    path: '/settings',
    redirect: '/settings/collection',
  },
  {
    path: '/rtb',
    redirect: '/rtb/offer',
  },
  {
    path: '/report',
    redirect: '/report/adx',
  },
  {
    path: '/management',
    redirect: '/management/user',
  },
  {
    path: '/management/user',
    redirect: '/management/user/list',
  },
  {
    path: '/management/group',
    redirect: '/management/group/list',
  },

  {
    path: '/dsp/registration',
    redirect: '/dsp/registration/list',
  },
  {
    path: '/ssp/registration',
    redirect: '/ssp/registration/list',
  },
  {
    path: '/operation',
    redirect: '/operation/dsp-ep/list',
  },
  {
    path: '/operation/dsp',
    redirect: '/operation/dsp-ep/list',
  },
  {
    path: '/operation/dsp-ep',
    redirect: '/operation/dsp-ep/list',
  },
  {
    path: '/operation/ssp-ep',
    redirect: '/operation/ssp-ep/list',
  },
  {
    path: '/operation/ssp',
    redirect: '/operation/ssp-ep/list',
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: './dashboard',
  },
  {
    path: '/dsp',
    name: 'DSP',
    routes: [
      {
        path: '/dsp/list',
        name: 'DSP List',
        component: './dsp/list',
        target: '_blank',
      },
      {
        path: '/dsp/detail',
        name: 'DSP Detail',
        component: './dsp/detail',
        hideInMenu: true,
      },
      {
        path: '/dsp/registration',
        name: 'DSP Registration',
        routes: [
          {
            path: '/dsp/registration/list',
            name: 'Registration List',
            component: './register/dsp',
            target: '_blank',
            hideInMenu: true,
          },
          {
            path: '/dsp/registration/detail',
            name: 'Registration List',
            component: './register/dsp/detail',
            hideInMenu: true,
          },
          {
            component: '404',
          },
        ],
      },
      {
        component: '404',
      },
    ],
  },
  {
    path: '/ssp',
    name: 'SSP',
    routes: [
      {
        path: '/ssp/list',
        name: 'SSP List',
        component: './ssp/list',
        target: '_blank',
        hideInMenu: true,
      },
      {
        path: '/ssp/detail',
        name: 'SSP Detail',
        hideInMenu: true,
        component: './ssp/detail',
      },
      {
        path: '/ssp/registration',
        name: 'SSP Registration',
        routes: [
          {
            path: '/ssp/registration/list',
            name: 'Registration List',
            component: './register/ssp',
            target: '_blank',
            hideInMenu: true,
          },
          {
            path: '/ssp/registration/detail',
            name: 'Registration List',
            component: './register/ssp/detail',
            hideInMenu: true,
          },
          {
            component: '404',
          },
        ],
      },
      {
        component: '404',
      },
    ],
  },

  {
    path: '/rtb',
    name: 'RTB',
    routes: [
      {
        path: '/rtb/offer',
        name: 'Offer',
        component: './rtb/offer',
        wrappers: ['../layouts/RtbLayout'],
        hideInMenu: true,
      },
      {
        path: '/rtb/ad-set',
        name: 'Ad-Set',
        component: './rtb/ad-set',
        wrappers: ['../layouts/RtbLayout'],
        hideInMenu: true,
      },
      {
        path: '/rtb/creative',
        name: 'Creative',
        component: './rtb/creative',
        wrappers: ['../layouts/RtbLayout'],
        hideInMenu: true,
      },
      {
        path: '/rtb/ssp-budget-units',
        name: 'Ssp-Budget-Units',
        component: './rtb/by-ssp-unit',
        wrappers: ['../layouts/RtbLayout'],
        hideInMenu: true,
      },
      {
        component: '404',
      },
    ],
  },

  {
    path: '/report',
    name: 'Report',
    routes: [
      {
        path: '/report/adx',
        name: 'Adx Report',
        component: './report/adx',
        target: '_blank',
      },
      {
        path: '/report/dsp',
        name: 'Dsp Report',
        component: './report/dsp',
        target: '_blank',
      },
      {
        path: '/report/dsp-illegal',
        name: 'Dsp Illegal Report',
        component: './report/dsp-illegal',
        target: '_blank',
      },
      {
        path: '/report/topbundle',
        name: 'Top Bundle ID Report',
        component: './report/topbundle',
        target: '_blank',
      },
      {
        component: '404',
      },
    ],
  },

  {
    path: '/user',
    layout: false,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
      },
      {
        path: '/user/login',
        name: 'login',
        component: './user/loginv2',
      },
      {
        component: '404',
      },
    ],
  },
  {
    path: '/operation',
    name: 'Operation',
    routes: [
      {
        path: '/operation/dsp',
        name: 'DSP',
        routes: [
          {
            path: '/operation/dsp/list',
            name: 'List',
            component: './operation/dsp/list',
            wrappers: ['../layouts/OperationLayout'],
            target: '_blank',
          },
          {
            path: '/operation/dsp/approve',
            name: 'Approve',
            component: './operation/dsp/approve',
            target: '_blank',
            hideInMenu: true,
          },
          {
            path: '/operation/dsp/block',
            name: 'Block',
            component: './operation/dsp/block',
            target: '_blank',
            hideInMenu: true,
          },
          {
            component: '404',
          },
        ],
      },
      {
        path: '/operation/dsp-ep',
        name: 'DSP Unit',
        routes: [
          {
            path: '/operation/dsp-ep/list',
            name: 'List',
            component: './operation/dsp-ep/list',
            wrappers: ['../layouts/EpOperationLayout'],
            target: '_blank',
          },
          {
            component: '404',
          },
        ],
      },
      {
        path: '/operation/ssp',
        name: 'SSP',
        routes: [
          {
            path: '/operation/ssp/list',
            name: 'List',
            component: './operation/ssp/list',
            wrappers: ['../layouts/OperationLayout'],
            target: '_blank',
          },
          {
            path: '/operation/ssp/approve',
            name: 'Approve',
            component: './operation/ssp/approve',
            target: '_blank',
            hideInMenu: true,
          },
          {
            path: '/operation/ssp/block',
            name: 'Block',
            component: './operation/ssp/block',
            target: '_blank',
            hideInMenu: true,
          },
          {
            component: '404',
          },
        ],
      },
      {
        path: '/operation/ssp-ep',
        name: 'SSP EP',
        routes: [
          {
            path: '/operation/ssp-ep/list',
            name: 'List',
            component: './operation/ssp-ep/list',
            wrappers: ['../layouts/EpOperationLayout'],
            target: '_blank',
          },
          {
            component: '404',
          },
        ],
      },
      {
        path: '/operation/rule',
        name: 'Rule',
        component: './operation/floatRule/list',
        target: '_blank',
      },
      {
        component: '404',
      },
    ],
  },
  {
    path: '/billing',
    name: 'Billing',
    routes: [
      {
        path: '/billing/dsp',
        name: 'DSP',
        component: './billing/dsp',
      },
      {
        path: '/billing/ssp',
        name: 'SSP',
        component: './billing/ssp',
      },
      {
        component: '404',
      },
    ],
  },
  {
    path: '/export',
    name: 'Export',
    component: './export/list',
  },
  {
    path: '/settings',
    name: 'Settings',
    routes: [
      {
        path: '/settings/collection',
        name: 'Collection',
        component: './settings/collection',
        hideInMenu: true,
      },
      {
        component: '404',
      },
    ],
  },

  {
    name: 'Management',
    path: '/management',
    routes: [
      {
        name: 'User',
        path: '/management/user',
        routes: [
          {
            name: 'List',
            path: '/management/user/list',
            component: './management/user/list',
            wrappers: ['../layouts/UserGroupLayout'],
            hideInMenu: true,
          },
          {
            name: 'Permission',
            path: '/management/user/permission',
            component: './management/user/permission',
            hideInMenu: true,
          },
          {
            name: 'Operation Log',
            path: '/management/user/log',
            component: './management/user/operation-log',
            hideInMenu: true,
          },
          {
            component: '404',
          },
        ],
      },
      {
        name: 'Group',
        path: '/management/group',
        routes: [
          {
            name: 'List',
            path: '/management/group/list',
            component: './management/group/list',
            wrappers: ['../layouts/UserGroupLayout'],
          },
          {
            name: 'Permission',
            path: '/management/group/permission',
            component: './management/group/permission',
          },
        ],
      },
      {
        name: 'Permission',
        path: '/management/permission',
        component: './management/permission',
      },
      {
        component: '404',
      },
    ],
  },

  {
    path: '/change-password',
    name: 'Change Password',
    component: './change-password',
    hideInMenu: true,
  },
  {
    path: '/register',
    name: 'Register',
    component: './register/form',
    hideInMenu: true,
    layout: false,
  },
  {
    path: '/test-page',
    component: './test-page',
    hideInMenu: true,
  },
  {
    path: '/403',
    component: '403',
  },
  {
    path: '/404',
    component: '404',
  },
  {
    component: '404',
  },
];
