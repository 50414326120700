import { listIdentity, listWire, listPayonner, listPaypal, senderInfo } from './collection.api';
import { transformIdentityList, transformWireList, sortByDefault } from './collection.util';

const collectionModel = {
  namespace: 'settingsCollectionState',
  state: {
    identityList: [],
    wireList: [],
    payonnerList: [],
    paypalList: [],
    senderInfo: {
      stamp: [],
      associationLogo: null,
      email: null,
    },
  },
  effects: {
    *listIdentity({ payload }, { call, put }) {
      const data = yield call(listIdentity, payload);

      yield put({
        type: 'listIdentitySuccess',
        payload: data,
      });
    },

    *listWire({ payload }, { call, put }) {
      const data = yield call(listWire, payload);

      yield put({
        type: 'listWireSuccess',
        payload: data,
      });
    },

    *listPayonner({ payload }, { call, put }) {
      const data = yield call(listPayonner, payload);

      yield put({
        type: 'listPayonnerSuccess',
        payload: data,
      });
    },

    *listPaypal({ payload }, { call, put }) {
      const data = yield call(listPaypal, payload);

      yield put({
        type: 'listPaypalSuccess',
        payload: data,
      });
    },

    *senderInfo(_, { call, put }) {
      const data = yield call(senderInfo);

      yield put({
        type: 'senderInfoSuccess',
        payload: data,
      });
    },
  },
  reducers: {
    listIdentitySuccess: (state, { payload }) => {
      return {
        ...state,
        identityList: transformIdentityList(payload),
      };
    },

    listWireSuccess: (state, { payload }) => {
      return {
        ...state,
        wireList: transformWireList(payload),
      };
    },

    listPayonnerSuccess: (state, { payload }) => {
      return {
        ...state,
        payonnerList: sortByDefault(payload),
      };
    },

    listPaypalSuccess: (state, { payload }) => {
      return {
        ...state,
        paypalList: sortByDefault(payload),
      };
    },

    senderInfoSuccess: (state, { payload }) => {
      return {
        ...state,
        senderInfo: payload,
      };
    },
  },
};

export default collectionModel;
