import { isArray, isNumber } from 'lodash';
import TimeService from '@/services/time.service';
import AuthService from '@/services/auth.service';
import DspSspOperationService from '@/pages/operation/operation.service';

export function transformDspUnitList(list) {
  if (isArray(list) && list.length) {
    return list.map((item) => {
      const {
        id: dspIntegrationId,
        minBidFloor,
        maxBidFloor,
        createTime,
        updateTime,
        managerIds,
      } = item;

      const operationId = DspSspOperationService.generatorOperationId(item);

      const [createTimeText, updateTimeText] = TimeService.multipleFormatTime([
        createTime,
        updateTime,
      ]);

      const managerAuthed = AuthService.authOperationData(managerIds);

      const dataAuthed = AuthService.authorizedDsp(item.dspId);

      return {
        ...item,
        dspIntegrationId,
        operationId,
        createTimeText,
        updateTimeText,
        userAuthed: managerAuthed || dataAuthed,
        minBidFloor: isNumber(minBidFloor) && minBidFloor >= 0 ? minBidFloor : 0,
        maxBidFloor: isNumber(maxBidFloor) ? maxBidFloor : 0,
      };
    });
  }

  return [];
}
