import UserService from '@/pages/user/user.service';
import Utils from '@/utils';
import { isArray } from 'lodash';

// 系统权限 组的名字
export class GroupEnum {
  static Admin = 'Admin';

  static ADX_BD = 'ADX BD';

  // 演示数据组
  static Demonstration = 'Demonstration';

  static TechTeam = 'Tech Team';
}

class AuthService {
  static authorizedAdmin() {
    const { groupNames } = UserService.getUserAuthorizedGroup();

    return groupNames?.includes(GroupEnum.Admin);
  }

  // 根据userId或者组 来判断是否能拥有某条数据权限
  static authOperationData(checkUserId) {
    return AuthService.authorizedTechTeam() || AuthService.authUserOperation(checkUserId);
  }

  static authUserOperation(checkUserId) {
    const user = UserService.getLocalUser();

    if (!user) {
      return false;
    }

    const checkerIds = Utils.falsely(checkUserId)
      ? []
      : isArray(checkUserId)
      ? checkUserId
      : [checkUserId];

    return AuthService.authorizedAdmin() || checkerIds.includes(user.id);
  }

  static authorizedDsp(dspId) {
    const { authorizedDspIds } = UserService.getUserAuthorizedGroup();

    if (AuthService.authorizedAdmin()) {
      return true;
    }

    if (authorizedDspIds?.includes(-1)) {
      return true;
    }

    return Utils.falsely(dspId) ? !!authorizedDspIds?.length : authorizedDspIds?.includes(dspId);
  }

  static authorizedSsp(sspId) {
    const { authorizedSspIds } = UserService.getUserAuthorizedGroup();

    if (AuthService.authorizedAdmin()) {
      return true;
    }

    if (authorizedSspIds?.includes(-1)) {
      return true;
    }

    return Utils.falsely(sspId) ? !!authorizedSspIds?.length : authorizedSspIds?.includes(sspId);
  }

  static authorizedAdxBD() {
    const { groupNames } = UserService.getUserAuthorizedGroup();

    return groupNames?.includes(GroupEnum.ADX_BD);
  }

  // 用户是否分配了演示组
  static authorizedDemonstration() {
    const { groupNames } = UserService.getUserAuthorizedGroup();

    return groupNames?.includes(GroupEnum.Demonstration);
  }

  // 用户是否分配了技术组
  static authorizedTechTeam() {
    const { groupNames } = UserService.getUserAuthorizedGroup();

    return groupNames?.includes(GroupEnum.TechTeam);
  }
}

export default AuthService;
