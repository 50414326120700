export default {
  'menu.login': '登陆',
  'menu.Dashboard': '仪表盘',
  'menu.DSP': 'DSP',
  'menu.DSP.DSP List': 'DSP 列表',
  'menu.DSP.DSP Detail': 'DSP 详情',

  'menu.SSP': 'SSP',
  'menu.SSP.SSP List': 'SSP 列表',
  'menu.SSP.SSP Detail': 'SSP 详情',
  'menu.Operation': 'Operation',
  'menu.Operation.List': 'Operation 列表',
  'menu.Operation.Rule': 'Float Rule',
  'menu.Report': '报表',
  'menu.Export': '导出列表',
};
